import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, shareReplay, map } from 'rxjs/operators';
import { ChatColorsInterface, chatInterface, chatMessageInterface } from '../models/chats';
import * as firebase from 'firebase';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({providedIn: 'root'})

export class CustomChatService {

  BASE_PATH = "privateChats";

  constructor(
    private database    : AngularFireDatabase,
    private errorServ   : ErrorService
  ) { }

  async getByMembers( memebers_sort_ids : string ) : Promise<chatInterface[]>{
    return await this.database.list<chatInterface>(this.BASE_PATH, ref => ref.orderByChild("memebers_sort_ids").equalTo(memebers_sort_ids)).valueChanges().pipe(take(1)).toPromise();
  }

  async getByID( id : string ) : Promise<chatInterface | null>{
    return await this.database.object<chatInterface>(`${this.BASE_PATH}/${id}`).valueChanges().pipe(take(1)).toPromise();
  }

  getStyles( event_fid : string ) : Observable<ChatColorsInterface | null>{
    return this.database.object<ChatColorsInterface>(`chats_styling/${event_fid}`).valueChanges().pipe(shareReplay(1));
  }

  getMessages( id : string ) : Observable<chatMessageInterface[]>{
    return this.database.list<chatMessageInterface>(`${this.BASE_PATH}/messages/${id}`,
      ref => ref.limitToLast(100)
    ).valueChanges().pipe(shareReplay(0), map(msg => {
      return msg;
    }));
  }

  async getFriendUID( id : string, current_user_id : string ) : Promise<false | string>{
    return await this.database.object<chatInterface>(`${this.BASE_PATH}/${id}`).valueChanges()
                        .pipe(
                          take(1),
                          map( chat => {
                            if(chat){
                              return chat.memebers_sort_ids.split('___');
                            }
                            return [];
                          }),
                          map( users  => users.filter( user => user != current_user_id)),
                          map( friend => {
                            if(friend.length){
                              return friend[0];
                            }
                            return false;
                          })
                        ).toPromise();
  }

  async create( friend : string, user : string) : Promise<false | chatInterface> {
    try{
      let memebers_sort_ids = [friend,user].sort().join('___');
      let reference = this.database.database.ref(`privateChats`).push();
      let chat : chatInterface = {
        // @ts-ignore
        created_at : firebase.default.database.ServerValue.TIMESTAMP,
        // @ts-ignore
        updated_at : firebase.default.database.ServerValue.TIMESTAMP,
        // @ts-ignore
        id : reference.key,
        memebers_sort_ids,
        status   : {
          creator : {id : user, status : true},
          friend  : {id : friend, status : user == friend},
        },
        messages : []
      }
      await reference.set(chat);
      return chat;
    }catch(error){
      this.errorServ.handleErrors(`Ocurrio un error al generar un nuevo chat. Detalles: `,error);
      return false;
    }
  }

  async sendMessage(chat_id : string, message : chatMessageInterface){
    try {
      let ref = this.database.database.ref(`privateChats/messages/${chat_id}`).push();
      //@ts-ignore
      message.id = ref.key;
      //@ts-ignore
      message.sent_at = firebase.default.database.ServerValue.TIMESTAMP;
      ref.set(message);
      return true;
    } catch (error : any) {
      this.errorServ.handleErrors(`Ocurrio un error al enviar un nuevo mensaje. Detalles: `,error);
      return false;
    }
  }

  async newChat(friend_id : string, current_user_id : string){
    let users_sort_ids =  [friend_id, current_user_id].sort().join('___');
    await this.validateIfUserHasFriend(friend_id, current_user_id);
    await this.validateIfUserHasFriend(current_user_id, friend_id);

    let response = await this.getByMembers(users_sort_ids);

    if(response.length){
      return response[0].id;
    }else{
      let chat = await this.create(friend_id, current_user_id);
      return (chat != false) ? chat.id : '';
    }
  }

  async validateIfUserHasFriend(user_id : string, friend_id : string) : Promise<void>{
    try {
      let friends = await this.database.object<User>(`users/${user_id}`)
                            .valueChanges()
                            .pipe(
                              take(1),
                              map( user => {
                                if(user){
                                  if(user.friends){
                                    if(!user.friends.includes(friend_id)){
                                      user.friends.push(friend_id);
                                    }
                                  }else{
                                    user.friends = [friend_id];
                                  }
                                  return user.friends;
                                }else{
                                  return false;
                                }
                              })
                            )
                            .toPromise();

      if(friends != false){
        await this.database.database.ref(`users/${user_id}/friends`).set(friends);
      }
    } catch (error) {
      this.errorServ.handleErrors(`Ocurrio un error al generar un nuevo chat. Detalles: `,error);
    }
  }

  async pendingChat(user_id : string, friend_id : string) : Promise<void>{
    try {
      let pending_chat = await this.database.object<User>(`users/${user_id}`)
                            .valueChanges()
                            .pipe(
                              take(1),
                              map( user => {
                                if(user){
                                  if(user.pending_chat){
                                    if(!user.pending_chat.includes(friend_id) && user_id != friend_id){
                                      user.pending_chat.push(friend_id);
                                    }
                                  }else{
                                    if(user_id != friend_id){
                                      user.pending_chat = [friend_id];
                                    }else{
                                      return false;
                                    }
                                  }
                                  return user.pending_chat;
                                }else{
                                  return false;
                                }
                              })
                            )
                            .toPromise();

      if(pending_chat != false){
        await this.database.database.ref(`users/${user_id}/pending_chat`).set(pending_chat);
      }
    } catch (error) {
      this.errorServ.handleErrors(`Ocurrio un error al asignar chat pendiente. Detalles: `,error);
    }
  }

  getTotalPendingChats( user_id : string ) : Observable<number>{
    return this.database.object<string[]>(`users/${user_id}/pending_chat`)
                        .valueChanges()
                        .pipe(
                          shareReplay(1),
                          map( chats => {
                            if(chats){
                              return chats.length;
                            }
                            return 0;
                          })
                        );
  }

  getPendingChats( user_id : string ) : Observable<string[]>{
    return this.database.object<string[]>(`users/${user_id}/pending_chat`)
                        .valueChanges()
                        .pipe(
                          shareReplay(1),
                          map( chats => {
                            if(!chats){
                              return [];
                            }
                            return chats;
                          })
                        );
  }

  async removePendingChat(friend_id : string, user_id : string) : Promise<void>{
    try {
      let pending_chat = await this.database.object<User>(`users/${user_id}`)
                                            .valueChanges()
                                            .pipe(
                                              take(1),
                                              map( user => {
                                                if(user && user.pending_chat){
                                                  if(user.pending_chat.includes(friend_id)){
                                                    return user.pending_chat.filter( chat => chat != friend_id );
                                                  }else{
                                                    return user.pending_chat;
                                                  }
                                                }
                                                return [];
                                              })
                                            )
                                            .toPromise();
      await this.database.database.ref(`users/${user_id}/pending_chat`).set(pending_chat);
    } catch (error) {
      this.errorServ.handleErrors(`Ocurrio un error al remover chat pendiente. Detalles: `,error);
    }
  }

  async confirmChat(chat_id : string) : Promise<boolean>{
    try {
      await this.database.database.ref(`privateChats/${chat_id}/status/friend/status`).set(true);
      return true;
    } catch (error) {
      this.errorServ.handleErrors(`Ocurrio un error al remover chat pendiente. Detalles: `,error);
      return false
    }
  }
}
