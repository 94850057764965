import { User } from 'src/app/core/models/user';
import { Component } from '@angular/core';
import { MagixEvent } from '../../../core/models/event';
import { EventState } from 'src/app/core/ngrx-store/event-store/event.reducers';
import { HelperService } from 'src/app/core/services/helper.service';
import { Store, select } from '@ngrx/store';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { selectEventState } from 'src/app/core/ngrx-store/event-store/event.selectors';
import { NetworkingService } from 'src/app/core/services/networking/networking.service';
import { DEFAULT_PROFILE_IMG } from 'src/app/core/data/networking.data';
import { NetworkingChatInterface } from 'src/app/core/models/networking.interface';
import { Subscription, Observable } from 'rxjs';
import { NetworkingInitChatInterface } from '../../../core/models/networking.interface';
import { currentUser, isLoadingUserImages } from 'src/app/core/auth/selectors/auth.selectors';
import { skipWhile, withLatestFrom, map, take } from 'rxjs/operators';
import { SetNetworkingStyle } from './networking.functions';

@Component({
  	templateUrl: './networking.component.html',
    styleUrls: [
		'./networking.component.scss',
		'./networking.rules.scss'
	]
})

export class NetworkingComponent {
	// Constantes
	DEFAULT_PROFILE_IMG = DEFAULT_PROFILE_IMG;
	// Variables dinamicas
	event!               : MagixEvent; 
	friend               : User | null = null;
	contacts!            : Observable<User[]>;
	currentUser!         : User;
	// Variables inicializadas
	chat                 : NetworkingInitChatInterface | null = null;
	userInterval         : any     = null;
	lastStyleUUID        : string  = "";
	isFriendLoaded       : boolean = false;
	subscriptions        : Subscription[] = [];
	stylesSubscription   : Subscription[] = [];
	openChatSubscription : Subscription[] = [];
	// Observables
	event$   : Observable<EventState>  = this.store.pipe(select(selectEventState),skipWhile( state => !(state.eventLoadStatus == 'loaded') ) )
	profile$ : Observable<User | null> = this.store.pipe(select(currentUser)).pipe(
		withLatestFrom(
			this.store.pipe(select(isLoadingUserImages))
		),
		skipWhile(([user, loadingImages]) => user == null || loadingImages),
		map(([user]) => user)
	);
	constructor(
		private store             : Store<StoreRootState>,
		private helper            : HelperService,
		private networkingService : NetworkingService,
	){
		this.getFriends();
	}

	async ngOnInit(){
		this.subscriptions.push( this.event$.subscribe( response => {
			if(response.event && response.event.tabs){
				this.event            = response.event;
				// window.document.title = response.event.tabs.networking;

				this.loadStyles();
			}
		}));
	}

	async getCurrentUser(){
		let currentUser = await this.profile$.pipe(take(1)).toPromise();

		if(currentUser){
			this.currentUser = Object.assign({},currentUser);
			
			this.userInterval = setInterval(() =>{
				this.networkingService.updateLastSeen(this.currentUser.uid);
			},60000)	
		}
	}

	async getFriends(){
		await this.getCurrentUser()
		let response = this.networkingService.getUserFriends(this.currentUser.uid);

		if(response){
			this.contacts = response;
		}
	}

	async openChat( chat : NetworkingChatInterface ){
		let friendID = "";
		// Get friendo
		if(chat.status.creator.id == this.currentUser.uid){
			friendID = chat.status.friend.id;
		}
		else if(chat.status.friend.id == this.currentUser.uid){
			friendID = chat.status.creator.id;
		}else{
			return;
		}
		
		let friendResponse = this.networkingService.getFriendByID( friendID );

		if(friendResponse){
			this.openChatSubscription.forEach( subscription => subscription.unsubscribe() );
			this.openChatSubscription.push(
				friendResponse.subscribe( friend => {
					this.chat = {
						friend         : friend,
						usersMembersID : [friend.uid, this.currentUser.uid].sort().join("___")
					}
					this.friend          = friend;
					this.isFriendLoaded  = true;	
					this.toggleChatsSection(true);
					this.networkingService.removePendingChat(friend.uid, this.currentUser.uid);
				})
			);
		}
	}

	toggleFriendSection( status : boolean ){
		let containerElement = document.querySelector("#chat-wrapper");
		let rightElement     = document.getElementById('right');
		// Validar que exista el contenedor
		if(containerElement && rightElement){
			if(status === true){
				containerElement.classList.remove("two");
				containerElement.classList.add("three");
				if (!rightElement.classList.contains('checked')) {
					rightElement.classList.add("checked")
				}	
			}else{
				containerElement.classList.add("two");
				containerElement.classList.remove("three");
				if(rightElement.classList.contains("checked")){
					setTimeout( () => rightElement?.classList.remove("checked"), 500 );
				}
			}
		}
	}

	toggleChatsSection( status : boolean ){		
		let containerElement = document.querySelector("#chat-wrapper");
		// Validar que exista el contenedor
		if(containerElement){
			if(status === true){
				containerElement.classList.add("show-chat");
				containerElement.classList.remove("hide-chat");
			}else{
				containerElement.classList.remove("show-chat");
				containerElement.classList.add("hide-chat");
			}
		}
	}
	
	ngOnDestroy(){
		if( this.userInterval ){
			clearInterval(this.userInterval);
		}
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.openChatSubscription.forEach( subscription => subscription.unsubscribe() )
	}

	loadStyles(){
		// Validar que exista un estilo seleccionado y que el estilo sea diferente al anterior
		if(this.event.features.defaultNetworkingStyle && this.lastStyleUUID != this.event.features.defaultNetworkingStyle){
			this.stylesSubscription.forEach( subscription => subscription.unsubscribe() );
			this.lastStyleUUID = this.event.features.defaultNetworkingStyle;
			
			this.stylesSubscription.push(
				this.networkingService.retrieveStyles(this.event.fid, this.lastStyleUUID).subscribe( style => {	
					if(style){
						let networkingStyleClass = new SetNetworkingStyle(style);
						
						networkingStyleClass.setImg();
						networkingStyleClass.setChatColors();
						networkingStyleClass.setLeftSideColors();
						networkingStyleClass.setChatBackgrounds();
						networkingStyleClass.setRightSideColors();
						networkingStyleClass.setOverLeftSideColors();
						networkingStyleClass.setLeftSideBackgrounds();
						networkingStyleClass.setRightSideBackgrounds();
						networkingStyleClass.setOverLeftSideBackgrounds();
					}
				})
			);
		}
	}

	checkIfChatStillExists( friendID : string ){
		if(this.friend?.uid == friendID){
			this.chat           = null;
			this.friend         = null;
			this.isFriendLoaded = false;
			this.networkingService.removePendingChat(friendID, this.currentUser.uid);
		}	
	}

}
