import { Component, forwardRef, Inject, INJECTOR, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector    : 'networking-search-left-section',
	styleUrls   : ['./search.component.scss'],
	templateUrl : './search.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NetworkingSearchLeftSectionComponent),
			multi: true
		}
	]
})

export class NetworkingSearchLeftSectionComponent implements ControlValueAccessor{

	@Input() placeholder : string = "Busca un chat o inicia uno nuevo";

	input     : string = '';
	onTouch   : any    = () => {}
	onChange  : any    = () => {}
	_control! : NgControl;

	constructor(@Inject(INJECTOR) private injector: Injector) {}

	ngOnInit() {
		this._control = this.injector.get(NgControl);
	}
	writeValue(input: string) {
		this.onTouch();
		this.input = input;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

}
