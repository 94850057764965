import firebase from 'firebase';
import { User } from '../../models/user';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorService } from '../error.service';
import { FilesService } from '../files.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { mergeMap, shareReplay, take, map } from 'rxjs/operators';
import { DAYSDIFF, MESSAGE_STATUS, DEFAULT_PROFILE_IMG } from '../../data/networking.data';
import { NetworkingMessageInterface, NetworkingContactLastMessageInformation, NetworkingInterface, NetworkingChatInterface, NetworkingChatMessageInterface, NetworkingStylingInterface } from '../../models/networking.interface';

import * as moment from 'moment-timezone';

const _        = require('lodash');
const TIMEZONE = moment.tz.guess();

@Injectable({providedIn: 'root'})

export class NetworkingService {

	constructor(
		private database    : AngularFireDatabase,
		private firestore   : AngularFirestore,
		private errorServ   : ErrorService,
		private fileService : FilesService
	) { }

	
	// 
	getFriendByID( userID : string ) : false | Observable<User> {
		try {
			return this.database.object<User>(`users/${userID}`)
								.valueChanges()
								.pipe( 
									mergeMap( async(user) => {
										if(user){
											if(user.image){
												user.image = await this.fileService.getDownloadLink(user.image);
											}else{
												user.image = DEFAULT_PROFILE_IMG;
											}

											if(user.lastSeen){
												if(moment.tz().diff( moment.tz(user.lastSeen, TIMEZONE), "minutes" ) > 5){
													user.lastSeenStatus = false;
												}
												else{
													user.lastSeenStatus = true;
												}
											}
											return user;
										}else{
											throw("Usuario no encontrado");
										}
									}),
									shareReplay(1)
								)
		} catch (error : any) {
			this.errorServ.handleErrors(`Ha ocurrido un error al obtener los contactos. Detalles: `,error);
		}
		return false;
	}

	getUserFriends( userID : string ) : false | Observable<User[]>{
		try {
			return this.firestore.collection<User>("users", ref => ref.where("friends", "array-contains", userID))
								.valueChanges()
								.pipe(
									mergeMap( users =>
										Promise.all(users.map( async(user) => {
											if(user.image){
												user.image = await this.fileService.getDownloadLink(user.image);
											}else{
												user.image = DEFAULT_PROFILE_IMG;
											}

											let lastMSG = await this.getFriendLastMessage(`${userID}___${user.uid}`);

											if(!lastMSG){
												lastMSG = await this.getFriendLastMessage(`${user.uid}___${userID}`);
											}
											// Si el ultimo mensaje es valido asignar
											if(lastMSG){
												user.lastMessage = lastMSG;
											}

											return user;
										}),									
									)),
									map( users => users.sort( (a,b) => {
										const A_LAST_MESSAGE = a.lastMessage?.lastMessage ? a.lastMessage.lastMessage : 0;
										const B_LAST_MESSAGE = b.lastMessage?.lastMessage ? b.lastMessage.lastMessage : 0;
										if(A_LAST_MESSAGE < B_LAST_MESSAGE){
											return 0;
										}

										if(A_LAST_MESSAGE > B_LAST_MESSAGE){
											return -1;
										}

										return 1;
									})),
									shareReplay(1)
								);
		} catch (error : any) {
			this.errorServ.handleErrors(`Ha ocurrido un error al obtener los contactos. Detalles: `,error);
		}
		return false;
	}

	async getFriendLastMessage( membersID : string ) : Promise<false | NetworkingContactLastMessageInformation> {

		try {
			let chat = await this.retrieveChatByMembersUID(membersID);

			if(chat){
				let messagesSnapshopt = await this.database.database.ref(`privateChats/messages/${chat.id}`).orderByKey().limitToLast(1).once("value");

				if(messagesSnapshopt.exists()){
					let message = Object.values(messagesSnapshopt.val())[0] as NetworkingChatMessageInterface;

					return {
						status      : message.seen_at ? MESSAGE_STATUS.seen : MESSAGE_STATUS.sent,
						message     : message.type && message.type == "img" ? "Foto" : message.message,
						sended_at   : this.transformDate(message.sent_at),
						lastMessage : message.sent_at
					};
				}else{
					return {
						status      : MESSAGE_STATUS.initial,
						message     : "",
						sended_at   : this.transformDate(chat.created_at),
						lastMessage : chat.created_at
					};
				}
			}
		} catch (error : any) {
			this.errorServ.handleErrors(`Ha ocurrido un error al obtener el ultimo chat. Detalles: `,error);
		}
		return false;
	}

	transformDate(timestamp : number) : string{

		let now      = moment().locale("es");
		let date     = moment.tz(timestamp, TIMEZONE).locale("es");
		let daysDiff = now.diff(date, "days");

		switch( daysDiff ){
			case DAYSDIFF.today              : return date.format("hh:mm a");
			case DAYSDIFF.yesterday          : return "Ayer";
			case DAYSDIFF.dayBeforeYesterday : return "Antier";
			default:
				if(daysDiff <= 7){
					return date.format("dddd");
				}else{
					return date.format("DD/MM/YYYY");
				}
		}
	}

	async getAttendeesIndex( eventID : number ){
		return await this.database.list<string>(`indexes/users/${eventID}`)
					.valueChanges()
					.pipe( take(1) )
					.toPromise();
	}

	async getAttendeesByFilter( eventID : number, filter : string ) : Promise<User[]>{
		let usersRef = this.firestore.collection<User>("users");

		const [jobs,names,lastNames] = await Promise.all([
			usersRef.ref.where('job',      '>=', filter).where('job',      '<=', `${filter}~`).where(`events`, "array-contains", eventID).get(),
			usersRef.ref.where('name',     '>=', filter).where('name',     '<=', `${filter}~`).where(`events`, "array-contains", eventID).get(),
			usersRef.ref.where('lastName', '>=', filter).where('lastName', '<=', `${filter}~`).where(`events`, "array-contains", eventID).get()
		]);

		return await Promise.all(
			_.concat(jobs.docs, names.docs, lastNames.docs).map( async(userSnapshot : any) => {
				let user = userSnapshot.data();

				if(user.image){
					user.image = await this.fileService.getDownloadLink(user.image);
				}else{
					user.image = DEFAULT_PROFILE_IMG;
				}

				return user;
			})
		);
	}

	async getByMembersID( memebers_sort_ids : string ) : Promise<false | NetworkingChatInterface>{
		try {
			let response = await this.database.database.ref(`privateChats`).orderByChild("memebers_sort_ids").equalTo(memebers_sort_ids).once("value");

			if(response.exists()){
				return Object.values( response.val() )[0] as NetworkingChatInterface;
			}
		} catch (error : any) {
			this.errorServ.handleErrors(`Ha ocurrido un error al obtener el ultimo chat. Detalles: `,error);
		}
		return false;
	}

	async updateLastSeen( userUID : string ){
		await this.database.database.ref(`users/${userUID}`).update({ lastSeen : firebase.database.ServerValue.TIMESTAMP });
	}

	async getAllUsersID(){
		// let resp = await this.database.list<User>('users').valueChanges()
		// .pipe(
		// 	map(users => users.map((user) => {
		// 		return user.uid;
		// 	})),
		// 	take(1)
		// )
		// .toPromise();

		// resp.forEach(async(user) =>{
		// 	await this.database.database.ref(`users/${user}`).update({job : "      "});
		// 	console.log(`Usuario :${user}`)
		// })

		//
	}
	
	async newFriend( currentUserID : string, friendID : string ){
		let users_sort_ids =  [friendID, currentUserID].sort().join('___');
		let chat           = await this.retrieveChatByMembersUID(users_sort_ids);
	
		if(!chat){
			await this.create(users_sort_ids, currentUserID, friendID);
		}
	}

	async addAsFriendIfIsNotAdded( currentUserID : string, friendsIDS : string[] ){
		await this.database.database.ref(`users/${currentUserID}/friends`).set(friendsIDS);						
	}

	async retrieveChatByMembersUID( membersID : string ) : Promise<false | NetworkingInterface>{
		try {
			let chatSnapshopt = await this.database.database.ref("privateChats").orderByChild("memebers_sort_ids").equalTo(membersID).once("value");

			if(chatSnapshopt.exists()){
				return Object.values(chatSnapshopt.val())[0] as NetworkingInterface;
			}
		} catch (error : any) {
			this.errorServ.handleErrors(`Ha ocurrido un error al obtener el ultimo chat. Detalles: `,error);
		}
		return false;
	}

	async create( membersID : string, currentUserID : string, friendID : string){
		try{
			let reference = this.database.database.ref(`privateChats`).push();
			//@ts-ignore
			let chat : NetworkingInterface = {
				// @ts-ignore
				created_at : firebase.default.database.ServerValue.TIMESTAMP,
				// @ts-ignore
				updated_at : firebase.default.database.ServerValue.TIMESTAMP,
				// @ts-ignore
				id                : reference.key,
				memebers_sort_ids : membersID,
				status   : {
					creator : {id : currentUserID, status : true},
					friend  : {id : friendID,      status : friendID == currentUserID},
				}
			}
			await reference.set(chat);
		}catch(error : any){
		  this.errorServ.handleErrors(`Ocurrio un error al generar un nuevo chat. Detalles: `,error);
		}
	}
	
	retrieveChatMessages( chatID : string ) : Observable<NetworkingChatMessageInterface[]> {
		return this.database.list<NetworkingChatMessageInterface>(`privateChats/messages/${chatID}`,
								ref => ref.limitToLast(100)
							).valueChanges().pipe(
								mergeMap(messages => Promise.all(
									messages.map(async(message) => {
										if(message.type == "img"){
											message.message = await this.fileService.getDownloadLink(message.message);
										}
										return message;
									})
								)),
								shareReplay(0),
							);
	}

	async pendingChat(user_id : string, friend_id : string) : Promise<void>{
		try {
		  	let pending_chat = await this.database.object<User>(`users/${user_id}`)
												.valueChanges()
												.pipe(
													take(1),
													map( user => {
														if(user){
															if(user.pending_chat){
																if(!user.pending_chat.includes(friend_id) && user_id != friend_id){
																	user.pending_chat.push(friend_id);
																}
															}
															else{
																if(user_id != friend_id){
																	  user.pending_chat = [friend_id];
																}else{
																	  return false;
																}
															}
															return user.pending_chat;
														}else{
															  return false;
														}
													})
												)
												.toPromise();
	
			if(pending_chat != false){
				await this.database.database.ref(`users/${user_id}/pending_chat`).set(pending_chat);
			}
		} catch (error : any) {
		  	this.errorServ.handleErrors(`Ocurrio un error al asignar chat pendiente. Detalles: `,error);
		}
	}

	async sendMessage(chat_id : string, message : NetworkingChatMessageInterface){
		try {
			let ref = this.database.database.ref(`privateChats/messages/${chat_id}`).push();

			message.id      = ref.key as any;
			message.sent_at = (firebase as any).default.database.ServerValue.TIMESTAMP;

			ref.set(message);
			return true;
		} catch (error : any) {
			this.errorServ.handleErrors(`Ocurrio un error al enviar un nuevo mensaje. Detalles: `,error);
			return false;
		}
	}

	retrieveStyles(eventFID : string, styleUUID : string) : Observable<NetworkingStylingInterface | null>{
		return this.database.object<NetworkingStylingInterface>(`networkingStyling/${eventFID}/${styleUUID}`)
							.valueChanges()
							.pipe(
								mergeMap( async(style) => {	
									// Cargar imagenes
									if(style?.images.chatEmpty){
										style.images.chatEmpty = await this.fileService.getDownloadLink(style.images.chatEmpty);
									}
									if(style?.images.chatFriend){
										style.images.chatFriend = await this.fileService.getDownloadLink(style.images.chatFriend);
									}
									if(style?.images.background){
										style.images.background = await this.fileService.getDownloadLink(style.images.background);
									}
									
									return style;
								})
							)
	}
	
	async removePendingChat(friend_id : string, user_id : string) : Promise<void>{
		try {
			let pending_chat = await this.database.object<User>(`users/${user_id}`)
												.valueChanges()
												.pipe(
													take(1),
													map( user => {
													if(user && user.pending_chat){
														if(user.pending_chat.includes(friend_id)){
															return user.pending_chat.filter( chat => chat != friend_id );
														}else{
															return user.pending_chat;
														}
													}
													return [];
													})
												)
												.toPromise();
		  	await this.database.database.ref(`users/${user_id}/pending_chat`).set(pending_chat);
		} catch (error : any) {
		  	this.errorServ.handleErrors(`Ocurrio un error al remover chat pendiente. Detalles: `,error);
		}
	}

	async updateFriends( friends : string[], userID : string ) : Promise<boolean>{
		try{
			await this.database.database.ref(`users/${userID}/friends`).set(friends);
			return true;
		}
		catch( error : any ){
			this.errorServ.handleErrors(`Ocurrio un error al actualizar a los amigos: `,error);
		}
		return false;
	}
}
