<article id="left-side-chats-container" scrollable (scrollPosition)="scroll.emit($event)">

	<div *ngIf="filteredData.length == 0; else elseBlock">
		<ng-container [ngTemplateOutlet]="listFriends" [ngTemplateOutletContext]="{users : friends}"></ng-container>
	</div>
	<ng-template #elseBlock>
		<ng-container [ngTemplateOutlet]="listFriends" [ngTemplateOutletContext]="{users : filteredData}"></ng-container>
	</ng-template>

</article>




<ng-template #listFriends let-users='users'>
	<div class="chat-container" *ngFor="let friend of users">
		<div class="chat-img-container" (click)="openChat(friend)" style="cursor: pointer">
			<div class="chat-img-wrapper">
				<div class="chat-img">
					<img [src]="friend.image" alt="">
				</div>
			</div>
		</div>

		<div class="chat-information-container">
			<div class="chat-user-name" (click)="openChat(friend)" style="cursor: pointer">
				<p>{{ friend.name }} {{ friend.lastName }}</p>
				<p>{{ friend.lastMessage?.sended_at }}</p>
			</div>
			<div class="chat-user-msg">

				<!-- <div class="chat-user-pending" matBadge="1" *ngIf="currentUser?.pending_chat?.includes(friend.uid)"></div> -->
				<!--  -->
				<div class="chat-user-pending-container" >
					<span class="chat-user-pending" *ngIf="currentUser?.pending_chat?.includes(friend.uid) && showNotifications">
						<mat-icon>notifications</mat-icon>
					</span>

					<span class="friendOptions">
						<mat-icon [matMenuTriggerData]="{friend: friend}" [matMenuTriggerFor]="friendOptions">expand_more</mat-icon>
					</span>
					
				</div>

				<div class="chat-user-msg-sent" (click)="openChat(friend)" style="cursor: pointer">
					<!-- <mat-icon *ngIf="messageStatus.sent == friend.lastMessage?.status" class="sent">done_all</mat-icon>
					<mat-icon *ngIf="messageStatus.seen == friend.lastMessage?.status" class="read">done_all</mat-icon> -->

					<p class="preview">{{ friend.lastMessage?.message }}</p>
				</div>

			</div>
		</div>
    </div>
</ng-template>


<mat-menu #friendOptions="matMenu">
	<ng-template matMenuContent let-friend="friend">
		<button mat-menu-item (click)="removeFriend(friend)">
			<span>Eliminar chat</span>
		</button>
	</ng-template>
</mat-menu>