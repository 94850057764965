import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, concat, from, Observable, of, throwError } from 'rxjs';

import { mergeMap, withLatestFrom, filter, tap, take, concatMap, map, catchError, switchMap, takeUntil, skipUntil } from 'rxjs/operators';
import { EventsService } from '../../services/event.service';
import { ImagesService } from '../../services/images.service';
import { EventActionsTypes, LoadEvent, EventLoaded, EventError, EventListenerStarted, LoadEventImagesURLS, LoadedEventImagesURLS, StartPlayerCounter, StopPlayerCounter, StoppedPlayerCounter, StartedPlayerCounter, ErrorPlayerCounter, LoadEventWelcome, EventActions, LoadedEventWelcome, NotFoundEventWelcome } from './event.actions';
import { EventState } from './event.reducers';
import { isEventLoaded, selectEvent, selectEventActiveSubscription } from './event.selectors';
import { StoreRootState } from '../index.reducer';
import { MetricsService } from '../../services/metrics.service';
import { currentUser } from '../../auth/selectors/auth.selectors';
import { MagixEvent } from '../../models/event';
import { WelcomeService } from '../../services/welcome.service';
import { Router } from '@angular/router';
import { Action } from 'rxjs/internal/scheduler/Action';
import { HelperService } from '../../services/helper.service';
import { selectRouteParam, selectRouteParams } from '../router-store/router.selectors';
import { Title } from '@angular/platform-browser';

@Injectable()
export class EventEffects {

    offEventSubscription = new BehaviorSubject(false);

    // Effect cuando se solicita cargar el evento
    loadEvent$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadEvent>(EventActionsTypes.LoadEvent),
                withLatestFrom(this.store.pipe(select(isEventLoaded))),
                filter(([action, _isEventLoaded]) => !_isEventLoaded),
                map(([action, _isEventLoaded]) => this.eventsService.startEventListener(action.payload.eventID)),
                catchError((error) => {
                    console.warn("Error al cargar el evento via Promise. Detalles: ", error);
                    return throwError(error);
                })
            ), { dispatch: false }
    );

    eventLoaded$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<EventLoaded>(EventActionsTypes.EventLoaded),
                withLatestFrom(this.store.pipe(select(selectRouteParams))),
                switchMap(([action, params]) => {
                    if (action.payload.event) {
                        let event: MagixEvent = JSON.parse(JSON.stringify(action.payload.event));
                        this.titleService.setTitle(event.title);
                        const { id, name } = params;
                        let eventTitle = event.title;
                        let sanitizedEventTitle = this.helper.eventTitle2permalink(eventTitle)

                        let actions: EventActions[] = [];

                        if (name !== sanitizedEventTitle) {
                            return [new EventError()];
                        }

                        if (id && name) {
                            // new LoadEventWelcome({ event: JSON.parse(JSON.stringify(action.payload.event)) })
                            if (action.payload.loadImages == true) {
                                actions.push(new LoadEventImagesURLS({ event: action.payload.event }));
                            } else {
                                actions.push(new LoadedEventImagesURLS({ event: action.payload.event }));
                            }
                        }

                        return actions;
                    } else {
                        console.warn("No se cargo correctamente el evento. Datos recibidos: ", action.payload);
                        return [new EventError()];
                    }
                }),
                catchError((error) => {
                    console.warn("No se cargo correctamente el evento. Detalles de error: ", error);
                    return throwError(error);
                })
            )
    );

    onEventError$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<EventError>(EventActionsTypes.EventError),
                map(action => {
                    this.router.navigate(["404"]);
                })
            ), { dispatch: false }
    );

    /* loadEventWelcome$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadEventWelcome>(EventActionsTypes.LoadEventWelcome),
                mergeMap(async (action): Promise<[LoadEventWelcome, any]> => {
                    let editor: any | null = null;
                    try {
                        if (action.payload.event) {
                            let event = action.payload.event;
                            editor = await this.welcomeService.getEditor(event.fid).pipe(take(1), map(snapshot => snapshot.payload.data())).toPromise();
                        } else {
                            console.warn("No se cargo correctamente el evento. Datos recibidos: ", action.payload);
                        }
                    } catch (error) {
                        console.warn("No se cargo correctamente la bienvenida: ", action.payload);
                    }
                    return [action, editor];
                }),
                map(([action, editor]) => !editor ? new NotFoundEventWelcome() : new LoadedEventWelcome({ event: JSON.parse(JSON.stringify(action.payload.event)) }))
            ),
    );

    loadedEventWelcome$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadedEventWelcome>(EventActionsTypes.LoadedEventWelcome),
                map(action => {
                    let { event } = action.payload;
                    this.routerService.navigate([event.id, event.title, "welcome"]);
                })
            ), { dispatch: false }
    ); */

    /**
    * Este effect se ejecuta cuando un evento ha sido cargado y se le paso el parametro loadImages en true,
    * lo que indica que debe de cargar los urls de las imagenes de storage de google.
    */
    LoadEventImagesURLS$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadEventImagesURLS>(EventActionsTypes.LoadEventImagesURLS),
                mergeMap(async action => {
                    let event: MagixEvent = JSON.parse(JSON.stringify(action.payload.event));
                    try {
                        if (event) {
                            event.logo = await this.imagesService.getImage(event.logo);
                            event.banners.stands = await this.imagesService.getImage(event.banners.stands);
                            event.adminThumbnail = await this.imagesService.getImage(event.adminThumbnail);
                            event.banners.breakoutRoom = await this.imagesService.getImage(event.banners.breakoutRoom);
                            event.banners.attendees = await this.imagesService.getImage(event.banners.attendees);
                            event.banners.certificates = await this.imagesService.getImage(event.banners.certificates);
                            event.colors.login.backgroundImage = await this.imagesService.getImage(event.colors.login.backgroundImage);
                        } else {
                            console.warn("Ha ocurrido un error al cargar las imagenes. Action: ", action);
                        }
                    } catch (error) {
                        console.warn("Ha ocurrido un error al cargar las imagenes. Action: ", action, "Detalles del error: ", error);
                    }
                    return event;
                }),
                map((event) => new LoadedEventImagesURLS({ event }))
            )
    );

    /*   onStartPlayerCounter$ = createEffect(() => {
          return this.actions$.pipe(
              ofType<StartPlayerCounter>(EventActionsTypes.StartPlayerCounter),
              withLatestFrom(this.store.pipe(select(currentUser))),
              mergeMap(([action, user]) => {
                  let { event, room } = action.payload;
     
                  if (!event || !room || !user)
                      return Promise.resolve(false);
                  else
                      return this.metricsService.startPlayerCounter(user, event, room);
              }),
              map((result) => result ? new StartedPlayerCounter() : new ErrorPlayerCounter())
          );
      });
     
      onStopPlayerCounter$ = createEffect(() => {
          return this.actions$.pipe(
              ofType<StopPlayerCounter>(EventActionsTypes.StopPlayerCounter),
              withLatestFrom(this.store.pipe(select(currentUser))),
              mergeMap(([action, user]) => {
                  let { event, room } = action.payload;
     
                  if (!event || !room || !user)
                      return Promise.resolve(false);
                  else
                      return this.metricsService.stopPlayerCounter(user, event, room);
              }),
              map((result) => result ? new StartedPlayerCounter() : new ErrorPlayerCounter())
          );
      }); */

    constructor(
        private router: Router,
        private actions$: Actions,
        private eventsService: EventsService,
        private store: Store<StoreRootState>,
        private imagesService: ImagesService,
        private helper: HelperService,
        private titleService: Title
    ) {
    }

}