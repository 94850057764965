import { Store                               } from '@ngrx/store';
import { Injectable                          } from '@angular/core';
import { shareReplay, take, mergeMap, map } from 'rxjs/operators';
import { Subscription                        } from 'rxjs';
import { OnDemandData, RoomsVideosClass, RoomsVideosInterface, OnDemandVideo } from '../models/ondemand';
import { StoreRootState                      } from '../ngrx-store/index.reducer';
import { AngularFireDatabase                 } from '@angular/fire/database';
import { OnDemandLoaded, OnDemandLoadedEmpty } from '../ngrx-store/on-demand-store/on-demand.actions';
import { Conference } from 'src/app/core/models/conferences/conference';
import { ImagesService } from './images.service';
import { ErrorService } from './error.service';
import { HelperService } from './helper.service';

@Injectable({ providedIn: 'root' })

export class OnDemandService {
    //Constante de la ubicacion en firebase
    BASE_PATH: string = '/onDemand';
    subscription: Subscription | null = null;
    //Inicializar servicios
    constructor(
        private database : AngularFireDatabase,
        private store    : Store<StoreRootState>,
        private imagesService: ImagesService,
        private errorServ : ErrorService,
        private helper: HelperService
    ) { }

    initializeListener(fidEvent : string) : void {
        this.subscription = this.getByEvent(fidEvent).subscribe(onDemand => {
            if(onDemand){
                this.store.dispatch(new OnDemandLoaded({onDemand}));
            }else{
                this.store.dispatch(new OnDemandLoadedEmpty());
            }
        });
    }

    stopOnDemandListener(): void {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
        }
      }

    private getByEvent(event_fid: string){
        return this.database.object<OnDemandData>(`${this.BASE_PATH}/${event_fid}`)
                            .valueChanges()
                            .pipe(shareReplay(1));
    }

    async getRoomVideos( event_fid : string){
        return await this.database.list<Conference>(`conferences`).valueChanges()
                                .pipe(
                                    take(1),
                                    map( conferences => (conferences.filter( conference => (conference.eventFID ==  event_fid))[0]) ),
                                    map(conference => {
                                        this.helper.setCSSVariables(conference, undefined, "conference");
                                        return conference.rooms
                                    }),
                                    map( rooms => rooms.map( (room, index) => {
                                        return {
                                            id     : index,
                                            name   : room.name,
                                            videos : []
                                        }
                                    }))
                                )
                                .toPromise();
    }

    getVideosByRoomIDAndEventFID( room_id : number, event_fid : string){
        return this.database.list<OnDemandVideo>(`${this.BASE_PATH}/${event_fid}/videos`).valueChanges()
                            .pipe(
                                shareReplay(1),
                                map( videos => videos.filter( video => (video.room == room_id))),
                                mergeMap( videos => {
                                    return Promise.all(
                                        videos.map( async (video) => {
                                            let thumbnail = await this.imagesService.getVideoImage(video.url);
                                            if( !(typeof thumbnail == 'boolean') ){
                                                video.thumbnail = thumbnail;
                                            }
                                            return video;
                                        })
                                    );
                                }),
                                map( videos => videos.sort( (a, b) => a.order - b.order))
                            )
    }

    async updateVideo(fid: string, video : OnDemandVideo): Promise<void> {
        try{
            await this.database.database.ref(`${this.BASE_PATH}/${fid}/videos/${video.id}`).update(video);
        }catch(error){
            this.errorServ.handleErrors(`Error al actual videos en base de datos FID ${fid}. Más detalles: `,error);
        }
    }
}

