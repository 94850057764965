<div class="center-topbar">
	<div id="center-profile-img">

		<button (click)="showChats.emit(false)" mat-icon-button id="showChatsButton">
			<mat-icon id="back-icon">chevron_left</mat-icon>
		</button>

		<div id="center-profile-img__container">
			<div id="center-profile-img__container-wrapper">
				<img [src]="friend.image" alt="">
			</div>
		</div>

		<div class="profile-data-short">
			<p class="profile-name">{{ friend.name }} {{ friend.lastName }}</p>
			<p class="profile-status" *ngIf="friend.lastSeenStatus">Conectado</p>
		</div>
	</div>
  
	<div id="center-profile-actions">
		<div id="center-profile-actions__container">
			<button mat-icon-button [matMenuTriggerFor]="menu">
				<mat-icon>more_vert</mat-icon>
			</button>
		</div>
	</div>
</div>


<mat-menu #menu="matMenu" xPosition="before">
	<button (click)="showFriend.emit(false)" mat-menu-item>
		<span>Perfil</span>
	</button>
</mat-menu>