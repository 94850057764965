import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { authReducer, AuthState } from '../auth/reducers/auth.reducers';
import { eventReducer, EventState } from './event-store/event.reducers';
import { onDemandReducer, OnDemandState } from './on-demand-store/on-demand.reducers';
import { appReducer, AppState } from './app-store/app.reducers';
import { FormState, formReducer } from './form-store/form.reducers';

export interface StoreRootState {
    router: fromRouter.RouterReducerState<any>;
    auth: AuthState,
    event: EventState,
    app: AppState,
    onDemand: OnDemandState,
    form: FormState
}
export const AppReducers: ActionReducerMap<StoreRootState, any> = {
    router: fromRouter.routerReducer,
    event: eventReducer,
    auth: authReducer,
    app: appReducer,
    onDemand: onDemandReducer,
    form: formReducer
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        console.log('DEBUG ACTION => ', action);
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [debug];
