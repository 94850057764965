<section *ngIf="event">
    <div class="attendes-bg"></div>
    <app-main-banner [banner]="event.banners.attendees"></app-main-banner>
    <div class="row expositor">
        <div class="col-md-4">
            <app-attendees-list [event]="event" (selectedUser)="showUser($any($event))"></app-attendees-list>
        </div>
        <div class="col-md-8 expositor">
            <app-attendees-show [user]="selectedAttender"></app-attendees-show>
        </div>
    </div>
</section>

