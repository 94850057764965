<div id="left-side-searcher-container">
	<label for="search-input">
		<mat-icon>search</mat-icon>
	</label>
	<input
			id="search-input"
			type="text"
			(blur)="onTouch()"
			[(ngModel)]="input"
			[placeholder]="placeholder"
			(ngModelChange)="onChange($event)">
</div>
