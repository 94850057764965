import { selectEvent } from '../../../core/ngrx-store/event-store/event.selectors';
import { Subscription } from 'rxjs';
import { LogoutService } from '../../../core/services/logout.service';
import { Store, select } from '@ngrx/store';
import { StoreRootState } from '../../../core/ngrx-store/index.reducer';
import { skipWhile, take } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import * as uuid from 'uuid';
import { GrapeJSService } from 'src/app/core/services/grapejs.service';

@Component({templateUrl: './thanks-for-coming.component.html'})

export class ThanksForComingComponent implements OnDestroy {
  subscriptions : Subscription[] = [];
  uidTemplate   : string = `component_${uuid.v4()}_component`;
  /* @ts-ignore */
  event       : MagixEvent;

  constructor( 
    private logoutService : LogoutService,
    private store         : Store<StoreRootState>,
    private grapeService : GrapeJSService
  ){ 
    this.retrieveEditorData();
  }

  private async retrieveEditorData(){
    this.event = await this.store.pipe(select(selectEvent), skipWhile(data => data == null)).pipe(take(1)).toPromise();
    this.subscriptions.push(
      this.logoutService.getEditor(`${this.event.fid}`).subscribe( response => {
        this.grapeService.printHTML(this.uidTemplate, response.payload.data())
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
