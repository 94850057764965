import { createSelector } from "@ngrx/store";
import { StoreRootState } from "../index.reducer";
import { EventState } from "./event.reducers";

export const selectEventState = (state: StoreRootState): EventState => state.event;

export const isEventLoaded = createSelector(
    selectEventState,
    storeEvent => storeEvent.eventLoadStatus == "loaded"
);

export const isEventLoadedWithImages = createSelector(
    selectEventState,
    storeEvent => storeEvent.eventLoadStatus == "loaded" && storeEvent.loadedEventImages
);

export const selectEventActiveSubscription = createSelector(
    selectEventState,
    storeEvent => storeEvent.eventActiveSubscription
);

export const selectEvent = createSelector(
    selectEventState,
    storeEvent => storeEvent.event
);

export const selectEventID = createSelector(
    selectEventState,
    storeEvent => storeEvent.eventID
);

export const selectRoom = createSelector(
    selectEventState,
    storeEvent => storeEvent.room
);
