import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map } from 'rxjs/operators';
import { OnDemandData, OnDemandVideo } from '../../models/ondemand';
import { EventsService } from '../../services/event.service';
// import { EventService } from '../../services/event.service';
import { ImagesService } from '../../services/images.service';
import { OnDemandService } from '../../services/ondemand.service';
import { InitializeOnDemandListener, LoadedOnDemandImages, LoadOnDemandImages, OnDemandActionsTypes, OnDemandLoaded, StopOnDemandListener } from './on-demand.actions';
// import { OnDemand } from '../../models/events/eventModerator.model';

@Injectable()
export class OnDemandEffects {

    InitializeOnDemandListener$ = createEffect(() => {        
        return this.actions$
            .pipe(
                ofType<InitializeOnDemandListener>(OnDemandActionsTypes.InitializeOnDemandListener),
                map((value) => this.ondemandService.initializeListener(value.payload.event_fid)),
            )
    }, { dispatch: false });

    StopOnDemandListener$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType<StopOnDemandListener>(OnDemandActionsTypes.StopOnDemandListener),
                map((value) => this.ondemandService.stopOnDemandListener()),
            )
    }, { dispatch: false });

    OnDemandLoaded$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<OnDemandLoaded>(OnDemandActionsTypes.OnDemandLoaded),
                map((value) => new LoadOnDemandImages({ onDemand: value.payload.onDemand })),
            )
    );

    LoadOnDemandImages = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadOnDemandImages>(OnDemandActionsTypes.LoadOnDemandImages),
                mergeMap(async (action) => {
                    let ondemand : OnDemandData = JSON.parse(JSON.stringify(action.payload.onDemand));
                    ondemand.backgroundImage = await this.imagesService.getImage(ondemand.backgroundImage);
                    return ondemand;
                }),
                map(onDemand => new LoadedOnDemandImages({ onDemand }))
            )
    );

    constructor(
        private actions$: Actions,
        private ondemandService : OnDemandService,
        private imagesService: ImagesService,
    ) {
    }

}