import { User } from 'src/app/core/models/user';
import { MagixEvent } from 'src/app/core/models/event';
import { selectEvent } from 'src/app/core/ngrx-store/event-store/event.selectors';
import { Store, select } from '@ngrx/store';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { skipWhile, take } from 'rxjs/operators';
import { NetworkingService } from 'src/app/core/services/networking/networking.service';
import { NetworkingChatInterface } from 'src/app/core/models/networking.interface';
import { AttendeesPaginationService } from 'src/app/core/services/networking/attendees.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
	selector    : 'networking-attendees-list-left-section',
	styleUrls   : ['./list.component.scss'],
	templateUrl : './list.component.html',
})

export class NetworkingAttendeesListLeftSectionComponent {
	@Output() chat       : EventEmitter<NetworkingChatInterface> = new EventEmitter();

	@Input() search      : string = "";
	@Input() currentUser : User | null = null;

	event$ = this.store.pipe(select(selectEvent), skipWhile(data => data == null),take(1)).toPromise();
	event!  : MagixEvent;
	indexes : string[] = [];

	filteredData : User[] = []

	constructor(
		private store                      : Store<StoreRootState>,
		private networkingService          : NetworkingService,
		public attendeesPaginationService : AttendeesPaginationService
	){
		this.getCurrentEvent();
	}

	async getCurrentEvent(){
		let event = await this.event$;

		if(event){
			this.event = event;
			this.getAttendees();
			// this.getAttendeesIndexes();
		}
	}

	private async getAttendees(){
		this.attendeesPaginationService.init(this.event.id);
	}

	scrollHandler( destination : string ) {
		if (destination === 'bottom') {
			this.attendeesPaginationService.more(this.event.id)
		}
	}

	async checkIfAttendeeExists(){
		this.filteredData = await this.networkingService.getAttendeesByFilter(this.event.id, this.search);
	}

	// async getAttendeesIndexes(){

	// 	let event = await this.event$;

	// 	if(event){
	// 		this.indexes = await this.networkingService.getAttendeesIndex(event.id);

	// 		console.log(this.indexes);
	// 	}
	// }

}
