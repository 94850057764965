import { Injectable            } from '@angular/core';
// Services
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  BASE_PATH: string = '/logout';

  constructor(private firestore   : AngularFirestore) { }


  getEditor(doc_id : string){
    return this.firestore.collection(this.BASE_PATH).doc(doc_id).snapshotChanges();    
  }
}
