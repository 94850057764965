<footer id="sender-container">

	<div id="attachments">
		<button mat-icon-button class="emoji-message" (click)="emojiVisible = !emojiVisible">
			<mat-icon class="material-icons-outlined">emoji_emotions</mat-icon>
		</button>

		<button mat-icon-button class="attach-image m-0" (click)='inputFile.click()'>
			<mat-icon class="material-icons-outlined">image</mat-icon>
		</button>

		<input  #inputFile
				id="sender-img" 
				type="file" 
				name="sender-img" 
				style="display: none"
				accept="image/*" 
				(change)="previewPreUploadedImage($event)"
		>

	</div>

	<div id="searcher">
		<input type="text" placeholder=" {{'chat.labelTextChat' | translate}} " #inputEmoji [(ngModel)]="message.message" />
	</div>

	<div id="sender">
		<button mat-icon-button class="send-message-button" (click)="sendMessage()">
			<mat-icon>send</mat-icon>
		</button>
	</div>
</footer>

<div [ngClass]="emojiVisible ?  'showEmojiMart' : 'hideEmojiMart'" id="emoji-container">
	<emoji-mart [style]="{width: '100%'}" class="emoji-container" [darkMode]="false" [color]="'#007bff'" [set]="'twitter'" [showPreview]="false" (emojiClick)="addEmoji($event, inputEmoji)"></emoji-mart>
</div>