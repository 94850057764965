<networking-conctacts-left-section  id="left-side-chats"
									[contacts]="this.attendeesPaginationService.data | async"
									[currentUser]="currentUser"
									[filteredData]="filteredData"
									[searchContacts]="search"
									[showNotifications]="false"
									(chat)="chat.emit($event)"
									(scroll)="scrollHandler($event)"
									(noMatches)="checkIfAttendeeExists()"
></networking-conctacts-left-section>
