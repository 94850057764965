import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/core/models/user';

@Component({
  selector    : 'networking-right-section',
  styleUrls   : ['./right-side-chats.component.scss'],
  templateUrl : './right-side-chats.component.html',
})

export class NetworkingRightSectionComponent {

	@Output() hideFriend     : EventEmitter<boolean> = new EventEmitter();

 	@Input() set friendProfile( value : User | null){
		if(value){
			this.friend = value;
		}
	}

 	friend!: User;
}
