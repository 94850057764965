export const environment = {
  production: true,
  geoTokenKey: 'AIzaSyA0ltrrx7O95qnx7VjqQ4hK8ZWL6ROEEeM',
  firebase: {
    apiKey: "AIzaSyCu2_YzRcqVpBn6Z0rdAdeOcS-jtvR62xc",
    authDomain: "mvs20-e8d3c.firebaseapp.com",
    databaseURL: "https://mvs20-e8d3c-default-rtdb.firebaseio.com",
    projectId: "mvs20-e8d3c",
    storageBucket: "mvs20-e8d3c.appspot.com",
    messagingSenderId: "784032464911",
    appId: "1:784032464911:web:0807c4ef6622dad2ef237a",
    measurementId: "G-TKGFWC7E34"
  },
  twitch: {
    clientID: "02p15qaqfclltf52mgjeava009a0ql",
    secret: "sa0keunx1y2l9ktrpw80llkagoz6zf",
  }
};
