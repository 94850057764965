import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore'
import { Observable } from 'rxjs';
import { RegisterForm } from '../_models/RegisterForm';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFireFunctions } from '@angular/fire/functions';
import { map, shareReplay, tap } from 'rxjs/operators';
import firebase from 'firebase';
import Swal from 'sweetalert2'
import { FormInterfaz } from '../models/events/form';
@Injectable({
  providedIn: 'root'
})
export class FormsService {
  FormsCollection: AngularFirestoreCollection<RegisterForm> | undefined;
  Forms: Observable<RegisterForm[]> | undefined;
  FormDocs: AngularFirestoreDocument<RegisterForm> | undefined;
  constructor(
    public firestoreDatabase: AngularFirestore,
    public afs: AngularFirestore,
    private storage: AngularFireStorage,
    private database: AngularFireDatabase,
    private functions: AngularFireFunctions
  ) { }
  /* Funcion principal para obtener datos */
  // getForms(formsCollection: AngularFirestoreCollection<RegisterForm>) {
  //   this.Forms = formsCollection.snapshotChanges().pipe(tap(arr => console.log(`read ${arr.length} docs`)), map(changes => {
  //     return changes.map(a => {
  //       const data = a.payload.doc.data() as RegisterForm;
  //       return data;
  //     });
  //   }), shareReplay(1));
  //   return this.Forms;
  // }
  /* Manda el query a la funcion principal; */
  // getAllForms() {
  //   this.FormsCollection = this.afs.collection('forms',
  //     ref => ref.orderBy("id", "asc"));
  //   return this.getForms(this.FormsCollection);
  // }
  // getForm(id: number) {
  //   this.FormsCollection = this.afs.collection('forms',
  //     ref => ref.where("id", "==", id));
  //   return this.getForms(this.FormsCollection);
  // }

  _getForm$(id: string) {
    let collectionRef = this.firestoreDatabase.collection<RegisterForm>('forms', ref => ref.orderBy("id", "asc"));
    return collectionRef.valueChanges().pipe(shareReplay(1));
  }
  
  async get(fid: string): Promise<FormInterfaz | false> {
    try {
        let response = await this.database.database.ref(`/forms/${fid}`).once("value").catch(erro => console.log("erro", erro));
        return response ? this.snapshotValidation<FormInterfaz>(response) : false;
    } catch (error) {
        console.log(`Error al buscar el formulario en base de datos. UID: ${fid} Más detalles: `, error);
        return false;
    }
}

  async updateFormInfo(form: RegisterForm, formValue: any, message: string) {
    //Ninos seguros
    if (form.id == "fblf57") {
      let dataToSend = {
        to: formValue.email,
        email: "ninosseguros",
        emailName: "Registro ¡Sí! “Niños Seguros Responsabilidad de Todos”",
        subject: "Confirmación de Registro",
        domain: "mvswebcast.com",
        formID: "fblf57"
      };
      console.log("Data to send", dataToSend);
      let res = await this.sendRegistrationEmail(dataToSend);
      console.log('email', res);
    }

    //Crianza
    if (form.id == "rbimcy") {
      let dataToSend = {
        name: formValue.name,
        to: formValue.email,
        email: "congresocrianza",
        emailName: "Congreso Crianza",
        subject: "Confirmación de Registro",
        domain: "mvswebcast.com",
        formID: "rbimcy"
      };
      console.log("Data to send", dataToSend);
      await this.sendRegistrationEmail(dataToSend);
    }

    //Century
    if (form.id == "sxncir") {
      let dataToSend = {
        to: formValue.email,
        email: "2daConvencionNacionalCENTURY21",
        emailName: "2daConvencionNacional CENTURY21",
        subject: "Confirmación de Registro",
        domain: "mvswebcast.com",
        formID: "sxncir"
      };
      console.log("Data to send", dataToSend);
      await this.sendRegistrationEmail(dataToSend);
    }

    if (form.id == "842ist") {
      let dataToSend = {
        to: formValue.email,
        email: "certificadoazul",
        emailName: "Certificado Azul",
        subject: "Confirmación de registro",
        domain: "mvswebcast.com",
        formID: "842ist",
        // html: "Te has registrado correctamente al evento "
      }
      await this.sendRegistrationEmail(dataToSend);
    }

    if (form.id == "9lj5ic") {
      await this.sendCustomEmail({ name: formValue.name + formValue.lastName, email: formValue.email }, "1");
    }

    if (form.id == "p8qwmt") {
      // console.log("formValue", formValue);
      await this.sendCustomEmail({ name: formValue.name + formValue.lastName, email: formValue.email }, "");
    }

    let res = await this.newRegisterData(formValue, form);
    if (res.status === 'OK') {

      Swal.fire({
        icon: 'success',
        title: message,
      });
      // this.allForms.reset();

    } else if (res.status === 'FAIL' && res.error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ha ocurrido un error.',
        footer: res.error
      });
    }
    return res;
  }

  async sendRegistrationEmail(data: registrationEmailData): Promise<{ status: "OK" | "ERROR" }> {
    try {
      const sendRegistrationEmailFN = this.functions.httpsCallable("sendRegistrationEmail");
      const response = await sendRegistrationEmailFN(data).toPromise();
      console.log("Response", response);
      return response;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }

  async sendCustomEmail(data: { email: string, name: string }, number: string): Promise<{ status: "OK" | "ERROR" }> {
    const sendCustomEmailFN = this.functions.httpsCallable("sendCustomEmail" + number);
    const response = await sendCustomEmailFN(data).toPromise();
    return response;
  }

  async newRegisterData(registerData: any, form: RegisterForm): Promise<{ status: string, error?: string }> {
    console.log('entre a registar data');
    let regData = JSON.parse(JSON.stringify(registerData));
    console.log(regData);
    regData.timestamp = new Date().getTime();
    for (let key of Object.keys(regData)) {
      if (typeof regData[key] === "object" && regData[key].files) {
        console.log('entre a subir imagenes')
        let filePath = `FormsData/${form.fid}/${form.formName}/${regData[key].fileNames}`;
        let res = await this.uploadImageAsPromise(filePath, regData[key].files[0]);
        if (res.status === 'FAIL') return { status: 'FAIL', error: res.error };
        regData[key] = filePath;
      }
      else if (typeof regData[key].getMonth === 'function') {
        console.log('no entre a subir imagenes');

        regData[key] = new Date(regData[key]).getTime();
      }
      console.log('no entre a ninguna de los 2');

    }

    try {
      let res = await this.database.database.ref(`formsData/${form.fid}`).push(registerData);
      return { status: 'OK' }
    } catch (error) {
      throw { status: 'FAIL', error }
    }

  }

  // uploadImageAsPromise(imagePath, imageFile) {
  //   return new Promise((resolve, reject) => {
  //     var task = this.storage.upload(imagePath, imageFile);
  //     task.then(() => {
  //       resolve("good");
  //     }).catch(function (error) {
  //       reject(error);
  //     });
  //   });
  // }
  async uploadImageAsPromise(imagePath: string, imageFile: any): Promise<{ status: string, task?: firebase.storage.UploadTaskSnapshot, error?: string }> {
    try {
      var task = await this.storage.upload(imagePath, imageFile);
      return { status: 'OK', task };
    } catch (error) {
      throw { status: 'FAIL', error };
    }
  }

  async _getForm(id: string): Promise<false | RegisterForm> {
    try {
      let collectionRef = this.firestoreDatabase.collection<RegisterForm>('forms').ref.where('id', '==', id);
      let formSnap = await collectionRef.get();
      return formSnap.empty ? false : formSnap.docs[0].data() as RegisterForm;
    } catch (error) {
      console.log('Ha ocurrido un error al obtener el formulario', error);
      return false;
    }
  }

  private snapshotValidation<T>(snapshot: firebase.database.DataSnapshot): T | false {
    return snapshot.exists() ? snapshot.val() as T : false;
}

  //   getFormByEventSemeOpen(id: number) {
  //     this.FormsCollection = this.afs.collection('forms',
  //       ref => ref.where("eventid", "==", id).where("type", "==", 'withLoginInside'));
  //     return this.getForms(this.FormsCollection);
  //   }
  //   async newForm(newForm: RegisterForm) {
  //     const idCover = Math.random().toString().substring(2);
  //     const idLogo = Math.random().toString().substring(2);
  //     let filesPathBackground = `Forms/${newForm.id}/${newForm.formName}/Cover/${idCover}`;
  //     let filesPathLogo = `Forms/${newForm.id}/${newForm.formName}/Logo/${idLogo}`;
  //     //@ts-ignore
  //     this.storage.upload(filesPathBackground, newForm.backgroundImg.files[0]);
  //     //@ts-ignore
  //     this.storage.upload(filesPathLogo, newForm.logoImg.files[0]);
  //     newForm.backgroundImg = filesPathBackground;
  //     newForm.logoImg = filesPathLogo;
  //     newForm.timestamp = new Date().getTime();
  //     let docRef = this.afs.firestore.collection('forms').doc();
  //     newForm.fid = docRef.id;
  //     let response = docRef.set(newForm).then(() => {
  //       return newForm;
  //     }).catch((error:string) => {
  //       console.error("Error al crear: ", error);
  //     });

  //     return response;
  //   }
  //   newRegisterData(registerData:any, form: RegisterForm) {
  //     return new Promise<any | void | void>((resolve, reject) => {
  //       registerData.timestamp = new Date().getTime();
  //       for (let key of Object.keys(registerData)) {
  //         if (typeof registerData[key] === "object" && registerData[key].files) {
  //           let filePath = `FormsData/${form.fid}/${form.formName}/${registerData[key].fileNames}`;
  //           this.uploadImageAsPromise(filePath, registerData[key].files[0]).then((res) => {
  //           }).catch(() => reject("Error al subir el registro"));
  //           registerData[key] = filePath;
  //         }
  //         else if (typeof registerData[key].getMonth === 'function') {
  //           registerData[key] = new Date(registerData[key]).getTime();
  //         }
  //       }
  //       this.database.database.ref(`formsData/${form.fid}`).push(registerData).then(() => {
  //         resolve();
  //       }).catch(() => {
  //         reject("Error al subir el registro")
  //       });

  //     })

  //   }
  //   uploadImageAsPromise(imagePath, imageFile) {
  //     return new Promise((resolve, reject) => {
  //       var task = this.storage.upload(imagePath, imageFile);
  //       task.then(() => {
  //         resolve("good");
  //       }).catch(function (error) {
  //         reject(error);
  //       });
  //     });
  //   }
  //   getReigsterData(formFID: string, email: string) {
  //     return this.database.list<any>(`formsData/${formFID}`, (reference) => reference.orderByChild("email").equalTo(email).limitToLast(1)).valueChanges();
  //   }

  //   /**
  //    * Funcion desarrollada para enviar un email personalizado Agrichile 2021
  //    * @param data 
  //    */
  //   async sendCustomEmail(data: { email: string, name: string }, number: string): Promise<{ status: "OK" | "ERROR" }> {
  //     const sendCustomEmailFN = this.functions.httpsCallable("sendCustomEmail" + number);
  //     const response = await sendCustomEmailFN(data).toPromise();
  //     console.log("Response", response);
  //     return response;
  //   }

  //   async sendRegistrationEmail(data: registrationEmailData): Promise<{ status: "OK" | "ERROR" }> {
  //     try {
  //       const sendRegistrationEmailFN = this.functions.httpsCallable("sendRegistrationEmail");
  //       const response = await sendRegistrationEmailFN(data).toPromise();
  //       console.log("Response", response);
  //       return response;
  //     } catch (error) {
  //       console.error("error", error);
  //     }
  //   }

  // }


  // export interface registrationEmailData {
  //   to: string;
  //   email: string;
  //   emailName: string;
  //   subject: string;
  //   domain: string;
  //   formID: number | string;
  //   html?: string;
  // }
}


export interface registrationEmailData {
  to: string;
  email: string;
  emailName: string;
  subject: string;
  domain: string;
  formID: number | string;
  html?: string;
}