import { User } from 'src/app/core/models/user';
import { FilesService } from 'src/app/core/services/files.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkingService } from 'src/app/core/services/networking/networking.service';
import { NetworkingInterface } from 'src/app/core/models/networking.interface';
import { Component, HostListener, Input } from '@angular/core';
import { NetworkingChatMessageInterface } from 'src/app/core/models/networking.interface';

@Component({
	selector: 'networking-sender-center-section',
	styleUrls: ['./sender.component.scss'],
	templateUrl: './sender.component.html',
})
export class NetworkingSenderCenterSectionComponent {
	@Input() chat        : NetworkingInterface | null = null;
	@Input() friend      : User | null = null;
	@Input() currentUser : User | null = null;

	@HostListener('window:keydown', ['$event'])
	keyEvent(event: KeyboardEvent) {
		if(event.code == "Escape" && this.emojiVisible){
			this.emojiVisible = false;
		}
		if(event.code == "Enter"){
			this.sendMessage(); 
		}
	}

	chat_id           : string  = '';
	emojiVisible      : boolean = false;	

	message : NetworkingChatMessageInterface = {
		id         : "",
		type       : "",
		message    : "",
		user_id    : "",
		sent_at    : 0,
		contiguous : false,
	};

	constructor(
		private networkingService : NetworkingService,
		private fileService       : FilesService,
		private translate         : TranslateService,
		private helper            : HelperService
	){}


	private wait(ms : number){
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	addEmoji(selected: any, input: any) {
		const emoji: string = (selected.emoji as any).native;
		input.focus();

		if (document.execCommand) {
			document.execCommand('insertText', false, emoji);
			return;
		}

		const [start, end] = [input.selectionStart, input.selectionEnd];
		input.setRangeText(emoji, start, end, 'end');
	}

	async previewPreUploadedImage(event : Event){
		let inputFile = event.target as HTMLInputElement;
		if(inputFile.files?.length){
			const FILE = inputFile.files[0];
			let response = await this.helper.swalImage(URL.createObjectURL(FILE));
			
			if(response.isConfirmed){
				this.message.message = await this.fileService.upload(FILE);
				await this.sendMessage("img");
			}
			inputFile.value = "";
		}
	}

	private async scrollToLastMessage(){
		let element : any;
		while(!element){
			element = document.querySelector('.messages');
			if(!element){
				await this.wait(200);
			}
		}
		element.scrollTop = element.scrollHeight;
	}

	async sendMessage( type : string = "msg" ){
		if(this.message.message.length && this.friend && this.currentUser && this.chat){
			// Inicializar valores del mensaje
			this.message.type    = type;
			this.message.user_id = this.currentUser.uid;
			// Marcar al amigo como chat pendiente
			await this.networkingService.pendingChat(this.friend.uid, this.currentUser.uid);
			
			let response = await this.networkingService.sendMessage(this.chat.id,this.message);

			if(response){
				this.message.message = '';
			}else{
				let title = this.translate.instant('chat.swal.errorSendChat');
				this.helper.swalWarnign(title,'');
			}
		}
	}
}
