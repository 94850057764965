import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ErrorService } from './error.service';
import firebase from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class HeartbeatService {
    
    BASE_PATH: string = '/users';

    constructor(
        private errorServ : ErrorService,
        private database : AngularFireDatabase,
    ) { }
    
    async setConecction(user_id : string, event_id : number, room : number){
        try{
            await this.database.database.ref(`${this.BASE_PATH}/${user_id}/heartbeat/${event_id}/${room}`).transaction((beat => {
                if(!beat) {
                    return {
                        /* @ts-ignore */
                        last_changed: firebase.database.ServerValue.TIMESTAMP,
                        counter     : 5
                    };
                }
                beat.counter = (beat.counter ? beat.counter += 5 : 5);
                beat.last_changed = firebase.database.ServerValue.TIMESTAMP;
                return beat;
            }));
        }catch(error){
            this.errorServ.handleErrors(`Error al actualizar heartbeat. Más detalles: `,error);
        }
    }

    async setInitialConecction(user_id : string, event_id : number, room : number){
        try{
            await this.database.database.ref(`${this.BASE_PATH}/${user_id}/heartbeat/${event_id}/${room}`).transaction((beat => {
                if(!beat) {
                    return {
                        /* @ts-ignore */
                        last_changed: firebase.database.ServerValue.TIMESTAMP,
                        counter     : 0
                    };
                }
                beat.counter = (beat.counter ? beat.counter += 0 : 0);
                beat.last_changed = firebase.database.ServerValue.TIMESTAMP;
                return beat;
            }));
        }catch(error){
            this.errorServ.handleErrors(`Error al actualizar heartbeat. Más detalles: `,error);
        }
    }
}

export interface UserCoordsInterface {
    status  : boolean;
    coords? : GeolocationCoordinates;
}
