<div id="frame" *ngIf="currentUser">
    <div id="sidepanel">
        <div id="profile">
            <div class="wrap" id="profile-section" (click)="navigateToProfile()">
                <img id="profile-img" [src]="currentUser.image ? currentUser.image : defaultImg" />
                <p>{{currentUser.name}} {{currentUser.lastName}}</p>
            </div>
        </div>
        <div id="search">
            <label for="">
                <mat-icon>search</mat-icon>
            </label>
            <input type="text" placeholder="{{'generalSearch.input' | translate}}" (keyup)="applyFilter($event)">
        </div>
        <div id="contacts">
            <div *ngIf="filteredData.length == 0; else elseBlock">
                <ng-container [ngTemplateOutlet]="listFriends" [ngTemplateOutletContext]="{users : friends}"></ng-container>
            </div>
            <ng-template #elseBlock>
                <ng-container [ngTemplateOutlet]="listFriends" [ngTemplateOutletContext]="{users : filteredData}"></ng-container>
            </ng-template>
        </div>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <div class="d-none d-md-block banner" *ngIf="slider_type == 'carousel'">
        <ngx-slick-carousel class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of sponsors" class="slide mx-2" [ngStyle]="{
                'background-image': slide && slide.category ? slide.category.color : '#fff',
                'border-radius': '.5rem;'
            }">
                <a [href]="slide.sponsor.url" target="_blank" rel="noopener noreferrer">
                    <img class="imgCarousel" src="{{ slide.sponsor.logo }}" width="100%">
                </a>
            </div>
        </ngx-slick-carousel>
    </div>

    <div class="static-sponsors banner" *ngIf="slider_type == 'static'">
      <div  *ngFor="let slide of sponsors" class="slide mx-2" >
        <a [href]="slide.sponsor.url" target="_blank" rel="noopener noreferrer">
            <img class="imgCarousel" src="{{ slide.sponsor.logo }}" width="100%">
        </a>
      </div>
    </div>
</div>

<ng-template #listFriends let-users='users'>
    <ul>
        <li class="contact" [id]="'list_' + friend.uid" *ngFor="let friend of users" (click)="openChat([friend.uid])">
            <div class="wrap">
                <span class="busy" *ngIf="pendingChats.includes(friend.uid)">
                    <mat-icon>notifications</mat-icon>
                </span>
                <img [src]="friend.image ? friend.image : defaultImg" alt="" />
                <div class="meta">
                    <p class="name">{{friend.name}} {{friend.lastName}}</p>
                    <p class="preview">{{friend.email}}</p>
                </div>
            </div>
        </li>
    </ul>
</ng-template>
