import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { WelcomeService } from 'src/app/core/services/welcome.service';
import * as uuid from 'uuid';
import { selectEventState } from 'src/app/core/ngrx-store/event-store/event.selectors';
import { skipWhile, take } from 'rxjs/operators';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { MagixEvent } from 'src/app/core/models/event';
import { GrapeJSService } from 'src/app/core/services/grapejs.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  event         : MagixEvent | null = null;
  subscriptions : Subscription[] = [];
  uidTemplate   : string = `component_${uuid.v4()}_component`;
  templateFlag  : boolean | null = null;

  constructor(
    private store        : Store<StoreRootState>,
    private welcomeSrv   : WelcomeService,
    private grapeService : GrapeJSService,
  ) { }

  async ngOnInit(): Promise<void> {
    let response = await this.store.pipe(select(selectEventState),skipWhile( state => !(state.eventLoadStatus == 'loaded') ), take(1) ).toPromise();
    this.event   = response.event;
    
    if(this.event){
      let responseHTML = await this.grapeService.getHTML(this.event.id, 'welcome');
      
      if (responseHTML != null && responseHTML.status) {
        this.templateFlag = false;
        setTimeout(() => {
          let element: any = document.querySelector(`#${this.uidTemplate}`);
          element.innerHTML = responseHTML.html;
        }, 300);
      } else {
        this.templateFlag = true;
        this.getEditorHTML(this.event.fid);
      }
      
    }
  }

  getEditorHTML(event_fid : string){
    console.log({event_fid});
    
    this.subscriptions.push(
      this.welcomeSrv.getEditor(event_fid).subscribe( response => {
        this.grapeService.printHTML(this.uidTemplate, response.payload.data())
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
