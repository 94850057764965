import { Injectable } from '@angular/core';
import { GlobalErrors } from '../models/errors.model';
import Swal from 'sweetalert2';
//@ts-ignore
import geolocator from 'geolocator';
import { environment } from 'src/environments/environment';
import { MagixEvent } from '../models/event';
import { FormPaymentInterfaz } from '../models/events/form';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
const diacriticLess = require('diacriticless');

export const vnew_lastForm = "VNEW_LAST_FORM";
export const youtuberegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
export const vimeoregExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:event\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
export const vimeoVideoRegExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private translate: TranslateService
  ) {
  }

  /**
   *
   * @param variable el nombre de la variable a cambiar del css sin el prefijo "--", puede ser string, string[] o un objeto del tipo { variableName: variableValue }.
   * @param value el valor de la variable a cambiar, puede ser un string o un string[].
   * @param prefix si se tiene un prefijo para los valores ej: <login>backgroundColor.
   * @returns retorna un booleano true si completo se pudo realizar el cambio.
   */
  setCSSVariables(variable: string | string[] | { [key: string]: any }, value?: string | string[], prefix?: string): boolean {
    let result = false;

    if (!variable) {
      return result
    }

    if (typeof variable == "string") {
      if (!value || typeof value != "string") {
        console.warn("Error en setCSSVariables; <value> debe de ser un string.");
      }
      else {
        document.documentElement.style.setProperty(`--${prefix ? prefix : ""}${variable.trim()}`, String(value).trim());
        result = true;
      }
    } else if (typeof variable == "object") {
      if (Array.isArray(variable)) {
        if (!value || !Array.isArray(value) || variable.length != value.length) {
          console.warn("Error en setCSSVariables; <value> debe de ser un array con el mismo numero de valores que variable.");
        } else {
          variable.forEach((variableName, index) => {
            document.documentElement.style.setProperty(`--${prefix ? prefix : ""}${variableName.trim()}`, String(value[index]).trim());
          });
          result = true;
        }
      }
      else if (variable === Object(variable)) {
        for (let key in variable) {
          let subValue = variable[key];
          if (!subValue || (typeof subValue != "string" && typeof subValue != "number")) {
            console.warn("Error en setCSSVariables; <variableValue> de {variableName: variableValue} debe de ser un string.");
          }
          else {
            document.documentElement.style.setProperty(`--${prefix ? prefix : ""}${key.trim()}`, String(subValue).trim());
            result = true;
          }
        }
      }
    }
    return result;
  }

  /* selectEventColors(event: MagixEvent) {
    return event.colors.live;
  } */

  /* selectLeftEventColors(event: MagixEvent) {
    return this.selectEventColors(event).left;
  } */

  /* selectCenterEventColors(event: MagixEvent) {
    return this.selectEventColors(event).center;
  } */

  /* selectRightEventColors(event: MagixEvent) {
     return this.selectEventColors(event).right;
  } */

  onlyStrings(data: { [key: string]: any }): { [key: string]: string } {
    let strings: { [key: string]: string } = {};
    for (let key in data) {
      if (!!data[key] && !Array.isArray(data[key]) && typeof data[key] == "string")
        strings[key] = data[key];
    }
    return strings;
  }

  swalError(type: string, language: string = "es-MX") {
    let error = { title: "Error desconocido", description: "Ha ocurrido un error desconocido contacta al administrador." }
    if (GlobalErrors[language] && GlobalErrors[language][type])
      error = GlobalErrors[language][type];

    Swal.fire({
      icon: 'error',
      title: error.title,
      text: error.description,
      showConfirmButton: true,
      allowOutsideClick: true,
    });
  }

  swalDisconnection(language: string = "es-MX") {
    let errorCode = "no-internet-connection";
    let error = { title: "Error desconocido", description: "Ha ocurrido un error desconocido contacta al administrador." }
    if (GlobalErrors[language] && GlobalErrors[language][errorCode])
      error = GlobalErrors[language][errorCode];

    Swal.fire({
      icon: 'error',
      title: error.title,
      text: error.description,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
  }

  swalClose() {
    Swal.close();
    console.log("swal close");
  }

  async getTranslation(route: string): Promise<string>{
    return this.translate.get(route).pipe<string>(take(1)).toPromise();
  }

  async swalAdvice(title: string, confirmButtonText?: string | undefined, denyButtonText?: string | undefined): Promise<boolean> {
    if (!confirmButtonText) confirmButtonText = await this.getTranslation('form.advice.yes');
    if (!denyButtonText) denyButtonText = await this.getTranslation('form.advice.no');

    let result = await Swal.fire({
      title: title,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText,
      denyButtonText,
      allowOutsideClick: false,
      //@ts-ignore
      showCancelButton: false
    });

    return result.isConfirmed;
  }

  swalSuccess(title: string, description: string, timer = 2500) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: description,
      timer,
      timerProgressBar: true
    });
  }

  swalWarnign(title: string, description: string, timer = 2500) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: description,
      timer,
      timerProgressBar: true
    });
  }

  swalImage(url: string) {
    return Swal.fire({
      title: "¿Es correcta la imagen?",
      imageUrl: url,
      imageWidth: 500,
      reverseButtons: true,
      showCancelButton: true,
      showConfirmButton: true
    });
  }

  async getUserLocalization(): Promise<string | "Privado"> {
    return await new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        const geoConfig = {
          language: "en",
          google: {
            version: "3",
            key: environment.geoTokenKey,
          }
        };
        const geoOptions = {
          addressLookup: true,
          timezone: false,
          staticMap: false
        };

        geolocator.config(geoConfig);
        geolocator.locate(geoOptions, async (err: any, location: any) => {
          if (err) return resolve("Privado");
          resolve(location.address.country);
        });

      } else {
        /* default return */
        resolve("Privado");
      }
    })
  }

  getVideoUrl(url: string): string | false {
    let cleanURL: string | false = false;

    switch (true) {
      case (url.includes("https://iframe.dacast.com")):
        cleanURL = url;
        break;
      case (url.indexOf("youtube") != -1):
        var match = url.match(youtuberegExp);
        var youtubeID = (match && match[7].length == 11) ? match[7] : false;
        if (youtubeID != false)
          cleanURL = `https://www.youtube.com/embed/${youtubeID}?autoplay=1&modestbranding=1`;
        break;
      case (url.indexOf("vimeo") != -1):
        // let match1 = url.match(vimeoregExp);
        // let match2 = url.match(vimeoVideoRegExp);
        let splittedUrl = url.split("/");
        console.log("splittedUrl", splittedUrl);

        // console.log("match1, match2", match1, match2);


        if (url.indexOf("event") > -1) {
          cleanURL = `https://vimeo.com/event/${splittedUrl[4]}/embed`
          // cleanURL = `https://vimeo.com/video/${splittedUrl[4]}`;
        } else {
          cleanURL = `https://player.vimeo.com/video/${splittedUrl[3]}`;
        }

        break;
      case (url.indexOf("twitch") > -1):
        switch (true) {
          case (url.indexOf("channel=") > -1 && url.indexOf("&") > -1):
            let temp = url.slice(url.indexOf("channel=") + 8);
            let channel = temp.slice(0, temp.indexOf("&"));
            cleanURL = `https://player.twitch.tv/?channel=${channel}&parent=vnew.cl`;
            break;
          case (url.indexOf("video=") > -1):
            let temp1 = url.slice(url.indexOf("video=") + 6);
            let video = temp1.slice(0, temp1.indexOf("&"));
            cleanURL = `https://player.twitch.tv/?video=${video}&parent=vnew.cl`;
            break;
          case (url.indexOf("collection=") > -1):
            let temp2 = url.slice(url.indexOf("collection=") + 11);
            let collection = temp2.slice(0, temp2.indexOf("&"));
            cleanURL = `https://player.twitch.tv/?collection=${collection}&parent=vnew.cl`
            break;
          default:
            console.warn("El url del video no es valido. URL: ", url);
            break;
        }
        break;
      default:
        alert("Error con el video");
        break;
    }

    console.log("cleanURL", cleanURL);

    return cleanURL;
  }

  eventTitle2permalink(title: string) {
    title = title.toLowerCase();
    title = title.trim();
    title = diacriticLess(title);
    title = title.replace(/\s+/g, ' ');
    title = title.replace(/\W/g, ' ');
    title = title.trim();
    title = title.replace(/\s+/g, '-');
    return title;
  }

  generatePermalink(event: MagixEvent): string {
    let permalink: string = "";
    if (environment.production)
      permalink = `https://vnew.cl/${event.id}/${this.eventTitle2permalink(event.title)}`;
    else
      permalink = `http://localhost:4200/${event.id}/${this.eventTitle2permalink(event.title)}`;
    return permalink;
  }

  downloadFileByURL(url: string) {
    let link = document.createElement("a");
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', "download");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  validaRut(rutCompleto: string) {
    if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto))
      return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'k';
    return (this.dv(Number(rut)) == digv);
  }
  dv(T: number) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
  }
  saveFormOnPaymentRedirection(value: FormPaymentInterfaz["answers"]) {
    this.saveLocalStorage(vnew_lastForm, JSON.stringify(value));
  }
  retreiveSavedForm(): FormPaymentInterfaz["answers"] | null {
    let formString = this.getLocalStorage(vnew_lastForm);
    return formString ? JSON.parse(formString) : null;
  }
  deleteSavedForm() {
    this.deleteLocalStorage(vnew_lastForm);
  }
  saveLocalStorage(name: string, value: string) {
    localStorage.setItem(name, value);
  }
  getLocalStorage(name: string) {
    return localStorage.getItem(name);
  }
  deleteLocalStorage(name: string) {
    return localStorage.removeItem(name);
  }
}
