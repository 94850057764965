<main id="chat-main-container">
    <section id="chat-wrapper" class="two">

        <networking-left-section    id="left" 
									[contacts]="contacts | async" 
									[currentUser]="profile$ | async" 
									[defaultImage]="DEFAULT_PROFILE_IMG" 
									(chat)="openChat($event)"
									(friendDeleted)="checkIfChatStillExists($event)"
		></networking-left-section>

        <networking-center-section  id="center"
									*ngIf="isFriendLoaded"
									[chat]="chat"
									[currentUser]="currentUser"
									(showChats)="toggleChatsSection($event)"
									(showFriend)="toggleFriendSection($event)"
		></networking-center-section>

		<div id="center-img">
			<div class="img-background-color"></div>
			<img *ngIf="!isFriendLoaded">
		</div>
		

        <networking-right-section id="right"
								  class=""
								  *ngIf="isFriendLoaded"
								  [friendProfile]="friend"
								  (hideFriend)="toggleFriendSection($event)"
		></networking-right-section>
    </section>

	<div id="chat-bg-image"></div>
</main>
