import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { skipWhile, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientCredentialsAuthProvider, ApiClient } from 'twitch';
import { ErrorService } from './error.service';
import { vimeoregExp, youtuberegExp } from './helper.service';

@Injectable({
	providedIn: 'root'
})
export class ImagesService {

	twitchAuthProvider = new ClientCredentialsAuthProvider(environment.twitch.clientID, environment.twitch.secret);
	twitchClient       = new ApiClient({ authProvider: this.twitchAuthProvider });

	constructor(
		private storageService: AngularFireStorage,
		private errorServ: ErrorService
	) { }

	async getImage(path: string | null): Promise<string> {
		try {
			if (!path) return Promise.resolve("");
			if (path.indexOf("https://") > -1) return Promise.resolve(path);
			let result = await this.storageService.ref(path).getDownloadURL().pipe(take(1)).toPromise();
			return result;
		} catch (error) {
			this.errorServ.handleErrors(`Ha ocurrido un error descargando las imágenes. Path de consulta: ${path} Detalles: `, error);
			return "";
		}
	}

	async uploadImage(path: string, file: File | null): Promise<string> {
		if (path && file) {
			let fileRef = this.storageService.ref(path);
			await fileRef.put(file);
			// await fileRef.getDownloadURL().pipe(skipWhile(url => !url), take(1)).toPromise();
			return path;
		} else
			return "";
	}

	async getVideoImage(url: string): Promise<string | false> {
		let result: string | false = false;

		try {
			switch (true) {
				case (url.indexOf("youtube") != -1):
					var match     = url.match(youtuberegExp);
					var youtubeID = (match && match[7].length == 11) ? match[7] : false;

					result = `http://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`;
				break;
				case (url.indexOf("vimeo") != -1):
					var match = url.match(vimeoregExp);

					if (match && match[3]) {
						let response         = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${match[3]}`, { mode: 'cors' })
						let responseAsString = await response.text();

						result = JSON.parse(responseAsString)['thumbnail_url'];
					}

				break;
				case (url.includes("https://iframe.dacast.com")):
					let response = await fetch(url);
					let parser   = new DOMParser();
					let doc      = parser.parseFromString(await response.text(), "text/html");
					let element  = doc.querySelector('meta[itemprop="thumbnailUrl"]');

					if(element){
						let tempvalue = element.getAttribute("content");

						if(tempvalue){
							result = tempvalue;
						}
					}
				break;
				case (url.indexOf("twitch") != -1):
					if (url.indexOf("video=") > -1) {
						let temp1     = url.slice(url.indexOf("video=") + 6);
						let videoID   = temp1.slice(0, temp1.indexOf("&"));
						let videoData = await this.twitchClient.helix.videos.getVideoById(videoID);

						if (videoData){
							result = videoData.thumbnailUrl;
						}
					}
				break;
			}
		} catch (error) {
			this.errorServ.handleErrors(`Ha ocurrido un error al obtener el thumbnail de vimeo. URL: `, error);
		}

		return result;
	}

}
