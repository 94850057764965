import { User } from "./user";

export interface NetworkingInterface{
    memebers_sort_ids : string;
    id                : string;
    created_at        : number;
    updated_at        : number;
    status            : NetworkingStatusInterface;
    messages          : NetworkingMessageInterface[]
}

export interface NetworkingStatusInterface{
    creator : {
        id     : string,
        status : boolean
    },
    friend : {
        id     : string,
        status : boolean
    }
}

export interface NetworkingMessageInterface{
    id       : string;
    message  : string;
    user_id  : string;
    sent_at  : number;
	seen_at? : number;
}

export interface NetworkingContactLastMessageInformation{
	status      : string;
	message     : string;
	sended_at   : any;
	lastMessage : number;
}

export interface NetworkingAttendeesQueryInterface{
	startAt?    : string;
	orderByKey  : boolean,
	limitToFirst: number,
}

export interface NetworkingChatInterface{
	memebers_sort_ids : string;
    id                : string;
    created_at        : number;
    updated_at        : number;
    status            : NetworkingChatStatusInterface;
}

export interface NetworkingChatStatusInterface{
    creator : {
        id     : string,
        status : boolean
    },
    friend : {
        id     : string,
        status : boolean
    }
}


export class NetworkingQueryConfigClass {
	path     : string  = "users"; // path to collection
	field    : string  = "name";  // field to orderBy
	limit    : number  = 12;      // limit per query
	reverse  : boolean = false;   // reverse order?
	prepend  : boolean = false;   // prepend to source?
}

export interface NetworkingInitChatInterface{
	friend : User;
	usersMembersID : string; 
}

export interface NetworkingChatMessageInterface{
    id         : string;
	type       : string;
    message    : string;
    user_id    : string;
    sent_at    : number;
	seen_at?   : number;
	contiguous : boolean;
}
export interface NetworkingStylingInterface{
    uuid     : string;
    name     : string;
    eventFID : string;
    images   : {
        chatEmpty  : string,
		background : string,
        chatFriend : string,
    },
    backgrounds: {
        general : {
            top    : string,
            bottom : string,
        },
        scroll : {
            track     : string;
            navigator : string;
        }
        leftSidebar : {
            profile       : string,
            friends       : string,
            friendsHover  : string,
            searcherInput : string,
            pendingMessage: string,
        },
        overLeftSidebar: {
            title          : string,
            searcher       : string,
            attendees      : string,
            searcherInput  : string,
            attendeesHover : string
        },
        chat: {
            sender      : string,
            profile     : string,
            selected    : string,
            messageIn   : string,
            messageOut  : string,
            noSelected  : string,
            senderInput : string
        },
        rightSidebar: {
            title   : string,
            general : string
        }
    },
    colors: {
        border     : string;
        leftSidebar: {
            profileIcons          : string,
            searcherIcon          : string,
            searcherText          : string,
            friendsNames          : string,
            pendingMessageIcon    : string,
            friendsLastestDate    : string,
            friendsLastestMessage : string,
        },
        overLeftSidebar: {
            titleText             : string,
            titleIcons            : string,
            searcherIcon          : string,
            searcherText          : string,
            friendsNames          : string,
        },
        chat: {
            senderText         : string,
            profileText        : string,
            senderIcons        : string,
            profileIcons       : string,
            messageInText      : string,
            messageOutText     : string,
            messageInDateIcon  : string,
            messageInDateText  : string,
            messageOutDateText : string,
            messageOutDateIcon : string
        },
        rightSidebar: {
            titleText  : string,
            titleIcons : string,
            friendText : string
        }
    }
}