import { Action } from '@ngrx/store'
import { RegisterForm } from '../../_models/RegisterForm';
import { Event as EventModel } from '../../_models/events/event.model';
import { User } from '../../models/user';

export enum FormActionsTypes {
  Default = '[Default] Action',
  LoadForm = '[LoadForm] Action',
  LoadedForm = '[LoadedForm] Action',
  FormNotFound = '[FormNotFound] Action',
  LoadFormImages = '[LoadFormImages] Action',
  LoadedFormImages = '[LoadedFormImages] Action',
  UploadUser = '[UploadUser] Action',
  UploadedUser = '[UploadedUser] Action',
  EmailSent = '[EmailSent] Action',
  ErrorUploadingUser = '[ErrorUploadingUser] Action',
}

export class Default implements Action {
  readonly type = FormActionsTypes.Default
  constructor(public payload: any) { }
}

export class LoadForm implements Action {
  readonly type = FormActionsTypes.LoadForm
  constructor(public payload: { formId: string }) {
  }
}
export class LoadedForm implements Action {
  readonly type = FormActionsTypes.LoadedForm
  constructor(public payload: { form: RegisterForm }) {

  }
}

export class UploadUser implements Action {
  readonly type = FormActionsTypes.UploadUser
  constructor(public payload: { newUser: User, event: EventModel, formValues: any, form: RegisterForm }) {
  }
}
export class UploadedUser implements Action {
  readonly type = FormActionsTypes.UploadedUser
  constructor(public payload: { formValues: any, form: RegisterForm }) {
  }
}
export class EmailSent implements Action {
  readonly type = FormActionsTypes.EmailSent
  constructor(public payload: {}) {
  }
}

export class ErrorUploadingUser implements Action {
  readonly type = FormActionsTypes.ErrorUploadingUser
}

export class LoadFormImages implements Action {
  readonly type = FormActionsTypes.LoadFormImages
  constructor(public payload: { form: RegisterForm }) {
  }
}

export class LoadedFormImages implements Action {
  readonly type = FormActionsTypes.LoadedFormImages
  constructor(public payload: { form: RegisterForm }) {
  }
}


export class FormNotFound implements Action {
  readonly type = FormActionsTypes.FormNotFound
}

export type FormActions =
  Default |
  LoadForm |
  FormNotFound |
  LoadFormImages |
  LoadedFormImages |
  UploadUser |
  UploadedUser |
  EmailSent |
  ErrorUploadingUser |
  LoadedForm;
