import { User } from 'src/app/core/models/user';
import { MESSAGE_STATUS } from 'src/app/core/data/networking.data';
import { NetworkingService } from 'src/app/core/services/networking/networking.service';
import { NetworkingChatInterface } from 'src/app/core/models/networking.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';


let diacriticLess = require('diacriticless');
@Component({
	selector    : 'networking-conctacts-left-section',
	templateUrl : './conctacts.component.html',
	styleUrls   : [
		'./conctacts.component.scss',
	],
})

export class NetworkingContactsLeftSectionComponent {
	@Output() chat          : EventEmitter<NetworkingChatInterface> = new EventEmitter();
	@Output() scroll        : EventEmitter<string>                  = new EventEmitter();
	@Output() noMatches     : EventEmitter<void>                    = new EventEmitter();
	@Output() friendDeleted : EventEmitter<string>                  = new EventEmitter();
     
	@Input() currentUser         : User | null = null;
	@Input() filteredData        : User[]      = [];
	@Input() showNotifications   : boolean = true;
	@Input() set contacts( value : User[] | null){
		if(value){
			this.friends = value;
		}
	}
	@Input() set searchContacts( value : string ){
		if(value != null){
			if(!value.length){
				this.filteredData = [];
			}else{
				this.filteredData = this.searchByField(value);

				if(!this.filteredData.length){
					this.noMatches.emit();
				}
			}
		}
	}

	friends       : User[] = [];
	notShownUsers : User[] = [];

	messageStatus = MESSAGE_STATUS

	constructor(
		private networkingService : NetworkingService
	) {}

	searchByField( filter : string ){
		return this.friends.filter((element) => {
			return  (this.sanitizeText(`${element.name} ${element.lastName}`).indexOf(this.sanitizeText(filter)) > -1) ||
					(this.sanitizeText(element.email).indexOf(this.sanitizeText(filter)) > -1);
		})
	}

	async removeFriend( friend : User ){
		if(this.currentUser){
			
			let currentUser = Object.assign({},this.currentUser);

			if(friend.friends){
				friend.friends = friend.friends.filter( ( friendID ) => friendID != currentUser.uid );
				friend.friends = [...new Set(friend.friends)];
			}else{
				friend.friends = [];
			}

			if(currentUser.friends){
				currentUser.friends = currentUser.friends.filter( (friendID) => friendID != friend.uid );
				currentUser.friends = [...new Set(currentUser.friends)];		
			}else{
				currentUser.friends = [];
			}

			let friendResponse      = await this.networkingService.updateFriends( friend.friends, friend.uid );
			let currentUserResponse = await this.networkingService.updateFriends( currentUser.friends, currentUser.uid );

			if(friendResponse && currentUserResponse){
				this.friendDeleted.emit(friend.uid);
			}
		}
	}

	async openChat(friend : User){
		if(this.currentUser){
			let members      = [friend.uid, this.currentUser.uid].sort().join('___');
			let chatResponse = await this.networkingService.getByMembersID(members);

			if( chatResponse ){
				this.chat.emit(chatResponse);
			}else{
				this.addAsFriendIfIsNotAdded(this.currentUser, friend);
				this.addAsFriendIfIsNotAdded(friend, this.currentUser);

				await this.networkingService.newFriend(this.currentUser.uid, friend.uid);
				this.openChat(friend);
			}
		}
	}

	async addAsFriendIfIsNotAdded( user : User, friend : User ){
		user = JSON.parse(JSON.stringify(user));
		let status = false;
		if( user.friends ){
			if( !user.friends.includes(friend.uid) ){
				status  = true;
				user.friends.push(friend.uid)
				await this.networkingService.addAsFriendIfIsNotAdded( user.uid, user.friends );
			}
		}else{
			status = true;
			await this.networkingService.addAsFriendIfIsNotAdded( user.uid, [friend.uid]);
		}
	}

	private sanitizeText(text: string): string {
		return diacriticLess(text.toLowerCase().trim());
	}
}
