import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase';
import { Room } from '../models/conferences/room';
import { MagixEvent } from '../models/event';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  constructor(
    private firestore: AngularFirestore,
    private database: AngularFireDatabase,
    private functions: AngularFireFunctions
  ) { }

  /* async startPlayerCounter(user: User, event: MagixEvent, room: Room): Promise<boolean> {
    try {
      let { uid } = user;
      let eventID = event.id;
      let roomID = room.id;

      const route = `users/${uid}/counters/${eventID}/${roomID}/player`;
      await this.database.database.ref(route).update({ start: firebase.database.ServerValue.TIMESTAMP });
      console.log("Conteo de Video Inicializado");
      return true;
    } catch (error) {
      console.error("Ha ocurrido un error al contabilizar el tiempo en el player", error);
      return false;
    }
  }
 */
  /* async stopPlayerCounter(user: User, event: EventInterface, room: Room): Promise<boolean> {
    try {
      let { uid } = user;
      let eventID = event.id;
      let roomID = room.id;
      const route = `users/${uid}/counters/${eventID}/${roomID}/player`;
      await this.database.database.ref(route).update({ end: firebase.database.ServerValue.TIMESTAMP });
      console.log("Conteo de Video Detenido");
      return true;
    } catch (error) {
      console.error("Ha ocurrido un error al contabilizar el tiempo en el player", error);
      return false;
    }
  } */

}
