import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, share, shareReplay } from 'rxjs/operators';
import { EmailRegistration } from '../models/emailRegister';
import { MagixEvent, MagixEventSummary } from '../models/event';
import { EventError, EventLoaded } from '../ngrx-store/event-store/event.actions';
import { StoreRootState } from '../ngrx-store/index.reducer';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  eventSubscription: Subscription | null = null;

  constructor(
    private database: AngularFireDatabase,
    private store: Store<StoreRootState>,
    private functions: AngularFireFunctions,
    private firestore: AngularFirestore,
    private errorServ: ErrorService
  ) { }

  startEventListener(id: number | string): void {
    this.StopEventListener();
    this.eventSubscription = this.database.list<MagixEvent>(`events`, ref => ref.orderByChild("id").equalTo(Number(id))).valueChanges().subscribe((event) => {
      if (event[0])
        this.store.dispatch(new EventLoaded({ event: event[0], loadImages: true }));
      else
        this.store.dispatch(new EventError());
    });
  }

  StopEventListener(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
  }

  async getEvents(): Promise<MagixEventSummary[]> {
    let result: { [key: string]: MagixEventSummary } = {};
    let arrayResult: MagixEventSummary[] = [];

    try {
      let events = await this.database.database.ref(`eventsSummary`).limitToLast(100).once("value");
      if (events.exists()) {
        result = events.val();
        for (let key in result)
          arrayResult.push(result[key]);
      }

    } catch (error) {
      this.errorServ.handleErrors(`Ha ocurrido un error al consultar los eventos del sistema. Detalles: `, error);
    }
    return arrayResult;
  }

  async getEvent(eventID: string | number): Promise<MagixEvent | null> {
    let result: MagixEvent | null = null;
    let objet: any = null;
    try {
      let events = await this.database.database.ref(`events`).orderByChild("id").equalTo(Number(eventID)).once("value");
      if (events.exists())
        objet = events.val();
      for (let key in objet)
        result = objet[key]

    } catch (error) {
      this.errorServ.handleErrors(`Ha ocurrido un error al consultar el resumen de un evento. Detalles: `, error);
    }
    return result;
  }

  async getEventsSummary(): Promise<{ [key: string]: MagixEventSummary }> {
    let result: { [key: string]: MagixEventSummary } = {};
    try {
      let events = await this.database.database.ref(`eventsSummary`).once("value");
      if (events.exists())
        result = events.val();
    } catch (error) {
      this.errorServ.handleErrors(`Ha ocurrido un error al consultar el resumen de los eventos. Detalles: `, error);
    }
    return result;
  }

  async getEventSummary(fid: string) {
    let result: MagixEventSummary | null = null;
    try {
      let events = await this.database.database.ref(`eventsSummary/${fid}`).once("value");
      if (events.exists())
        result = events.val();
    } catch (error) {
      this.errorServ.handleErrors(`Ha ocurrido un error al consultar el resumen de un evento. Detalles: `, error);
    }
    return result;
  }

  getEventObservable(eventID: number): Observable<MagixEvent | null> {
    return this.database.list<MagixEvent>("events",
      ref => ref.orderByChild("id").equalTo(eventID)
    ).valueChanges().pipe(
      map(value => value.length > 0 ? value[0] : null),
      shareReplay(1)
    );
  }

  getEventsSummaryObservable(fid: string): Observable<MagixEventSummary[] | null> {
    return this.database.list<MagixEventSummary>(`eventsSummary`).valueChanges().pipe(shareReplay(1));
  }

  getEventSummaryObservable(fid: string): Observable<MagixEventSummary | null> {
    return this.database.object<MagixEventSummary>(`eventsSummary/${fid}`).valueChanges().pipe(shareReplay(1));
  }

  async updateEvent(fid: string, event: MagixEvent | { [key: string]: any }): Promise<void> {
    return this.database.database.ref(`events/${fid}`).update(event);
  }

  async deleteEvent(fid: string): Promise<void> {
    return this.database.database.ref(`events/${fid}`).remove();
  }

  async newEvent(event: MagixEvent): Promise<boolean> {
    let result = false;

    try {
      let newReference = this.database.database.ref(`events`).push();
      let newEvent: MagixEvent | null = null;

      if (newReference.key) {
        newEvent = {
          ...event,
          fid: newReference.key,
          //@ts-ignore
          timestamp: firebase.default.database.ServerValue.TIMESTAMP,
        }

        await newReference.set(newEvent);
        result = true;
      }

    } catch (error) {
      this.errorServ.handleErrors(`Ocurrio un error al generar un nuevo evento. Detalles: `, error);
    }

    return result;
  }

  async sendNotes(notes: EmailRegistration): Promise<boolean> {
    try {
      let mailSend = this.functions.httpsCallable("sendRegistrationEmail");
      let message = await mailSend(notes).toPromise();
      return true;
    } catch (error) {
      this.errorServ.handleErrors(`Ocurrio un error. Detalles: `, error);
      return false;
    }
  }

}

/* import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Chat, ChatC } from '../models/events/chat.model';
import { Event } from "../models/events/event.model";
import { Question } from '../models/events/question.model';
import { User } from '../models/user.model';
import firebase from 'firebase';
import { User_quiz } from '../models/User_quiz';
import { Moderator_quiz } from '../models/Moderator_quiz';
import { AngularFireFunctions } from '@angular/fire/functions';
import { EmailNotes } from '../models/events/functions.models';
import { OnDemand } from '../models/events/eventModerator.model';
import { Store } from '@ngrx/store';
import { OnDemandLoaded, OnDemandLoadedEmpty } from '../ngrx-store/on-demand-store/on-demand.actions';
@Injectable({
  providedIn: 'root'
})
export class EventService {

  eventRef = this.firestore.collection<Event>('eventos').ref;
  event!: Event;
  eventPath = "eventos";
  onDemandSubscription: Subscription | null = null

  subscription: Subscription | null = null;

  constructor(
    private firestore: AngularFirestore,
    private database: AngularFireDatabase,
    private functions: AngularFireFunctions,
    private store: Store
  ) { }


  initializeEventListener(eventID: number) {
    this.database.database.ref(`events`)
  }

  //Bloc de notas
  sendNotes(data: EmailNotes): Promise<{ response: "OK" | "Error" }> {
    let mailSend = this.functions.httpsCallable("sendNotesByEmail");
    return mailSend(data).toPromise();
  }

  //Anuncios por sala
  getAnnounces(fidEvent: string, fidRoom: string | number): Observable<Chat[]> {
    return this.database.list<Chat>(`events/${fidEvent}/${fidRoom}/announces`, (reference) => reference.orderByChild("timestamp"))
      .valueChanges().pipe(
        map(announces => announces.reverse()),
        shareReplay(1)
      );
  }

  getLastAnnounce(fidEvent: string, fidRoom: string | number): Observable<Chat> {
    return this.database.list<Chat>(`events/${fidEvent}/${fidRoom}/announces`, (reference) => reference.orderByChild("timestamp").limitToLast(1))
      .valueChanges().pipe(
        map(announces => announces && announces[0] ? announces[0] : new ChatC()),
        shareReplay(1)
      );
  }



  //Eventos
  loadEventByID(eventID: string) {
  }

  //Cargar el evento desde el state
  loadEvent(event: Event) {
    this.event = event;
  }

  loadEventByIDObservable(eventID: string | number): Observable<Event> {
    return this.firestore.collection<Event>(this.eventPath, (reference) => reference.where("id", "==", Number(eventID))).snapshotChanges()
      .pipe(
        map(changes => {
          let foundEvents = changes.map(a => {
            let data = a.payload.doc.data() as Event;
            data.fid = a.payload.doc.id;
            return data;
          });
          return foundEvents[0];
        }),
        filter((event) => !!event),
        shareReplay(1)
      );
  }

  //Obtener el evento desde el servidor
  async retriveEventFromServer(eventID: string | number): Promise<null | any> {
    try {
      const eventsSnapshot = await this.eventRef.where("id", "==", Number(eventID)).where("clone", "==", false).get();
      if (eventsSnapshot.empty) return null;
      return eventsSnapshot.docs[0].data();
    } catch (error) {
      console.error(`Ha ocurrido un error al obtener el evento ${eventID}`, error);
      return null;
    }
  }

  getChat(fidEvent: string, fidRoom: string): Observable<Chat[]> {
    return this.database.list<Chat>(
      `events/${fidEvent}/${fidRoom}/chat`,
      (reference) => reference.orderByChild("timestamp").limitToLast(40)
    ).valueChanges().pipe(
      map(chats => chats.reverse()),
      shareReplay(1),
    );
  }
  async sendChatMessage(message: string, user: User, fidEvent: string, fidRoom: string): Promise<boolean> {
    let chatMessage: Chat = {
      message,
      user: user.name,
      usermail: user.email,
      userlastname: user.lastName,
      //@ts-ignore
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    }

    try {
      await this.database.database.ref(`events/${fidEvent}/${fidRoom}/chat`).push(chatMessage);
      return true;
    } catch (error) {
      console.warn("Ha ocurrido un error al enviar el mensaje", error);
      return false;
    }
  }
  getQuizQuestions(fidEvent: string, fidRoom: string): Observable<Question[]> {
    return this.database.list<Question>(`events/${fidEvent}/${Number(fidRoom)}/questions`, (reference) => reference.orderByChild("active").equalTo(true)).valueChanges().pipe(shareReplay(1));
  }
  answerQuizQuestion(answer: string, quizFid: string, roomFid: number, eventFid: string, user: User) {
    let fullAnswer = {
      quizFid: quizFid,
      answer: answer,
      username: user.name,
      userUid: user.uid,
      timestamp: new Date().getTime(),
    }

    return this.database.database.ref(`events/${eventFid}/${roomFid}/answers/${quizFid}/${user.uid}`).set(fullAnswer)
  }
  getUserQuestions(fidEvent: string, fidRoom: string | number) {
    return this.database.list<Moderator_quiz>(`events/${fidEvent}/${fidRoom}/userQuiz`, (reference) => reference.orderByChild("timestamp")).valueChanges().pipe(shareReplay(1));
  }
  async ask(quiz: string, username: string, fidEvent: string, fidRoom: string | number): Promise<boolean> {
    try {
      let questionsReference = this.database.database.ref(`events/${fidEvent}/${fidRoom}/userQuiz`)
      let questionReference = questionsReference.push();

      if (questionReference.key) {
        let question: User_quiz = {
          username: username,
          question: quiz,
          active: false,
          //@ts-ignore
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          fid: questionReference.key
        }

        await questionReference.set(question);
        return true;
      } else
        return false;
    } catch (error) {
      console.warn("Ha ocurrido un error al realizar la pregunta =", error);
      return false;
    }
  }
  getOnDemandByRoomObservable(fidEvent: string, fidRoom: number): Observable<OnDemand[]> {
    return this.database.list<OnDemand>(`events/${fidEvent}/${fidRoom}/ondemand`, (reference) => reference.orderByChild("order")).valueChanges().pipe(shareReplay(1));
  }

  initializeOnDemandListener(fidEvent: string, fidRoom: number): void {
    this.onDemandSubscription = this.getOnDemandByRoomObservable(fidEvent, fidRoom).subscribe(onDemandRoom => {
      if (onDemandRoom)
        this.store.dispatch(new OnDemandLoaded({ onDemand: onDemandRoom }));
      else {
        this.store.dispatch(new OnDemandLoadedEmpty());
      }
    });
  }

  stopOnDemandListener(): void {
    if (this.onDemandSubscription) {
      this.onDemandSubscription.unsubscribe();
      this.onDemandSubscription = null;
    }
  }

} */
