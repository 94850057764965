<div *ngIf="templateFlag != null && templateFlag" [id]="uidTemplate" style="position: relative">
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>

    <body>

    </body>

    </html>
</div>

<div *ngIf="templateFlag != null && templateFlag == false" [id]="uidTemplate" style="position: relative">
</div>