import { User } from '../../models/user';
import { Injectable } from '@angular/core';
import { FilesService } from '../files.service';
import { scan, tap, take } from 'rxjs/operators';
import { DEFAULT_PROFILE_IMG } from '../../data/networking.data';
import { NetworkingQueryConfigClass } from '../../models/networking.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
@Injectable({providedIn: 'root'})

export class AttendeesPaginationService {
	// Source data
	private query    = new NetworkingQueryConfigClass();
	private _done    = new BehaviorSubject(false);
	private _data    = new BehaviorSubject([]);
	private _loading = new BehaviorSubject(false);
	// Observable data
	data!  : Observable<any>;
	done   : Observable<boolean> = this._done.asObservable();
	loading: Observable<boolean> = this._loading.asObservable();

	constructor(
		private firestore   : AngularFirestore,
		private fileService : FilesService
	) {}

	// Initial query sets options and defines the Observable
	init(eventID : number) {
		const first = this.firestore.collection<User>(this.query.path, (ref) => {
			return ref
					.where(`events`, "array-contains", eventID)
					.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
					.limit(this.query.limit);
		});

		this.mapAndUpdate(first);

		// Create the observable array for consumption in components
		this.data = this._data.asObservable().pipe(
			scan((acc, val) => {
				return this.query.prepend ? val.concat(acc) : acc.concat(val);
			})
		);
	}

	more( eventID : number ) {
		const cursor = this.getCursor();
		const more   = this.firestore.collection<User>(
			this.query.path, (ref) => {
				return ref
					.where(`events`, "array-contains", eventID)
					.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
					.limit(this.query.limit)
					.startAfter(cursor);
			}
		);
		this.mapAndUpdate(more);
	}

	private getCursor() {
		const current : any = this._data.value;
		if (current.length) {
			return this.query.prepend ? current[0].doc : current[current.length - 1].doc;
		}
		return null;
	}

	private mapAndUpdate(col: AngularFirestoreCollection<any>) {
		if (this._done.value || this._loading.value) {
			//@ts-ignore
			return;
		}
		// loading
		this._loading.next(true);
		// Map snapshot with doc ref (needed for cursor)
		return col.snapshotChanges()
			.pipe(
				tap(async (arr : any) => {
					let values = arr.map((snap : any) => {
						const data = snap.payload.doc.data();
						const doc  = snap.payload.doc;
						return { ...data, doc };
					});

					values = this.query.prepend ? values.reverse() : values;
					values = await Promise.all(values.map(async(user : User) =>{
						if(user.image){
							user.image = await this.fileService.getDownloadLink(user.image);
						}else{
							user.image = DEFAULT_PROFILE_IMG;
						}
						return user;
					}))
					// update source with new values, done loading
					this._data.next(values);
					this._loading.next(false);

					if (!values.length) {
						this._done.next(true);
					}
				})
			)
			.pipe(take(1))
			.subscribe();
	}

	reset() {
		this._data.next([]);
		this._done.next(false);
	}
}
