<section id="support-button">
    <div *ngIf="support && activatedButton" class="float">
        <div *ngIf="!hideButtons" class="buttons-container">
            <a mat-mini-fab target="_blank" [href]="whatsapp.url" class="whatsapp" *ngIf="whatsapp.status">
                <i class="fab fa-whatsapp"></i>
            </a>

            <a mat-mini-fab color="primary" [href]="telephone.url" target="_blank" class="ml-2" *ngIf="telephone.status">
                <mat-icon>phone</mat-icon>
            </a>

            <a mat-mini-fab color="warn" [href]="email.url" target="_blank" class="ml-2" *ngIf="email.status">
                <mat-icon>email</mat-icon>
            </a>
        </div>

        <button mat-fab color="primary" class="ml-2" (click)="hideButtons = !hideButtons">
            <mat-icon *ngIf="hideButtons">support_agent</mat-icon>
            <mat-icon *ngIf="!hideButtons">close</mat-icon>
        </button>
    </div>
</section>