import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { withLatestFrom, map } from 'rxjs/operators';
import { HelperService } from '../../services/helper.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
// import { getCurrentRouteState } from '../../ngrx-store/router-store/router.selectors';
import { StoreRootState } from '../../ngrx-store/index.reducer';
import { selectCurrentRoute, selectQueryParams, selectRouteParams } from '../../ngrx-store/router-store/router.selectors';
import { RouterNavigatedAction, RouterNavigatedPayload, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { LoadEvent, NotFoundEvent, SetEventData } from '../event-store/event.actions';
import { selectEventState } from '../event-store/event.selectors';
import { EventsService } from '../../services/event.service';
import { NoAction } from '../app-store/app.actions';

@Injectable()
export class RouterEffects {

    //Effect para cargar los datos del usuario apenas ingrese a la plataforma
    /* eventRoute$ = createEffect(() =>
        this.actions$.pipe(
            ofType<>(ROUTER_INITIALIZER),
            withLatestFrom(this.store.pipe(select(selectQueryParams))),
            map(([route, params]) => {
                const { id, name } = params;
                console.log("Set Event Data", params);
                return new SetEventData({ eventID: id });
            })
        )
    ); */


    loadEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
            withLatestFrom(this.store.pipe(select(selectRouteParams))),
            withLatestFrom(this.store.pipe(select(selectEventState))),
            map(([[route, params], eventState]) => {
                const { id, name } = params;
                if (eventState.eventLoadStatus == "waiting-to-load") {
                    if (id && name)
                        return new LoadEvent({ eventID: id, name });
                    else
                        return new NotFoundEvent();
                } else {
                    return new NoAction();
                }

            })
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<StoreRootState>,
        private userService: UserService,
        private eventsService: EventsService,
        private helper: HelperService,
        private router: Router,
    ) {
    }

}