// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, filter, pairwise, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from '../selectors/auth.selectors';
import { selectRouteParams } from '../../ngrx-store/router-store/router.selectors';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private store: Store<any>,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(select(isLoggedIn));
    }
}
