// Modules
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { MatCardModule } from '@angular/material/card';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatExpansionModule } from '@angular/material/expansion';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CommonComponentsModule } from './views/common/common-components.module';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Componentes
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { BaseComponent } from './views/theme/base/base.component';
import { NavbarComponent } from './views/theme/navbar/navbar.component';
import { FooterComponent } from './views/theme/footer/footer.component';
import { NotFoundComponent } from './views/common/not-found/not-found.component';
import { AttendeesComponent } from './views/modules/attendees/attendees.component';
import { CertificatesComponent } from './views/modules/certificates/certificates.component';
import { AttendeesListComponent } from './views/common/attendees/list/attendees.list.component';
import { AttendeesShowComponent } from './views/common/attendees/show/attendees.show.component';

// Guards
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppEffects } from './core/ngrx-store/app-store/app.effects';
import { AppReducers } from './core/ngrx-store/index.reducer';
import { FormEffects } from './core/ngrx-store/form-store/form.effects';
import { AuthEffects } from './core/auth/effects/auth.effects';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EventEffects } from './core/ngrx-store/event-store/event.effects';
import { RouterEffects } from './core/ngrx-store/router-store/router.effects';
import { ChatComponent } from './views/theme/chat/chat.component';
import { ScapeUserPipe } from './core/pipes/scape-user.pipe';
import { MatInputModule } from '@angular/material/input';
import { OnDemandEffects } from './core/ngrx-store/on-demand-store/on-demand.effects';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { WelcomeComponent } from './views/modules/welcome/welcome.component';
import { PrivateChatComponent } from './views/theme/chat/private-chat/private-chat.component';

import { ScrollableDirective } from './core/directives/scrollable.directive';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomChatComponent } from './views/modules/chats/chat.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NetworkingComponent } from './views/modules/networking/networking.component';
import { CustomPrivateChatComponent } from './views/modules/chats/private/private.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NetworkingLeftSectionComponent } from './views/modules/networking/sections/left/left.component';
import { NetworkingRightSectionComponent } from './views/modules/networking/sections/right/right-side-chats.component';
import { NetworkingCenterSectionComponent } from './views/modules/networking/sections/center/center.component';
import { NetworkingChatCenterSectionComponent } from './views/modules/networking/sections/center/chat/chat.component';
import { NetworkingSearchLeftSectionComponent } from './views/modules/networking/sections/left/search/search.component';
import { NetworkingProfileLeftSectionComponent } from './views/modules/networking/sections/left/profile/profile.component';
import { NetworkingSenderCenterSectionComponent } from './views/modules/networking/sections/center/sender/sender.component';
import { NetworkingContactsLeftSectionComponent } from './views/modules/networking/sections/left/contacts/conctacts.component';
import { NetworkingProfileCenterSectionComponent } from './views/modules/networking/sections/center/profile/profile.component';
import { NetworkingAttendeesLeftSectionComponent } from './views/modules/networking/sections/left/attendees/attendees.component';
import { NetworkingAttendeesListLeftSectionComponent } from './views/modules/networking/sections/left/attendees/list/list.component';
import {
	TranslateLoader,
	TranslateModule,
	TranslateStore,
} from '@ngx-translate/core';
import { VarDirective } from './directives/ng-var';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ModalRightComponent } from './views/modules/event/right/modals/agenda/agenda.component';
import { FormTermsModalComponent } from './views/modules/auth/form/terms/terms.component';

@NgModule({
	declarations: [
		VarDirective,
		AppComponent,
		ScapeUserPipe,
		ChatComponent,
		BaseComponent,
		FooterComponent,
		NavbarComponent,
		WelcomeComponent,
		NotFoundComponent,
		AttendeesComponent,
		ModalRightComponent,
		ScrollableDirective,
		CustomChatComponent,
		NetworkingComponent,
		PrivateChatComponent,
		CertificatesComponent,
		AttendeesListComponent,
		AttendeesShowComponent,
		FormTermsModalComponent,
		CustomPrivateChatComponent,
		NetworkingLeftSectionComponent,
		NetworkingRightSectionComponent,
		NetworkingCenterSectionComponent,
		NetworkingChatCenterSectionComponent,
		NetworkingSearchLeftSectionComponent,
		NetworkingProfileLeftSectionComponent,
		NetworkingSenderCenterSectionComponent,
		NetworkingContactsLeftSectionComponent,
		NetworkingProfileCenterSectionComponent,
		NetworkingAttendeesLeftSectionComponent,
		NetworkingAttendeesListLeftSectionComponent,
	],
	imports: [
		//NGRX
		NgxDocViewerModule,
		StoreModule.forRoot(AppReducers /* , { metaReducers: metaReducers } */),
		EffectsModule.forRoot([
			EventEffects,
			AuthEffects,
			RouterEffects,
			AppEffects,
			OnDemandEffects,
			FormEffects,
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
		}),
		PickerModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		StoreRouterConnectingModule.forRoot(),
		//Material
		MatTabsModule,
		MatIconModule,
		MatInputModule,
		MatCardModule,
		MatMenuModule,
		ReactiveFormsModule,
		FormsModule,
		MatSelectModule,
		MatOptionModule,
		MatDialogModule,
		MatButtonModule,
		MatExpansionModule,
		//Firebase
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		AngularFireDatabaseModule,
		AngularFireAuthGuardModule,
		AngularFireAnalyticsModule,
		AngularFireFunctionsModule,
		MatBadgeModule,
		AngularFireModule.initializeApp(environment.firebase),
		// Extras
		CommonComponentsModule,
		FontAwesomeModule,
		ScrollingModule,
		EmojiModule,
		InfiniteScrollModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		NgbModule,
		SlickCarouselModule,
		NgxSpinnerModule,
	],
	providers: [
		AuthGuard,
		TranslateStore
	],
	exports: [
		VarDirective,
		ModalRightComponent,
		FormTermsModalComponent
	],
	bootstrap: [
		AppComponent
	],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
