import { createSelector } from '@ngrx/store';
import { AuthState } from '../reducers/auth.reducers';


export const selectAuthState = (state: { auth: AuthState }) => state.auth;

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => auth.loggedIn
);
export const isLoggedOut = createSelector(
    isLoggedIn,
    loggedIn => !loggedIn
);
export const selectUID = createSelector(
    selectAuthState,
    auth => (auth && auth.userAuthCredentials && auth.userAuthCredentials.uid) ? auth.userAuthCredentials.uid : null
);
export const isUserLoaded = createSelector(
    selectAuthState,
    auth => auth.isUserLoaded
);
export const currentUser = createSelector(
    selectAuthState,
    auth => auth.user
);
export const currentUserType = createSelector(
    currentUser,
    user => user ? user.type : "none"
);
export const selectUserCountry = createSelector(
    currentUser,
    user => user ? (user.status ? user.status.country : "Privado") : "Privado"
);
export const isConnected = createSelector(
    selectAuthState,
    userState => userState.connected
);
export const isLoadingUserImages = createSelector(
    selectAuthState,
    auth => auth.loadingUserImages
)