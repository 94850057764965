import Swal from "sweetalert2";
import { selectEvent } from 'src/app/core/ngrx-store/event-store/event.selectors';
import { currentUser } from 'src/app/core/auth/selectors/auth.selectors';
import { select, Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from "@angular/fire/functions";
import { ActivatedRoute, Params } from "@angular/router";
import { Observable, Subscription } from 'rxjs';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { take, skipWhile, withLatestFrom, map } from 'rxjs/operators';
//servicios----------------------------------------------------------------------------------
import { UserService } from "../../../core/services/user.service";
import { FormsService } from "../../../core/services/form.service";
import { EventsService } from "../../../core/services/event.service";
import { CertificatesService } from "../../../core/services/certificates.service";
//modelos--------------------------------------------------------------------------------
import { User } from 'src/app/core/models/user';
import { MagixEvent } from 'src/app/core/models/event';
import { HelperService } from 'src/app/core/services/helper.service';
import { SponsorService } from 'src/app/core/services/sponsors.service';
import { TranslateService } from '@ngx-translate/core';
import { Certificate, Item } from "../../../core/models/certificate";
import { isEventLoadedWithImages } from '../../../core/ngrx-store/event-store/event.selectors';
@Component({
	selector: 'app-certificates',
	templateUrl: './certificates.component.html',
	styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
	event$: Observable<MagixEvent | null> = this.store.pipe(
		select(isEventLoadedWithImages),
		withLatestFrom(this.store.pipe(select(selectEvent), skipWhile(data => data == null))),
		skipWhile( ([isEventLoadedWithImages, event]) => !isEventLoadedWithImages ),
		map( ([isEventLoadedWithImages, event]) => (event))
	);

	attendees = [
		{email: 'agustin.gonzalezcardozo@hospitalitaliano.org.ar', path: 'https://drive.google.com/file/d/1z6FtQrmM3y-7WoNiPXC0qDmv6nO3ythY/view?usp=sharing'},
		{email: 'alberto_penav@yahoo.com',                         path: 'https://drive.google.com/file/d/1z6FtQrmM3y-7WoNiPXC0qDmv6nO3ythY/view?usp=sharing'},
		{email: 'draahernandez@gmail.com',                         path: 'https://drive.google.com/file/d/1xrNhIppbH4vWKb1s1Fl171bpSfUI1bnj/view?usp=sharing'},
		{email: 'alsilva@odontologia.uchile.cl',                   path: 'https://drive.google.com/file/d/1wpiNCtuorVOVJrdtlBz2aR2QQbwKDLFK/view?usp=sharing'},
		{email: 'arichards@miuandes.cl',                           path: 'https://drive.google.com/file/d/1Utun0hE1MCx68JfsTWuo7QkIOKs2jeo7/view?usp=sharing'},
		{email: 'Ahcadiz@miuandes.cl',                             path: 'https://drive.google.com/file/d/1NlV3zOVmhh6FXfKBBTYmTWfiG90rVj4q/view?usp=sharing'},
		{email: 'camilo.leon.morales@gmail.com',                   path: 'https://drive.google.com/file/d/1ao452zBUyqha6PFFZyaUMVxyHuyXFnoN/view?usp=sharing'},
		{email: 'c.reuque.rivera@gmail.com',                       path: 'https://drive.google.com/file/d/1Mm0aoFXed33pYt83y2ctZSgXhc1bfbOv/view?usp=sharing'},
		{email: 'carla.rodriguezj@postgrado.uv.cl',                path: 'https://drive.google.com/file/d/1stbM0OqkGP2QHXtK1PzU6kOzUK-vGsb2/view?usp=sharing'},
		{email: 'carlarojash9@gmail.com	',                         path: 'https://drive.google.com/file/d/1py70Vd3yUKRXIDUeuTyAZOVLhIzIFt_M/view?usp=sharing'},
		{email: 'carlosizq@gmail.com',                             path: 'https://drive.google.com/file/d/1t-XAFDswTL7Gx2JHB8OhTp-DSgNv-iIE/view?usp=sharing'},
		{email: 'carmenguzu@gmail.com',                            path: 'https://drive.google.com/file/d/1wzfMA-vZOdnQejF7Ikkrq1Z2iVTRa9iW/view?usp=sharing'},
		{email: 'carol.echeverriav@gmail.com',                     path: 'https://drive.google.com/file/d/15DcNykNMtPRrHuzuZIQpfSODBjgUOEGS/view?usp=sharing'},
		{email: 'dra.carolinaespinozag@gmail.com',                 path: 'https://drive.google.com/file/d/1GXpl-a_-orSplLa9NvIHqknHs_Yj34xp/view?usp=sharing'},
		{email: 'cibascuan@miuandes.cl',                           path: 'https://drive.google.com/file/d/1kIYKKHzTWHYHDjYOYGJFuGyMqdpVsZXt/view?usp=sharing'},
		{email: 'cmiquel@miuandes.cl',                             path: 'https://drive.google.com/file/d/16Dgn6umMLqpP-mALNFIrM8Ahfz6xaVKH/view?usp=sharing'},
		{email: 'cgducommun@miuandes.cl',                          path: 'https://drive.google.com/file/d/13oTKB1YZKi8OyFHuAkU4p7pxhm3Svbe8/view?usp=sharing'},
		{email: 'ch.araya.jorquera@gmail.com',                     path: 'https://drive.google.com/file/d/18kJjjXPfQaq2bqFrCKfnbXZnfQkqZnAc/view?usp=sharing'},
		{email: 'cgamboa@miuandes.cl',                             path: 'https://drive.google.com/file/d/1JJDzroZrnOAOp7JLx02Dn9Glo4yigiLd/view?usp=sharing'},
		{email: 'comeboju@hotmail.com',                            path: 'https://drive.google.com/file/d/1WnNUfW2lT-OnyUiCWviSlKz6jMxU6wxY/view?usp=sharing'},
		{email: 'constanzapachecob@gmail.com',                     path: 'https://drive.google.com/file/d/1SrBjZdTc5wj9BYvwBUtXoKmVxyRAPlvs/view?usp=sharing'},
		{email: 'csalass@odontologia.uchile.cl',                   path: 'https://drive.google.com/file/d/1ravwb74fLO-sdKxdEIKdMLQaajKVbzGp/view?usp=sharing'},
		{email: 'cogajardo@miuandes.cl',                           path: 'https://drive.google.com/file/d/1qOqkC2Qe1V0l0QNYTMs0cBEVFmP2E1PF/view?usp=sharing'},
		{email: 'daniel.perines.a@gmail.com',                      path: 'https://drive.google.com/file/d/1kEN8pqV2UL3MWWSi4S6Ap0JDROR_7IH_/view?usp=sharing'},
		{email: 'dpvicuna@miuandes.cl',                            path: 'https://drive.google.com/file/d/1GbTSxyBrdSM1gG8Orj-fUX5TvDww2k2i/view?usp=sharing'},
		{email: 'gonzypru@gmail.com',                              path: 'https://drive.google.com/file/d/1Sn_OY8_ycyxPT8WrqnmFRR9B9YOWI1bU/view?usp=sharing'},
		{email: 'sepulvedaeditha@gmail.com',                       path: 'https://drive.google.com/file/d/1fhnG1VCWKbWJPYZxZfxsW7qZsGKj3N7w/view?usp=sharing'},
		{email: 'eneidalopezp@yahoo.com',                          path: 'https://drive.google.com/file/d/1Te2Q54QkR33q2PiALtK3uctpV88a63qf/view?usp=sharing'},
		{email: 'enzoriverat@gmail.com',                           path: 'https://drive.google.com/file/d/19bXwPj1FZwjNHGOpZuMEBjVktwL3-9b8/view?usp=sharing'},
		{email: 'ebenavides123@gmail.com',                         path: 'https://drive.google.com/file/d/1i2Q4UHij9Tw6rXjrYWLZPFcncoIueE5_/view?usp=sharing'},
		{email: 'fmaldonadoa@odontologia.uchile.cl',               path: 'https://drive.google.com/file/d/1F3zadZs_MI3uL-ZZBqAZFa6mxod4Nd5O/view?usp=sharing'},
		{email: 'fernandabarralesf@gmail.com',                     path: 'https://drive.google.com/file/d/1jFAF1qO_3bJpPv_LQDWav-E3reVDtW5x/view?usp=sharing'},
		{email: 'mfranvaldebenito@gmail.com',                      path: 'https://drive.google.com/file/d/1b7kv-0DRjU-td4wbfVi3l8oYEnfuwkxg/view?usp=sharing'},
		{email: 'francisco.perez81@gmail.com',                     path: 'https://drive.google.com/file/d/1Ixe-ngBOZXepqTcnCvRXuIeN7a_16ia9/view?usp=sharing'},
		{email: 'gabrielrodriguez@ug.uchile.cl',                   path: 'https://drive.google.com/file/d/1vipWCtTmSNKgD69XlDKObeho4lf8TshT/view?usp=sharing'},
		{email: 'gomunoz@miuandes.cl',                             path: 'https://drive.google.com/file/d/1Nnzau0h7DnMJllal0qYR10SdvtRkbgll/view?usp=sharing'},
		{email: 'Gconcha@clinicauandes.cl',                        path: 'https://drive.google.com/file/d/1IYSg3jpT3vn0UoUcsiAUELQTdPgDyQPU/view?usp=sharing'},
		{email: 'memodent@yahoo.com',                              path: 'https://drive.google.com/file/d/1TaWm4m3MGpWOda7sQuf-WZKLqGSp6_Nn/view?usp=sharing'},
		{email: 'odontologo.arrue@gmail.com',                      path: 'https://drive.google.com/file/d/1ovyplugX4NjDk1KZO59zexKuwdh-HTBL/view?usp=sharing'},
		{email: 'ignacio.urqueta.b@gmail.com',                     path: 'https://drive.google.com/file/d/1dCBebaVjUpjhBLXN6FAdTTH5HEjZVSok/view?usp=sharing'},
		{email: 'ivette.diaz@airliquide.com',                      path: 'https://drive.google.com/file/d/14Y60-BqYAQgDhoXU-FmaGWZrIpC0cetD/view?usp=sharing'},
		{email: 'salinas.javierignacio@gmail.com',                 path: 'https://drive.google.com/file/d/1nb8dOMh7bgFEPk8_e0wbr4l6M4CIfLT7/view?usp=sharing'},
		{email: 'jfaundez@coaniquem.org',                          path: 'https://drive.google.com/file/d/1mDMBAhu5NZH9CkN5YIrIEWYqFpX0vjlS/view?usp=sharing'},
		{email: 'joaquin.machado@hospitalitaliano.org.ar',         path: 'https://drive.google.com/file/d/113bJGia9vD4ZHcTn_MJa6vtN9nujSVbW/view?usp=sharing'},
		{email: 'jlyngbye@yahoo.cl',                               path: 'https://drive.google.com/file/d/1-PZXTNo5qWAbfHRAZH4Em11uDXPPZzJP/view?usp=sharing'},
		{email : "jorge.m.williams@gmail.com",                     path: "https://drive.google.com/file/d/1zG9PanVemJSF9BzBc1tWdzYi3uO4P2ZQ/view?usp=sharing"},
		{email : "jorgarayp@hotmail.com",                          path: "https://drive.google.com/file/d/1Qp5ck-d57RJXQGOmqPlMURqmb0JUfr9s/view?usp=sharing"},
		{email : "jose.ibanez@alumnos.ucn.cl",                     path: "https://drive.google.com/file/d/1zYPddVDacbK9UfHfjq-buQC_4DbZFzic/view?usp=sharing"},
		{email : "prejpizarro@gmail.com",                          path: "https://drive.google.com/file/d/1DVrUefVD2hzRa6HLOdpYjOqVuGXQs95A/view?usp=sharing"},
		{email : "joyarzo@unab.cl",                                path: "https://drive.google.com/file/d/13fEpLdvMHBl7eUCOEsSV-TvsDXgtiI1-/view?usp=sharing"},
		{email : "lplate7@gmail.com",                              path: "https://drive.google.com/file/d/1EntXmX22yTML6LSnrs11iSHKtIc6A_N7/view?usp=sharing"},
		{email : "franogarcia@gmail.com",                          path: "https://drive.google.com/file/d/1sqZq7zc2GpVjyN2ZB4hHpMs_urh3Sj4Y/view?usp=sharing"},
		{email : "macarena.cea.h@gmail.com",                       path: "https://drive.google.com/file/d/1F5owOopxpzSKtUnxi0BkbWFS_sjdSjnd/view?usp=sharing"},
		{email : "marce.g.salado@gmail.com",                       path: "https://drive.google.com/file/d/1NDnLpum1LeQM3EmpVHb-Ajo-Ke3hOU8i/view?usp=sharing"},
		{email : "mbarros@clinicauandes.cl",                       path: "https://drive.google.com/file/d/1zaoKBUrwpALhrMMZ95hh7RZhX7BCUJto/view?usp=sharing"},
		{email : "menuyensc@yahoo.es",                             path: "https://drive.google.com/file/d/1NJEn2DJLyYeegwAjflF4aBmkXGoXMF8R/view?usp=sharing"},
		{email : "ma.jesusgaete@gmail.com",                        path: "https://drive.google.com/file/d/1rZM4ECsxJUtYL1d0K_xfeAV-YgQM389I/view?usp=sharing"},
		{email : "mjelso@gmail.com",                               path: "https://drive.google.com/file/d/18Q-DBp6ZV8jamaHX7TU6tm4n3g55cIui/view?usp=sharing"},
		{email : "marlingil@gmail.com",                            path: "https://drive.google.com/file/d/1cXybr0ugY1sA-Lsui7idV_tfepfnm2fU/view?usp=sharing"},
		{email : "mmella1@miuandes.cl",                            path: "https://drive.google.com/file/d/1zr6BQHJnK9Xz3sKMCQC6LMulBWZsooL0/view?usp=sharing"},
		{email : "mildredjazz@yahoo.com",                          path: "https://drive.google.com/file/d/1MsbHW1-y0VdbhMdw348cEX2uVx3CsMIl/view?usp=sharing"},
		{email : "nvaldesruiz@gmail.com",                          path: "https://drive.google.com/file/d/1E89fWJKSXnNRylbhRdA_3TJdfrhXsaXf/view?usp=sharing"},
		{email : "ngamboacaicha@odontologia.uchile.cl",            path: "https://drive.google.com/file/d/1qMS3E-Rv8D5NiIVsme_PSAFfNWqt2ucz/view?usp=sharing"},
		{email : "nataly8125@hotmail.com",                         path: "https://drive.google.com/file/d/1jsYduFQgkpfU2maAOi0wP6Tiby5_qWN-/view?usp=sharing"},
		{email : "natgali@gmail.com",                              path: "https://drive.google.com/file/d/1sXEbRgLEFeNnnBWMSwaCVuahuO6lb8Uw/view?usp=sharing"},
		{email : "neildj10@hotmail.com",                           path: "https://drive.google.com/file/d/1UL5KL-tTb7XHziz0DcNKKx6oezcX9Fi5/view?usp=sharing"},
		{email : "poncenelly@yahoo.com.ar",                        path: "https://drive.google.com/file/d/1fB9ADTOR51F0xr5edlN5gF-P2roHCG1t/view?usp=sharing"},
		{email : "GOGABEL@YAHOO.COM",                              path: "https://drive.google.com/file/d/1d96KF90cXAipPEw0fS58Grj15KgM3R5w/view?usp=sharing"},
		{email : "dawahre98@gmail.com",                            path: "https://drive.google.com/file/d/10YVzBkUopguCQuK4XT4C4NhgFnwI0nw9/view?usp=sharing"},
		{email : "priscilla.madrid@ug.uchile.cl",                  path: "https://drive.google.com/file/d/17NbmDWmpzzyURh3QL3cqsiMaSsdPTan3/view?usp=sharing"},
		{email : "RAULBETANCURV@GMAIL.COM",                        path: "https://drive.google.com/file/d/101zLewUlxQrJYWG0moj5Mk744xf3h6vh/view?usp=sharing"},
		{email : "rrfernandez.neuro@gmail.com" ,                   path: "https://drive.google.com/file/d/1WXzEuX-As7lIbybAT03IitGpQtcsZEDY/view?usp=sharing"},
		{email : "rodrigofq27@gmail.com" ,                         path: "https://drive.google.com/file/d/1DMAZ-S2f7Ppch6AlH1HNcuNWO2nWEL7h/view?usp=sharing"},
		{email : "neuroacademyrm@gmail.com" ,                      path: "https://drive.google.com/file/d/1lqzvWOXOxGH93BfbxSyXtsr6RHKSFiza/view?usp=sharing"},
		{email : "seulufi@gmail.com" ,                             path: "https://drive.google.com/file/d/1Esr4BUApNNNs9_I4rs1t44ShIR42xfIB/view?usp=sharing"},
		{email : "silvanapt@gmail.com" ,                           path: "https://drive.google.com/file/d/1OF_8jksmHKq0RQC2XrSXV_FIg25_bP7X/view?usp=sharing"},
		{email : "soliva_cast@yahoo.es" ,                          path: "https://drive.google.com/file/d/1kY93CbgXycUQjzamtZmHH59zND7hke11/view?usp=sharing"},
		{email : "yunqui_89@hotmail.com" ,                         path: "https://drive.google.com/file/d/1Ex6Aqfnaou4mOlozaUNh40X-EK4_IPPt/view?usp=sharing"},
	]

	constructor(
		private store               : Store<StoreRootState>,
		private functions           : AngularFireFunctions,
		private translate           : TranslateService,
		private userService         : UserService,
		private sponsorServ         : SponsorService,
		private formsService        : FormsService,
		private eventsService       : EventsService,
		private helperService       : HelperService,
		private activatedRoute      : ActivatedRoute,
		private certificatesService : CertificatesService,
	) { }

	route         : Params = {};
	email         : boolean = true;
	event         : MagixEvent | null = null;
	runing        : boolean = false;
	sponsors      : any;
	slider_type   : string = 'carousel';
	subscriptions : Subscription[] = [];

	form = new FormGroup({
		name: new FormControl("", [Validators.required,]),
	});

	slideConfig = {
		"autoplay": true,
		"arrows": false,
		"slidesToShow": 3,
		"slidesToScroll": 1,
		"responsive": [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 520,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	async ngOnInit() {
		this.runing = true;
		this.route  = await this.activatedRoute.params.pipe(take(1)).toPromise();
		this.event  = await this.event$.pipe(take(1)).toPromise();
	
		if(this.event){
			this.sponsors    = await this.sponsorServ.getSponsors(this.event.id);
			this.slider_type = this.event.sponsors.certificado;

			document.documentElement.style.setProperty('--conference_bg', this.event.colors.backgrounds.certificates);
			// Cambiar el titulo de la pestaña
			this.subscriptions.push( this.event$.subscribe( response => {
				if(response && response.tabs){
					// window.document.title = response.tabs.certificates;
				}
			}));
		}
		//significa que si esta logueado
		if (!this.route.certifyId) {
			let user   = await this.store.pipe(select(currentUser), skipWhile((user) => !user), take(1)).toPromise();
			this.email = false;
			if(user){
				this.form.get("name")?.setValue(user.email);
			}
		}
		this.runing = false;
	}

  	ngOnDestroy(): void {
	    this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	async getInfoUrl() {
		if(this.event?.id == 24){
			let email = this.form.controls.name.value;
			if(email){
				let atten = this.attendees.find( attender => attender.email.toLowerCase() == email.toLowerCase() );
				if(atten){
					let a    = document.createElement('a');
					a.target = '_blank';
					a.href   = atten.path;
					a.click();
				}
				else{
					this.helperService.swalWarnign('Opsss','No se encontro ningun certificado con el correo: ' + email)
				}
			}
		}
		else{
			this.getActiveCertificates();
		}
	}

	async getActiveCertificates(){
		this.runing = true;
      	let email   = this.form.get('name')?.value;

		if (email) {
			let user = await this.userService.getUserByEmail(email);
			if (this.event && user && user.events && user.events[this.route.id]) {
				if (this.event.features.defaultCertificate) {					
					let certificates = await this.certificatesService.getCertificate(this.event.features.defaultCertificate)
					if (certificates.length) {
						for( let i = 0; i < certificates.length; i++){
							let list = await this.doItems(certificates[i], user, this.event);
							await this.doPDF(certificates[i], list, user.email);
						}
					}
					else{
						this.helperService.swalWarnign("", "ERROR no se encontro ningun certificado");
					}
				}else{
					this.helperService.swalWarnign("", "ERROR el evento aun no cuenta con un certificado");
				}
			}
			else {
				this.helperService.swalWarnign("", "ERROR no se encontro el evento o no estas registrado en el");
			}
		}
		else {
			this.helperService.swalWarnign("", "ERROR primero ingresa tu correo");
		}
		this.runing = false;
	}

	async doPDF(certificate: Certificate, list: Item[], email: string) {
	    let data     = { certificate: certificate, info: list, email: email }; 
	    let mailSend = this.functions.httpsCallable("printPdf"); //Invocacion de la funcion del servidor
	    let response = await mailSend(data).toPromise();
		
	    if (response) {
	      	window.location.href = response;
	    }
	    else {
			let title   = this.translate.instant('certificates.swal.pdfTitle');
			let message = this.translate.instant('certificate.swal.pdfMessage');
			this.helperService.swalWarnign(title, message);
	    }
	}
	
	async doItems(certificate: Certificate, user: User, event: MagixEvent) {
		let selectedList : Item[] = certificate.elements;
		let formRegister : any | null = null;
		//recorre los items que iran en el certificado
		for (let Item of selectedList) {
			//revisa a que lista pertenece el elemento
			switch (Item.list) {
				//lista de formularios
				case "form":
					Item.value = "";
					//en caso de que aun no se tenga datos del formulario para item
					if (!formRegister) {
						//@ts-ignore se guarda el formulario por si hay otro elemento en el certificado que tenga informacion del formulario
						formRegister =  await this.formsService.getRegisterData(Item.fid, user.uid).pipe(take(1)).toPromise();
					}
					  
					if (formRegister.questions && Item.elementf ) {
						formRegister.questions.map((question:any)=>{
							if(question.question==Item.element){
								//se asigna el valor del formulario al elemento del certificado
								Item.value = question.answer;
							}
						})
					}
				break;
				//lista evento
				case "event":
					//ver que tipode dato se nesecita
					switch (Item.element) {
						case 'Nombre'     : Item.value = event.title;        break; //se asigna el valor al elemento del certificado
						case 'Descripción': Item.value = event.description;  break; //se asigna el valor al elemento del certificado
				  	}
				break;
				//lista usuario
				case "user":
					//ver que tipo de elemento se necesita
					switch (Item.element) {
						case 'Nombre'         : Item.value = user.name;                          break;//se asigna el valor al elemento del certificado
						case 'Correo'         : Item.value = user.email;                         break;//se asigna el valor al elemento del certificado
						case 'Apellido'       : Item.value = user.lastName ? user.lastName : ""; break;//se asigna el valor al elemento del certificado
						case 'Nombre completo': Item.value = user.name + " " + user.lastName;    break;//se asigna el valor al elemento del certificado
				  	}
				break;
			}
		}
		return selectedList;
	}
	
}
