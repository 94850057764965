import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { EventsService } from '../../services/event.service';
import { ImagesService } from '../../services/images.service';
import { StoreRootState } from '../index.reducer';
import { MetricsService } from '../../services/metrics.service';
import { Store } from '@ngrx/store';

@Injectable()
export class AppEffects {

    constructor(
        private actions$: Actions,
        private eventsService: EventsService,
        private store: Store<StoreRootState>,
        private imagesService: ImagesService,
        private metricsService: MetricsService
    ) {
    }

}