import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CustomChatService } from 'src/app/core/services/custom.chat.service';
import { chatInterface, chatMessageInterface } from 'src/app/core/models/chats';
import { Store, select } from '@ngrx/store';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { currentUser } from 'src/app/core/auth/selectors/auth.selectors';
import { skipWhile, take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './private.component.html',
  styleUrls: [
    '../reset.component.scss',
    './private.component.scss',
  ]
})

export class CustomPrivateChatComponent implements OnInit{
  // @ts-ignore
  currentUser       : User;
  display           : string = 'display: none;';
  // @ts-ignore
  friend            : User;
  chat_id           : string = '';
  chat              : chatInterface | null = null;
  messages          : chatMessageInterface[] = [];
  subscriptions     : Subscription[] = [];
  friendChatElement : any;
  chatStatus        : boolean = false;

  message : chatMessageInterface = {
    id      : '',
    message : '',
    user_id : '',
    sent_at : 0,
  };

  constructor(
    private store       : Store<StoreRootState>,
    private activeRoute : ActivatedRoute,
    private chatServ    : CustomChatService,
    private userService : UserService,
    private helper      : HelperService,
    private userServ    : UserService,
    private router      : Router,
    private translate   : TranslateService
  ){}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(async(params: ParamMap) => {
      this.resetData();
      let id       = this.activeRoute.snapshot.paramMap.get('chat');
      let user     = await this.store.pipe(select(currentUser), skipWhile((user) => !user), take(1)).pipe().toPromise();
      let response = await this.userServ.getWithImg(user ? user.uid : null);
      if(id && response){
        this.chat_id = id;
        this.chat    = await this.chatServ.getByID(id);
        this.currentUser = response;
        this.getChatStatus();
        this.getChatMessages();
        await this.getChatFriend();
      }
    })
  }

  private getChatMessages(){
    this.subscriptions.push(
      this.chatServ.getMessages(this.chat_id).subscribe( messages => {
        this.messages = messages;
        this.scrollToLastMessage();
      })
    );
  }

  private async getChatFriend(){
    let response = await this.chatServ.getFriendUID(this.chat_id, this.currentUser.uid);
    if(response != false){
      await this.chatServ.removePendingChat(response, this.currentUser.uid);
      await this.setFriendChatElement(response);
    }else{
      await this.chatServ.removePendingChat(this.currentUser.uid, this.currentUser.uid);
      await this.setFriendChatElement(this.currentUser.uid);
    }
  }

  async setFriendChatElement(id : string){
    while(!this.friendChatElement){
      this.friendChatElement = document.querySelector(`#list_${id}`);
      if(!this.friendChatElement){
        await this.wait(200);
      }
    }
    this.friendChatElement.classList.add("active");
    let response = await this.userService.getWithImg(id);
    if(response != false){
      this.friend = response;
    }
  }

  private resetData(){
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if(this.friendChatElement){
      this.friendChatElement.classList.remove("active");
      this.friendChatElement = undefined;
    }
  }

  private wait(ms : number){
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async sendMessage() {
    if(this.message.message.length > 0){
      this.message.user_id = this.currentUser.uid;
      await this.chatServ.pendingChat(this.friend.uid, this.currentUser.uid);
      let response = await this.chatServ.sendMessage(this.chat_id, this.message);
      if(response){
        this.message.message = '';
      }else{
        let title = this.translate.instant('chat.swal.errorSendChat');
        this.helper.swalWarnign(title,'');
      }
    }
  }

  toggleEmoji() {
    if (this.display === 'display: none;') {
      this.display = 'display: block;';
    } else {
      this.display = 'display: none;';
    }
  }

  addEmoji(selected: any, input: any) {
    const emoji: string = (selected.emoji as any).native;
    input.focus();

    if (document.execCommand) {
      document.execCommand('insertText', false, emoji);
      return;
    }

    const [start, end] = [input.selectionStart, input.selectionEnd];
    input.setRangeText(emoji, start, end, 'end');
  }

  private async scrollToLastMessage(){
    let element : any;
    while(!element){
      element = document.querySelector('.messages');
      if(!element){
        await this.wait(200);
      }
    }
    element.scrollTop = element.scrollHeight;
  }

  navigateToFriendProfile(){
    let event_id   = this.activeRoute.snapshot.paramMap.get('id');
    let event_name = this.activeRoute.snapshot.paramMap.get('name');
    if (this.friend.type == "speaker") {
      this.router.navigate([`${event_id}/${event_name}/speaker/${this.friend.uid}`]);
    }else{
      this.router.navigate([`${event_id}/${event_name}/attendees/${this.friend.uid}`]);
    }
  }

  getChatStatus(){
    if(this.chat){
      if(this.currentUser.uid == this.chat.status.creator.id){
        this.chatStatus = true;
      }else{
        this.chatStatus = this.chat.status.friend.status;
      }
    }else{
      this.navigateToChat();
    }
  }

  navigateToChat(){
    let event_id   = this.activeRoute.snapshot.paramMap.get('id');
    let event_name = this.activeRoute.snapshot.paramMap.get('name');
    this.router.navigate([`${event_id}/${event_name}/chats`]);
  }

  async confirmChat(){
    if(this.chat){
      this.chatStatus = await this.chatServ.confirmChat(this.chat.id);
    }
  }
}
