<mat-card class="mat-elevation-z1">
    <!-- Buscador de asistentes -->
    <div class="fixedSearchContainer">
        <div class="form-group">
            <div class="input-container">
                <span>
                    <mat-icon class="search-icon">search</mat-icon>
                </span>
                <input type="text" class="form-control" id="searchStandInput" placeholder="{{'generalSearch.input' | translate}}" #input (keyup)="applyFilter($event)">
            </div>
        </div>
    </div>

    <div class="sort-by">
        <mat-accordion>
            <mat-expansion-panel hideToggle *ngFor="let letter of attendeesSearch; let i = index;" (opened)="openLetter(letter)">
                <mat-expansion-panel-header [ngClass]="'letter'+letter">
                    <mat-panel-title>
                        {{letter}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="list-container">
                    <cdk-virtual-scroll-viewport *ngIf="!(loading$ | async); else loaderBlock" itemSize="25" class="list-group ">
                        <div class="group" *cdkVirtualFor="let user of results" (click)="selectUser(user)" [ngClass]="{'expositor': user.userType == 'speaker'}">
                            <div class="img-container">
                                <img [src]="user.image ? user.image : 'https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png'">
                            </div>
                            <div class="description-container">
                                <span>{{user.name | uppercase}} {{user.lastName | uppercase}}</span>
                                <span> {{user.email | lowercase}} </span>
                                <span *ngIf="user.userType === 'speaker'"> EXPOSITOR </span>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                    <ng-template #loaderBlock>
                        <app-loader></app-loader>
                    </ng-template>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</mat-card>