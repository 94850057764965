import { User } from 'src/app/core/models/user';
import { currentUser } from 'src/app/core/auth/selectors/auth.selectors';
import { Store, select } from '@ngrx/store';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { map, skipWhile, take, withLatestFrom } from 'rxjs/operators';
import { Component, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { chatInterface } from 'src/app/core/models/chats';
import { CustomChatService } from 'src/app/core/services/custom.chat.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { selectEvent } from 'src/app/core/ngrx-store/event-store/event.selectors';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SponsorService } from 'src/app/core/services/sponsors.service';
import { Conference } from 'src/app/core/models/conferences/conference';
import { MagixEvent } from '../../../core/models/event';
import { isEventLoadedWithImages, selectEventState } from '../../../core/ngrx-store/event-store/event.selectors';

let diacriticLess = require('diacriticless');

@Component({
	templateUrl: './chat.component.html',
	styleUrls: [
		'./reset.component.scss',
		'./chat.component.scss',
		'./medias.component.scss',
	]
})

export class CustomChatComponent implements AfterViewInit {
	support_button_element: any;
	currentUser: User | null = null;
	friends: User[] = [];
	filteredData: User[] = [];
	currentChat: chatInterface | null = null;
	subscriptions: Subscription[] = [];
	pendingChats: string[] = [];
	defaultImg: string = 'https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png';
	sponsors: any;
	slider_type: string = 'carousel';

	event$ = this.store.pipe(select(selectEventState), skipWhile(state => !(state.eventLoadStatus == 'loaded')))



	constructor(
		private store: Store<StoreRootState>,
		private userServ: UserService,
		private chatServ: CustomChatService,
		private helper: HelperService,
		private activeRoute: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private sponsorServ: SponsorService
	) { }

	slideConfig = {
		"autoplay": true,
		"arrows": false,
		"slidesToShow": 1,
		"slidesToScroll": 1
	};

	async ngOnInit(): Promise<void> {
		let event = await this.store.pipe(select(selectEvent), skipWhile(data => data == null), take(1)).toPromise();
		let user = await this.store.pipe(select(currentUser), skipWhile((user) => !user), take(1)).pipe().toPromise();
		let response = await this.userServ.getWithImg(user ? user.uid : null);
		user = Object.assign({ friends: [user?.uid] }, user);
		if (response && user.friends && event) {
			this.currentUser = response;
			this.loadStyles(event.fid);
			this.getPendingChats(response.uid);
			this.subscriptions.push(
				this.userServ.friendsObservable(user.uid).subscribe(async (friends) => {
					this.friends = await this.userServ.getUserFriends(friends as string[]);
				})
			)
			this.slider_type = event.sponsors.chat;
			this.sponsors = await this.sponsorServ.getSponsors(event.id);
		}
		// Cambiar titulo de la pestaña
		this.subscriptions.push(this.event$.subscribe(response => {
			if (response.event && response.event.tabs) {
				// window.document.title = response.event.tabs.chat;
			}
		}));
	}

	async openChat(members: string[]) {
		let friend = members[0];
		members.push(`${this.currentUser?.uid}`);
		let memebers_sort_ids = members.sort().join('___');
		let getChatResponse = await this.chatServ.getByMembers(memebers_sort_ids);
		if (getChatResponse[0]) {
			this.navigateToChat(getChatResponse[0].id);
		} else {
			let createChatResponse = await this.chatServ.create(friend, `${this.currentUser?.uid}`);
			if (createChatResponse != false) {
				this.navigateToChat(createChatResponse.id);
			} else {
				let title = this.translate.instant('chat.swal.openChat');
				this.helper.swalWarnign(title, '');
			}
		}
	}
	async ngAfterViewInit() {
		this.support_button_element = document.querySelector('#support-button');
		this.support_button_element.style.visibility = 'hidden';
	}
	ngOnDestroy(): void {
		this.support_button_element.style.visibility = 'visible';
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
	applyFilter(keyboardEvent: KeyboardEvent) {
		const FILTER_VALUE = (keyboardEvent.target as HTMLInputElement).value;
		if (FILTER_VALUE.length == 0) {
			this.filteredData = [];
		} else {
			this.filteredData = this.friends.filter((element) => {
				return (this.sanitizeText(element.name).indexOf(this.sanitizeText(FILTER_VALUE)) > -1) || (this.sanitizeText(element.email).indexOf(this.sanitizeText(FILTER_VALUE)) > -1)
			});
		}
	}
	private sanitizeText(text: string): string {
		return diacriticLess(text.toLowerCase().trim());
	}

	navigateToChat(chat_id: string) {
		let event_id = this.activeRoute.snapshot.paramMap.get('id');
		let event_name = this.activeRoute.snapshot.paramMap.get('name');

		if (event_id && event_name) {
			this.router.navigate([`${event_id}/${event_name}/chats/${chat_id}`]);
		}
	}
	navigateToProfile() {
		let event_id = this.activeRoute.snapshot.paramMap.get('id');
		let event_name = this.activeRoute.snapshot.paramMap.get('name');

		if (event_id && event_name) {
			this.router.navigate([`${event_id}/${event_name}/account`]);
		}
	}

	private loadStyles(event_fid: string) {
		this.subscriptions.push(
			this.chatServ.getStyles(event_fid).subscribe(styles => {
				if (styles) {
					document.documentElement.style.setProperty('--sidebar_profile', styles.colors.user);
					document.documentElement.style.setProperty('--chat_sidebar_bg', styles.backgrounds.sidebar);
					document.documentElement.style.setProperty('--sidebar_friends_name', styles.colors.friends.name);
					document.documentElement.style.setProperty('--sidebar_friends_email', styles.colors.friends.email);
					document.documentElement.style.setProperty('--sidebar_search_text', styles.colors.search);
					document.documentElement.style.setProperty('--sidebar_search_bg', styles.backgrounds.search);
					document.documentElement.style.setProperty('--sidebar_active_friend', styles.colors.friends.selected);
					// Chat
					document.documentElement.style.setProperty('--chat_bg', styles.backgrounds.chat.general);
					document.documentElement.style.setProperty('--chat_header_bg', styles.backgrounds.header);
					document.documentElement.style.setProperty('--chat_friend', styles.colors.chat.friend);

					document.documentElement.style.setProperty('--chat_msg_send_bg', styles.backgrounds.chat.messages.send);
					document.documentElement.style.setProperty('--chat_msg_send_txt', styles.colors.chat.messages.send);
					document.documentElement.style.setProperty('--chat_msg_replies_bg', styles.backgrounds.chat.messages.receive);
					document.documentElement.style.setProperty('--chat_msg_replies_txt', styles.colors.chat.messages.receive);

					document.documentElement.style.setProperty('--chat_input_bg', styles.backgrounds.chat.input);
					document.documentElement.style.setProperty('--chat_input_txt', styles.colors.chat.input);
					document.documentElement.style.setProperty('--chat_btn_bg', styles.backgrounds.chat.button);
					document.documentElement.style.setProperty('--chat_btn_txt', styles.colors.chat.button);

					document.documentElement.style.setProperty('--chat_bar_bg', styles.backgrounds.chat.bar);
					document.documentElement.style.setProperty('--chat_emoji_color', styles.colors.chat.emoji);
				}
			})
		);
	}

	private getPendingChats(uid: string) {
		this.subscriptions.push(
			this.chatServ.getPendingChats(uid).subscribe(
				pendingChats => {
					this.pendingChats = pendingChats;
				}
			)
		);
	}

	private sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}
