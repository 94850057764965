import { User } from 'src/app/core/models/user';
import { Component, Input, Output, EventEmitter } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector    : 'networking-profile-center-section',
  styleUrls   : ['./profile.component.scss'],
  templateUrl : './profile.component.html',
})

export class NetworkingProfileCenterSectionComponent {

	@Output() showFriend : EventEmitter<boolean> = new EventEmitter();
	@Output() showChats  : EventEmitter<boolean> = new EventEmitter();
	@Input() friend! : User;

	constructor(){}

	// navigateToProfile(){
	// 	console.log(this.friend);
	// }
	
}
