import { NetworkingStylingInterface } from 'src/app/core/models/networking.interface';

export class SetNetworkingStyle{

	constructor( private styles : NetworkingStylingInterface){
		this.setCssVariables("--colorGeneralBorder",     this.styles.colors.border);
		this.setCssVariables("--bgGeneralScrollTrack",   this.styles.backgrounds.scroll.track);
		this.setCssVariables("--bgGeneralScrollThumb",   this.styles.backgrounds.scroll.navigator);

		this.setCssVariables("--bgGeneralTop",      this.styles.backgrounds.general.top);
		this.setCssVariables("--bgGeneralBottom",   this.styles.backgrounds.general.bottom);
	}

	setLeftSideBackgrounds(){
		this.setCssVariables("--bgLeftSidebarProfile",       this.styles.backgrounds.leftSidebar.profile);
		this.setCssVariables("--bgLeftSidebarFriends",       this.styles.backgrounds.leftSidebar.friends);
		this.setCssVariables("--bgLeftSidebarFriendsHover",  this.styles.backgrounds.leftSidebar.friendsHover);
		this.setCssVariables("--bgLeftSidebarSearcherInput", this.styles.backgrounds.leftSidebar.searcherInput);
		this.setCssVariables("--bgLeftSidebarPendingMessage", this.styles.backgrounds.leftSidebar.pendingMessage);
	}

	setLeftSideColors(){
		this.setCssVariables("--colorLeftSidebarProfileIcons",          this.styles.colors.leftSidebar.profileIcons);
		this.setCssVariables("--colorLeftSidebarSearcherIcon",          this.styles.colors.leftSidebar.searcherIcon);
		this.setCssVariables("--colorLeftSidebarSearcherText",          this.styles.colors.leftSidebar.searcherText);
		this.setCssVariables("--colorLeftSidebarFriendsNames",          this.styles.colors.leftSidebar.friendsNames);
		this.setCssVariables("--colorLeftSidebarPendingMessageIcon",    this.styles.colors.leftSidebar.pendingMessageIcon);
		this.setCssVariables("--colorLeftSidebarFriendsLastestDate",    this.styles.colors.leftSidebar.friendsLastestDate);
		this.setCssVariables("--colorLeftSidebarFriendsLastestMessage", this.styles.colors.leftSidebar.friendsLastestMessage);

	}

	setOverLeftSideBackgrounds(){
		this.setCssVariables("--bgOverLeftSidebarTitle",         this.styles.backgrounds.overLeftSidebar.title);
		this.setCssVariables("--bgOverLeftSidebarSearcher",      this.styles.backgrounds.overLeftSidebar.searcher);
		this.setCssVariables("--bgOverLeftSidebarAttendees",     this.styles.backgrounds.overLeftSidebar.attendees);
		this.setCssVariables("--bgOverLeftSidebarSearcherInput", this.styles.backgrounds.overLeftSidebar.searcherInput);
		this.setCssVariables("--bgOverLeftSidebarAttendeesHover",this.styles.backgrounds.overLeftSidebar.attendeesHover);
	}

	setOverLeftSideColors(){
		this.setCssVariables("--colorOverLeftSidebarTitleIcons",            this.styles.colors.overLeftSidebar.titleIcons);
		this.setCssVariables("--colorOverLeftSidebarTitleText",             this.styles.colors.overLeftSidebar.titleText);
		this.setCssVariables("--colorOverLeftSidebarSearcherIcon",          this.styles.colors.overLeftSidebar.searcherIcon);
		this.setCssVariables("--colorOverLeftSidebarSearcherText",          this.styles.colors.overLeftSidebar.searcherText);
		this.setCssVariables("--colorOverLeftSidebarFriendsNames",          this.styles.colors.overLeftSidebar.friendsNames);
	}

	setChatBackgrounds(){
		this.setCssVariables("--bgChatSender",      this.styles.backgrounds.chat.sender);
		this.setCssVariables("--bgChatProfile",     this.styles.backgrounds.chat.profile);
		this.setCssVariables("--bgChatSelected",    this.styles.backgrounds.chat.selected);
		this.setCssVariables("--bgChatMessageIn",   this.styles.backgrounds.chat.messageIn);
		this.setCssVariables("--bgChatNoSelected",  this.styles.backgrounds.chat.noSelected);
		this.setCssVariables("--bgChatMessageOut",  this.styles.backgrounds.chat.messageOut);
		this.setCssVariables("--bgChatSenderInput", this.styles.backgrounds.chat.senderInput);
	}

	setChatColors(){
		this.setCssVariables("--colorChatSenderText",         this.styles.colors.chat.senderText);
		this.setCssVariables("--colorChatProfileText",        this.styles.colors.chat.profileText);
		this.setCssVariables("--colorChatSenderIcons",        this.styles.colors.chat.senderIcons);
		this.setCssVariables("--colorChatProfileIcons",       this.styles.colors.chat.profileIcons);
		this.setCssVariables("--colorChatMessageInText",      this.styles.colors.chat.messageInText);
		this.setCssVariables("--colorChatMessageOutText",     this.styles.colors.chat.messageOutText);
		this.setCssVariables("--colorChatMessageInDateIcon",  this.styles.colors.chat.messageInDateIcon);
		this.setCssVariables("--colorChatMessageInDateText",  this.styles.colors.chat.messageInDateText);
		this.setCssVariables("--colorChatMessageOutDateIcon", this.styles.colors.chat.messageOutDateIcon);
		this.setCssVariables("--colorChatMessageOutDateText", this.styles.colors.chat.messageOutDateText);
	}

	setRightSideBackgrounds(){
		this.setCssVariables("--bgRightSidebarTitle",   this.styles.backgrounds.rightSidebar.title);
		this.setCssVariables("--bgRightSidebarGeneral", this.styles.backgrounds.rightSidebar.general);
	}

	setRightSideColors(){
		this.setCssVariables("--colorRightSidebarTitleText", this.styles.colors.rightSidebar.titleText);
		this.setCssVariables("--colorRightSidebarTitleIcons", this.styles.colors.rightSidebar.titleIcons);
		this.setCssVariables("--colorRightSidebarFriendText", this.styles.colors.rightSidebar.friendText);
	}

	setImg(){
		if(this.styles.images.background){
			this.setCssVariables("--imgGeneralBackground", `url("${this.styles.images.background}")`);
		}
		
		this.setCssVariables("--imgCenterChatEmpty",  `url("${this.styles.images.chatEmpty}")`);
		this.setCssVariables("--imgCenterChatFriend", `url("${this.styles.images.chatFriend}")`);
	}

	private setCssVariables(name : string, value : any){
		document.documentElement.style.setProperty(name, value);
	}

}