import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
// Operadores
import { Observable        } from 'rxjs';
import { shareReplay, map, mergeMap, take } from 'rxjs/operators';
import { FormAnswersInterfaz, FormInterfaz, FormQuestionAnswersInterfaz, FormPaymentInterfaz, FormEmailRegistrationInterface, PaymentResponseInterface, FormNormalInterfaz } from '../models/events/form';
import { FilesService } from './files.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { EmailInterfaz } from '../models/email';
import { ErrorService } from './error.service';
import * as moment from 'moment-timezone';
import * as firebase from 'firebase';
import { MagixEventSummary } from '../models/event';

@Injectable({ providedIn: 'root' })

export class FormsService {
    //Constante de la ubicacion en firebase
    BASE_PATH = '/forms';
    timezone  = moment.tz.guess();
    private SANDBOX = "sandbox";
    //Inicializar servicios
    constructor(
        private database    : AngularFireDatabase,
        private functions   : AngularFireFunctions,
        private fileService : FilesService,
        private errorServ   : ErrorService
    ) { }

    async fillFormPaymentData( formPayment : FormPaymentInterfaz ) : Promise<FormPaymentInterfaz> {
      let ref = await this.database.database.ref(`payments/${formPayment.form_id}`).push();

      formPayment.id         = ref.key;
      formPayment.sesion     = `${formPayment.form_id}/${formPayment.id}`;
      formPayment.created_at = firebase.default.database.ServerValue.TIMESTAMP;

      if(formPayment.email){
        formPayment.email = this.setProductIDToEmail(formPayment.email, formPayment.id);
      }

      return formPayment;
    }
    // : Promise<PaymentResponseInterface>
    async generatePayment( formPayment : FormPaymentInterfaz ){
        // try{
        //     let registerPayment = this.functions.httpsCallable('makePaymentTransaction');
        //     // Generando token y url del pago
        //     let response : PaymentResponseInterface = await registerPayment({
        //         order  : formPayment.id,
        //         amount : formPayment.amount,
        //         sesion : formPayment.sesion
        //     }).pipe(take(1)).toPromise();
        //     // Validar que todo se haya ejecutado bien
        //     if(response.payment && response.status){
        //         // Asignar url y token al objeto
        //         formPayment.url    = response.payment?.url;
        //         formPayment.token  = response.payment?.token;
        //         formPayment.status = 0;
        //         // Almacenar el pago
        //         return {
        //             status  : true,
        //             payment : response.payment
        //         }
        //     }
        //     return {status : false};
        // }catch(error){
        //     console.warn('Ocurrio un error, mas detalles: ',error);
        //     return {status : false};
        // }
    }

    //Obtener data con las imagenes
    getAll(event_fid: string): Observable<FormInterfaz[]> {
        return this.database.list<FormInterfaz>(`${this.BASE_PATH}`).valueChanges()
                            .pipe(
                                shareReplay(1),
                                map( forms => forms.filter( form => form.event_fid == event_fid ) )
                            ).pipe(mergeMap( elements => {
                                return Promise.all(elements.map(async form  => {
                                    form.files.images.banner     = await this.fileService.getDownloadLink(form.files.images.banner);
                                    form.files.images.background = await this.fileService.getDownloadLink(form.files.images.background);
                                  return form;
                                }));
                            } ));
    }

    getRegisterData(formFID: string, uid: string) {
        return this.database.list<any>(`forms/data/${formFID}`, (reference) => reference.orderByChild("user_uid").equalTo(uid).limitToLast(1)).valueChanges();
    }

    getByEventAndFormFID(event_fid : string, form_id : string){
        return this.database.object<FormInterfaz>(`${this.BASE_PATH}/${form_id}`).valueChanges()
                            .pipe(
                                shareReplay(1),
                                map( form => {
                                    if(form && form.event_fid == event_fid){
                                        return form;
                                    }
                                    return null;
                                })
                            )
                            .pipe(mergeMap( form => {
                                return new Promise(async (resolve) => {
                                    if(form){
                                        form.files.logos.main        = await this.fileService.getDownloadLink(form.files.logos.main);
                                        form.files.termsOfUser       = await this.fileService.getDownloadLink(form.files.termsOfUser);
                                        form.files.logos.mobile      = await this.fileService.getDownloadLink(form.files.logos.mobile);
                                        form.files.images.banner     = await this.fileService.getDownloadLink(form.files.images.banner);
                                        form.files.logos.desktop     = await this.fileService.getDownloadLink(form.files.logos.desktop);
                                        form.files.images.product    = await this.fileService.getDownloadLink(form.files.images.product);
                                        form.files.images.background = await this.fileService.getDownloadLink(form.files.images.background);

                                        form.start_at = moment.tz(form.start_at , this.timezone).format("YYYY-MM-DD kk:mm");
                                        form.end_at   = moment.tz(form.end_at , this.timezone).format("YYYY-MM-DD kk:mm");
                                    }
                                    resolve(form);
                                });
                            } ));
    }

	async saveFormAnswers( answers : FormAnswersInterfaz ) : Promise<boolean>{
        try{
            let registerFormAnswers = this.functions.httpsCallable('registerFormAnswers');
            return await registerFormAnswers(answers).pipe(take(1)).toPromise();;
        }
        catch(error : any){
            this.errorServ.handleErrors(`Error al almacenar la data de un formulario en base de datos. Más detalles: `,error);
            return false;
        }
    }

    async saveUserAnswers( data : FormNormalInterfaz ) : Promise<boolean>{
        try{
            let registerUserForm = this.functions.httpsCallable('registerUserForm');
            return await registerUserForm(data).pipe(take(1)).toPromise();;
        }
        catch(error : any){
            this.errorServ.handleErrors(`Error al almacenar la data de un formulario en base de datos. Más detalles: `,error);
            return false;
        }
    }

    async getEmailVars( form_id : string, questions : FormQuestionAnswersInterfaz[] ) : Promise<FormEmailRegistrationInterface[]>{
        return await this.database.list<EmailInterfaz>(`emails`).valueChanges()
                         .pipe(
                            take(1),
                            map( emails => {
                                emails = emails.filter(email => email.variablesFromFormID == form_id);
                                return emails.map( email => {
                                    email.htmlContent = email.htmlContent.trim().replace(/ +(?= )/g,'');
                                    return email;
                                })
                            }),
                            map( emails => emails.map( email => {
                                // Transformamos data para despues retornarlas con sus respectivas variables tokenizadas
                                let replacedVars = email.htmlContent.split("_||_").map( text => {
                                questions.forEach( question => {
                                    if(text == question.id){
                                        text = question.answer.toString();
                                    }
                                });
                                return text;
                                });
                                email.htmlContent = replacedVars.join("");
                                return email;
                            })),
                            map( emails => emails.map( email => {
                                let userEmail = '';
                                // Buscar el email del usuario atravez del id de la pregunta
                                questions.every( question => {
                                    if( question.id.substr(question.id.length - 8) == '___email'){
                                        userEmail = question.answer.toString();
                                        return false;
                                    }
                                    return true;
                                });
                                // Retornamos el mensaje formateado
                                return {
                                    to       : userEmail,
                                    html     : email.htmlContent,
                                    email    : email.email,
                                    domain   : 'vnew.cl',
                                    subject  : email.subject,
                                    emailName: email.title,
                                }
                            }))
                         )
                         .toPromise();
    }

    getUserByTransformingQuestions( questions : FormQuestionAnswersInterfaz[] ){
        let user = {
            job         : '',
            name        : '',
            city        : '',
            phone       : '',
            email       : '',
            summary     : '',
            country     : '',
            lastName    : '',
            password    : '',
            time_zone   : '',
            description : '',
        };
        questions.forEach(question => {
            if( question.id.substr(question.id.length - 7) == '___name'){
                user.name = question.answer.toString();
            }
            else if( question.id.substr(question.id.length - 11) == '___lastName'){
                user.lastName = question.answer.toString();
            }
            else if( question.id.substr(question.id.length - 8) == '___email'){
                user.email = question.answer.toString();
            }
            else if( question.id.substr(question.id.length - 11) == '___password'){
                user.password = question.answer.toString();
            }
            else{

                switch( question.id ){
                    case 'job'         : user.job          = user.job         ? user.job         : question.answer.toString(); break;
                    case 'city'        : user.city         = user.city        ? user.city        : question.answer.toString(); break;
                    case 'phone'       : user.phone        = user.phone       ? user.phone       : question.answer.toString(); break;
                    case 'summary'     : user.summary      = user.summary     ? user.summary     : question.answer.toString(); break;
                    case 'country'     : user.country      = user.country     ? user.country     : question.answer.toString(); break;
                    case 'time_zone'   : user.time_zone    = user.time_zone   ? user.time_zone   : question.answer.toString(); break;
                    case 'description' : user.description  = user.description ? user.description : question.answer.toString(); break;
                }

            }
        });
        return user;
    }

    async checkIfUserExistInEvent( email : string, event_id : number) : Promise<boolean>{
        try{
            let checkIfUserExistInEventFunction = this.functions.httpsCallable('checkIfFormUserExistInEvent');
            return await checkIfUserExistInEventFunction({email,event_id}).pipe(take(1)).toPromise();
        }catch(error){
            return false;
        }
    }

    private setProductIDToEmail(emails : FormEmailRegistrationInterface[], product_id : string) : FormEmailRegistrationInterface[] {
      const ID_VENTA_VAR      = "____id_venta____";
      const ID_VENTA_FORMATED = `Nº000000${product_id}`;
      emails.map( email => {
        email.emailName = email.emailName.replace(ID_VENTA_VAR,ID_VENTA_FORMATED);
        email.html = email.html.split("</p>").map( word => {
          return word.replace(ID_VENTA_VAR,ID_VENTA_FORMATED);
        }).join("</p>");
      });
      return emails;
    }

    async getPaymentResponse(eventID: string|number, formID: string, sessionID: string): Promise<{event: MagixEventSummary, payment: FormPaymentInterfaz}|false> {
        try{
            console.log("{ eventID ,formID, sessionID }", { eventID ,formID, sessionID });
            let registerFormAnswers = this.functions.httpsCallable('getPayment');
            let response = await registerFormAnswers({ eventID ,formID, sessionID }).pipe(take(1)).toPromise();
            return response;
        }
        catch(error : any){
            this.errorServ.handleErrors(`Error al almacenar la data de un formulario en base de datos. Más detalles: `,error);
            return false;
        }
    }
}
