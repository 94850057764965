import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StoreRootState } from './core/ngrx-store/index.reducer';
import { LoadUser } from './core/auth/actions/auth.actions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isConnected } from './core/auth/selectors/auth.selectors';
import { Observable } from 'rxjs';
import { HelperService } from './core/services/helper.service';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

declare var gtag : any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  AppConnection$: Observable<boolean> = this.store.pipe(select(isConnected), debounceTime(2000));
  lastConnection = true;
  constructor(
    private store: Store<StoreRootState>,
    private activeRoute: ActivatedRoute,
    private router      : Router,
    private helper: HelperService,
    private translate: TranslateService
  ) 
  {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        let arrayPath = event.urlAfterRedirects.split('/');
        let eventPath = event.urlAfterRedirects.split('/');
        arrayPath.splice(0,3);

        gtag('event', `${eventPath[1]}/${eventPath[2]}`, {
          'event_category': eventPath[1],
          'event_label':  arrayPath.join('/')
        });
       }
    })
  }

  ngOnInit() {
    setTimeout(this.initializeData.bind(this), 1000);
  }

  initializeData() {
    this.AppConnection$.subscribe(this.changeAppConnection.bind(this))
  }

  changeAppConnection(online: boolean) {
    if(this.lastConnection != online){
      if (online){
        this.helper.swalClose();
        this.lastConnection = online;
      }
      else
        this.helper.swalDisconnection();
    }
  }
}