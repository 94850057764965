import { User } from 'src/app/core/models/user';
import { FormGroup, FormControl } from '@angular/forms';
import { NetworkingChatInterface } from 'src/app/core/models/networking.interface';
import { Component, Output, EventEmitter, Input } from '@angular/core';


@Component({
	selector    : 'networking-attendees-left-section',
	templateUrl : './attendees.component.html',
	styleUrls   : [
		'./attendees.component.scss',
		'./dynamicStyles/title.component.scss',
		'./dynamicStyles/search.component.scss',
		'./dynamicStyles/attendees.component.scss',
	],
})

export class NetworkingAttendeesLeftSectionComponent {
	@Output() chat          : EventEmitter<NetworkingChatInterface> = new EventEmitter();
	@Output() hideAttendees : EventEmitter<void>                    = new EventEmitter();

	@Input() currentUser    : User | null = null;

	form = new FormGroup({
		search : new FormControl()
	});
}
