import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import firebase from 'firebase';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay, take, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Logout, UserLoaded } from '../auth/actions/auth.actions';
import { User } from '../models/user';
import { MagixEvent } from '../models/event';
import { ErrorService } from './error.service';
import { FilesService } from './files.service';
import { ImagesService } from './images.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  BASE_PATH = "users";

  userSubscription: Subscription | null = null;

  constructor(
    private database: AngularFireDatabase,
    private firestore: AngularFirestore,
    private store: Store,
    private errorServ : ErrorService,
    private fileService : FilesService
  ) { }
  
  async getWithImg(uid: string | null): Promise<User | false>{
    if (!uid) return false;
    try {
      const snapshot = await this.database.database.ref(`${this.BASE_PATH}/${uid}`).once("value");
      if(snapshot.exists()){
        let user = snapshot.val();
        if(user.image){
          user.image = await this.fileService.getDownloadLink(user.image);
        }
        return user;
      }
      return false;
    } catch (error) {
      this.errorServ.handleErrors(`Error al buscar usuario en base de datos. UID: ${uid} Más detalles: `,error);
      throw (error);
    }
  }

  async get(uid: string | null): Promise<User | false> {
    if (!uid) return false;
    try {
      const snapshot = await this.database.database.ref(`${this.BASE_PATH}/${uid}`).once("value");
      if(snapshot.exists()){
          return snapshot.val();
      }
      return false;
    } catch (error) {
      this.errorServ.handleErrors(`Error al buscar usuario en base de datos. UID: ${uid} Más detalles: `,error);
      throw (error);
    }
  }

  initializeUserUpdater(uid: string | null): void {
    if (uid) {
      let userObservable = this.getObservable(uid);
      this.userSubscription = userObservable.subscribe(user => {
        if (user)
          this.store.dispatch(new UserLoaded({ user }));
        else 
          this.store.dispatch(new Logout());
      });
    }
  }

  offUserUpdater() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  async updateUser(uid: string, user: User | any) {
    return this.database.database.ref(`${this.BASE_PATH}/${uid}`).update(user).catch(erro => console.log("error", erro));
  }

  getObservable(uid: string): Observable<User | null> {
    return this.database.object<User>(`${this.BASE_PATH}/${uid}`).valueChanges().pipe(shareReplay(1));
  }

  private async snapshotValidation<T>(snapshot: firebase.database.DataSnapshot): Promise<T | false> {
    if(snapshot.exists()){
      let user = snapshot.val();
      user.image = await this.fileService.getDownloadLink(user.image);
      return user;
    }
    return false;
  }

  	async getUserByEmail(email: string): Promise<User | null> {
		return await this.database.list<User>("users", ref => ref.orderByChild("email").startAt(email).endAt(email + "\uf8ff").limitToLast(1))
								  .valueChanges()
								  .pipe(
									  map(users => {
										  if(users.length){
											  return users[0];
										  }else{
											  return null;
										  }
									  }),
									  take(1)
								   )
								   .toPromise();
  	}

  async getUserFriends(friendsUid: string[]): Promise<User[]> {
    let friendsInfo:User[]=[]
    try {
      for (let friend of friendsUid){
        let user = await this.firestore.collection<User>("users")
                                        .doc(friend)
                                        .get()
                                        .pipe(
                                          take(1),
                                          map( user => user.data()),
                                          map( async(user) => {
                                            if(user){
                                              if(user.image){
                                                user.image = await this.fileService.getDownloadLink(user.image);
                                              }
                                            }
                                            return user;
                                          })
                                        ).toPromise();
        if(user){
          friendsInfo.push(user);
        }
      }
    } catch (error) {
      this.errorServ.handleErrors(`Ha ocurrido un error al obtener el usuario por correo. Detalles: `,error);
    }
    return friendsInfo
  }

  friendsObservable( user : string){
    return this.database.list(`users/${user}/friends`).valueChanges().pipe(shareReplay(1));
  }
}
