// 1 - Importaciones
import { ActionReducer } from '@ngrx/store';
import { AppActions, AppActionsTypes } from './app.actions';

export interface AppState {
    language: "es-MX" | "en-US";
}

const initialState: AppState = {
    language: "es-MX"
}

// 3 - Switch con las funciones reducer
export const appReducer: ActionReducer<AppState, AppActions> = function (state = initialState, action) {
    switch (action.type) {
        case AppActionsTypes.ChangeLanguage:
            return { ...state, language: action.payload.lang };
        default:
            return state;
    }
}