<div id="main-base-div">
    <div class="fixed-nav animate__animated animate__fadeInDown side">
        <app-navbar></app-navbar>
    </div>
    <div class="animate__animated animate__fadeIn animate__slower components " [@routeAnimations]="o && o.activatedRouteData && o.activatedRouteData['animation']">
        <router-outlet #o="outlet"></router-outlet>
    </div>

    <app-whatsapp-support-button id="vnew-support-button" message="¡Hola!, deseo registrarme al Congreso Internacional de Crianza en la Adolescencia"></app-whatsapp-support-button>
</div>
