import { Component, Input, EventEmitter, Output, OnInit, AfterViewInit } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendeesService } from 'src/app/core/services/attendees.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, skipWhile, take } from 'rxjs/operators';
import { MagixEvent } from 'src/app/core/models/event';
import { UserService } from 'src/app/core/services/user.service';
let diacriticLess = require('diacriticless');

@Component({
  selector: 'app-attendees-list',
  templateUrl: './attendees.list.component.html',
  styleUrls: ['./attendees.list.component.scss']
})

export class AttendeesListComponent implements AfterViewInit {

  @Input() event: MagixEvent | null = null;
  @Output() selectedUser: EventEmitter<User> = new EventEmitter();
  searchInput: BehaviorSubject<string> = new BehaviorSubject("");
  searchText$: Observable<string> = this.searchInput.pipe(debounceTime(500));
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentUser: User | null = null;

  private letterList: string[] = [];
  attendeesSearch: string[] = this.letterList;
  results: User[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private attendeesService: AttendeesService,
    private userServ: UserService,
  ) {
  }

  async ngAfterViewInit() {
    this.searchText$.subscribe(this.search.bind(this));
    if (this.event)
      this.letterList = await this.attendeesService.getAttendeesIndex(this.event.id);
  }

  applyFilter(keyboardEvent: KeyboardEvent) {
    const FILTER_VALUE = (keyboardEvent.target as HTMLInputElement).value;
    this.searchInput.next(String(FILTER_VALUE).toLowerCase());
  }

  async search(text: string,) {
    text = this.sanitizeText(text);
    if (text && this.event) {
      this.loading$.next(true);
      const result = await this.attendeesService.getAttendeesSummaryByField(this.event.id, text);
      const firstLetter = String(text)[0].toUpperCase();

      if (result.length > 0) {
        this.attendeesSearch = [firstLetter];
        this.results = result;
        setTimeout(() => jQuery(`.letter${firstLetter}`).trigger("click"), 500);
      } else {
        this.attendeesSearch = [];
        this.results = [];
      }

      this.loading$.next(false);
    } else {
      this.attendeesSearch = this.letterList;
      this.results = [];
    }
  }

  async openLetter(letter: string): Promise<void> {
    letter = letter.toLowerCase();
    let searchText = await this.searchText$.pipe(take(1)).toPromise();

    if (!searchText) {
      if (!(this.results.length > 0 && String(this.results[0].name)[0] == letter)) {
        this.loading$.next(true);
        this.results = [];

        if (this.event) {
          const result = await this.attendeesService.getAttendeesSummaryByField(this.event.id, String(letter).toLowerCase());
          this.results = result;
        }

        this.loading$.next(false);
      }
    }
  }

  //Emitir el expositor seleccionado
  async selectUser(user: User) {
    let response = await this.userServ.getWithImg(user.uid);

    if (response) {
      user = response;
    }

    if (user.type == "speaker") {
      let event_id   = this.activatedRoute.snapshot.paramMap.get('id');
      let event_name = this.activatedRoute.snapshot.paramMap.get('name');
      
      this.router.navigate([`${event_id}/${event_name}/speaker/${user.uid}`]);
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.selectedUser.emit(user);
    }
  }

  //Sanitizar el buscador
  sanitizeText(text: string): string {
    return diacriticLess(text.toLowerCase().trim());
  }
}
