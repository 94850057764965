import { Action } from '@ngrx/store'
import { AppState } from './app.reducers';

export enum AppActionsTypes {
    Default = '[Default] Action',
    ChangeLanguage = '[ChangeLanguage] Action',
    NoAction = '[NoAction] Action'
}

export class Default implements Action {
    readonly type = AppActionsTypes.Default;
    constructor(public payload: any) { }
}

export class ChangeLanguage implements Action {
    readonly type = AppActionsTypes.ChangeLanguage;
    constructor(public payload: { lang: AppState["language"] }) { }
}

export class NoAction implements Action {
    readonly type = AppActionsTypes.NoAction;
}

export type AppActions =
    Default |
    ChangeLanguage |
    NoAction;