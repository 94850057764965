<section *ngIf="event; else loading">
    <div class="certificate-bg"></div>
    <app-main-banner [banner]="event.banners.certificates" *ngIf="event.banners.certificates"></app-main-banner>

    <div class="container" *ngIf="!runing; else loadingCertificate">
        <div class="row">
            <div style="margin-top: 60px;" class="col-12 text-center">
                <h2> {{'certificates.title' | translate}} </h2>
                <p style="color: rgb(59, 59, 59);">
                    {{'certificates.instructions' | translate}}
                </p>
                <div [formGroup]="form" id="certificado" style="margin-top: 100px; width: 100%;">
                    <ng-container ktPortletTools>
                        <mat-form-field class="example-full-width">
                            <mat-label> {{'certificates.labelEmail' | translate}} </mat-label>
                            <input matInput formControlName="name" style="width: 350px; height: 30px; font-size: 22px;" placeholder="Correo..." maxlength="30">
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 text-center">
                <div class="d-grid gap-2 col-6 mx-auto">
                    <button class="btn btn-primary" (click)="getInfoUrl()" name="buttonSendCertificate" type="button">
                        {{"certificates.btnSearchCertificate" | translate}} 
					</button>
                </div>
            </div>
            <div class="col-12 text-center" *ngIf="slider_type == 'carousel'">
                <ngx-slick-carousel class="carousel"
                        style="margin-top: 10%;"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of sponsors" class="slide mx-2" [style]="'background-image:'+slide.category.color+'; border-radius: .5rem;'">
                        <a [href]="slide.sponsor.url" target="_blank" rel="noopener noreferrer">
                            <img class="imgCarousel" src="{{ slide.sponsor.logo }}" width="100%">
                        </a>
                    </div>
                </ngx-slick-carousel>
            </div>

            <div class="col-12 text-center custom-sponsor-list" *ngIf="slider_type == 'static'">
              <div *ngFor="let slide of sponsors" class="slide mx-2" [style]="'background-image:'+slide.category.color+'; border-radius: .5rem;'">
                <a [href]="slide.sponsor.url" target="_blank" rel="noopener noreferrer">
                    <img class="imgCarousel" src="{{ slide.sponsor.logo }}" width="100%">
                </a>
              </div>
            </div>
        </div>

    </div>
    <ng-template #loadingCertificate>
        <app-loader></app-loader>
    </ng-template>
</section>

<ng-template #loading>
    <app-loader></app-loader>
</ng-template>
