import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logout } from '../auth/actions/auth.actions';
import { StoreRootState } from '../ngrx-store/index.reducer';

@Injectable({providedIn: 'root'})

export class ErrorService {

  constructor(private store: Store<StoreRootState>) { }

  handleErrors(msg : string, error : Error){
    console.warn(msg, error);
    if(error.message.split(':')[0] == 'PERMISSION_DENIED'){
        this.store.dispatch(new Logout());
    }
  } 
}