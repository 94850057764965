import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, concat, EMPTY, from, Observable, of, pipe, throwError, zip } from 'rxjs';

import { mergeMap, withLatestFrom, filter, tap, take, concatMap, map, delay, catchError, mapTo, switchMap, skipUntil, skipWhile } from 'rxjs/operators';
import { ImagesService } from '../../services/images.service';
import { LoadedUserLocation, LoadUser, LoggedIn, Login, LoginError, LoginWithAnonymSession, Logout, RequestUserLocation, StartUserConnectionListener, UpdateUser, UploadedUserImage, UploadUserImage, UserConnected, UserDisconnected, UserImagesLoaded, UserLoaded } from '../actions/auth.actions';
import { AuthState } from '../reducers/auth.reducers';
import { AuthActionTypes } from '../actions/auth.actions';
import { currentUser, isLoggedIn, selectUID, selectUserCountry } from '../selectors/auth.selectors';
import { AuthService } from '../services/auth.service';
import { HelperService } from '../../services/helper.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { getCurrentRouteState } from '../../ngrx-store/router-store/router.selectors';
import { StoreRootState } from '../../ngrx-store/index.reducer';
import { selectRouteParams } from '../../ngrx-store/router-store/router.selectors';
import { event, merge } from 'jquery';
import { selectEvent, selectEventState, selectRoom } from '../../ngrx-store/event-store/event.selectors';
import { Default } from '../../ngrx-store/app-store/app.actions';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../../models/user';

@Injectable()
export class AuthEffects {

    // Effect cuando se inicia sesión
    login$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<Login>(AuthActionTypes.Login),
                withLatestFrom(this.store.pipe(select(isLoggedIn))),
                filter(([action, isLoggedIn]) => !isLoggedIn),
                mergeMap(([action, _isUserLoaded]) => from(this.authService.login(action.payload.email, action.payload.password))),
                withLatestFrom(this.store.pipe(select(selectRouteParams))),
                mergeMap(async([_user, params]) => {
                    let user    = JSON.parse(JSON.stringify(_user));
                    const event = await this.store.pipe(select(selectEvent), skipWhile(event => event == null)).pipe(take(1)).toPromise();
                    const { id, name } = params;

                    if (user && user.user && id && name && event) {
                        if(event.redirectTo){
                          this.router.navigate([id, name,event.redirectTo]);
                        }else{
                          this.router.navigate([id, name]);
                        }
                        return new LoggedIn({ user: user.user, loginType: "emailNPassword" });
                    }
                    else {
                        return new LoginError();
                    }
                }),
                catchError(async(error) => {
					const event = await this.store.pipe(select(selectEvent), skipWhile(event => event == null)).pipe(take(1)).toPromise();

					if(event && event.id == 75){
						this.helper.swalWarnign("Usuario no registrado", "Para registrarse por favor consulte este url https://eventual.meinscribo.cl/evento/105")
					}
					else{
						this.helper.swalError(error.code || "");
					}
                   
                    // return throwError(error);
					return new LoginError();
                })
            )
    );

    //Effect para cargar los datos del usuario apenas ingrese a la plataforma
    initialLoadUser$ = createEffect(() => {
        let currentUser = this.authService.getLoggedUser();
        let action = this.actions$.pipe(ofType<LoadUser>(AuthActionTypes.LoadUser));

        return zip(action, currentUser)
            .pipe(map(([action, _user]) => {
                if (_user) {
                    let user = JSON.parse(JSON.stringify(_user));
                    return new LoggedIn({ user, loginType: action.payload.loginType });
                } else {
                    if (action.payload.loginType == "open") {
                        return new LoginWithAnonymSession();
                    } else {
                        return new Logout();
                    }
                }
            }));
    });

    anonymLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoginWithAnonymSession>(AuthActionTypes.LoginWithAnonymSession),
            mergeMap(action => from(this.authService.registerAnonimSession())),
            withLatestFrom(this.store.pipe(select(selectEventState), skipWhile(state => (!state || !state.eventID)))),
            mergeMap(([user, eventState]) => {
                if (eventState.eventID)
                    return zip(of(JSON.parse(JSON.stringify(user))), from(this.authService.registerAnonymUserOnDB(user, eventState.eventID)));
                else throw "anonym-on-db.";
            }),
            map(([user]) => {
                // console.log("users", user);
                return new LoggedIn({ user, loginType: "open" });
            }),
            catchError((error) => {
                console.warn("Effect Error trying to login with anonymous session. Error: ", error);
                if (error == "anonym-on-db") {
                    this.authService.logout();
                }
                return EMPTY;
            })
        )
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Logout>(AuthActionTypes.Logout),
            mergeMap(action => from(this.authService.logout())),
            withLatestFrom(this.store.pipe(select(selectEventState))),
            tap({
                next: ([none, event]) => {
                    if (event) {
                        const { eventID, eventURLName } = event;
                        if (eventID && eventURLName && event.event) {
                            if (event.event.loginType == "emailNPassword")
                                this.router.navigate([eventID, eventURLName, 'login']);
                            else
                                this.router.navigate([eventID, eventURLName, 'logout']);
                        }
                    }
                    this.userService.offUserUpdater();
                }
            })
        ),
        {
            dispatch: false
        }
    );

    loadUser$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoggedIn>(AuthActionTypes.LoggedIn),
                withLatestFrom(this.store.pipe(select(selectUID))),
                filter(uid => !!uid),
                map(([action, uid]) => {
                    this.userService.initializeUserUpdater(uid);
                    return new StartUserConnectionListener();
                })
            )
    );

    requestUserLocation = createEffect(() => {
        return this.actions$.pipe(
            ofType<RequestUserLocation>(AuthActionTypes.RequestUserLocation),
            mergeMap((action) => from(this.helper.getUserLocalization())),
            map(location => new LoadedUserLocation({ country: location }))
        )
    })

    initializeUserConnected$ = createEffect(() => {
        const act$ = this.actions$.pipe(
            ofType<StartUserConnectionListener>(AuthActionTypes.StartUserConnectionListener),
            tap((action) => {
                this.authService.initializeConnectedStatus();
            })
        );

        return act$;
    }, { dispatch: false });

    onUserDisconnected$ = createEffect(() => {
        const user$ = this.store.pipe(select(currentUser));
        const country$ = this.store.pipe(select(selectUserCountry));
        return this.actions$.pipe(
            ofType<UserDisconnected>(AuthActionTypes.UserDisconnected),
            withLatestFrom(combineLatest([user$, country$])),
            tap(([action, [user, country]]) => {
                this.helper.swalDisconnection();
                // if (user != null && event != null)
                    // this.authService.setDisconnectedStatus(user);
            })
        );
    }, { dispatch: false }
    )

    onUserConnected$ = createEffect(() => {
        const event$ = this.store.pipe(select(selectEvent), skipWhile(event => event == null));
        const user$ = this.store.pipe(select(currentUser), skipWhile(user => user == null));
        const act$ = this.actions$.pipe(ofType<UserConnected>(AuthActionTypes.UserConnected));

        return zip(act$, event$, user$)
            .pipe(
                mergeMap(async([action, event, user]) => {
                    this.helper.swalClose();
                    if (user != null && event != null) {
                        return from(this.authService.setConnectedStatus(user, event));
                    }
                    return of(null);
                })
            );
    }, { dispatch: false });

    updateUserImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType<UploadUserImage>(AuthActionTypes.UploadUserImage),
            mergeMap((action) => combineLatest([of(action), from(this.imagesService.uploadImage(action.payload.path, action.payload.image))])),
            map(([action, path]) => {
                return new UploadedUserImage({ uid: action.payload.uid, imagePath: path });
            })
        );
    });

    updateUserImageDB$ = createEffect(() => {
        return this.actions$.pipe(
            ofType<UploadedUserImage>(AuthActionTypes.UploadedUserImage),
            withLatestFrom(this.store.pipe(select(selectEvent))),
            mergeMap(([action, event]) => {
                if (event)
                    return from(this.userService.updateUser(action.payload.uid, { image: action.payload.imagePath }));
                else
                    throw "El evento no se ha cargado, al actualizar la imagen de usuario.";
            }),
            map(() => {
                this.helper.swalSuccess("Se ha guardado la imagen correctamente", "");
            }),
            catchError(error => {
                console.warn("Ha ocurrido un error al guardar la imagen del usuario. Detalles: ", error);
                this.helper.swalError(error);
                return of(error);
            })
        );
    }, { dispatch: false });

    //PARA CARGAR IMAGENES DEL USUARIO
    loadUserImages$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType<UserLoaded>(AuthActionTypes.UserLoaded),
                filter(action => !!action.payload.user && !!action.payload.user.uid),
                withLatestFrom(this.store.pipe(select(selectRouteParams))),
                mergeMap(async ([action, params]) => {
                    let { id, name } = params;
                    if (id && name) {
                        let user: User = JSON.parse(JSON.stringify(action.payload.user));
                        if (user && user.image){
                            this.authService.setUserAddress(user);
                            user.image = await this.imagesService.getImage(user.image);
                        }
                        return user && user.events && user.events[id] ? user : null;
                    }
                    return null;
                }),
                map((user) => user ? new UserImagesLoaded({ user }) : new LoginError())
            );
    });

    updateUser$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType<UpdateUser>(AuthActionTypes.UpdateUser),
                withLatestFrom(this.store.pipe(select(selectEvent))),
                mergeMap(async ([action, event]) => {
                    let user = JSON.parse(JSON.stringify(action.payload.user));
                    if (user && event) {
                        await this.userService.updateUser(action.payload.uid, action.payload.user);
                        this.helper.swalSuccess("Se ha actualizado el usuario correctamente", "");
                    }
                    return user;
                }),
                /* map((user) => user ? new UpdatedUser() : new LoginError()) */
            );
    }, { dispatch: false });

    loginError$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoginError>(AuthActionTypes.LoginError),
            map((action) => new Logout())
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<StoreRootState>,
        private imagesService: ImagesService,
        private authService: AuthService,
        private userService: UserService,
        private helper: HelperService,
        private router: Router,
        private auth: AngularFireAuth,
    ) {
    }

}
