export const MESSAGE_STATUS = {
	sent    : "SENT",
	seen    : "SEEN",
	initial : "INITIAL",
}

export const DAYSDIFF = {
	today              : 0,
	lastWeek           : 7,
	yesterday          : 1,
	dayBeforeYesterday : 2,
}

export const DEFAULT_PROFILE_IMG = "https://cdn.imgbin.com/10/20/11/imgbin-avatar-user-profile-icon-women-wear-frock-GNLBV4N6qanFpjEYMEqxEgcCd.jpg";
