<section *ngIf="friend && chat && chatStatus">
    <div class="contact-profile" (click)="navigateToFriendProfile()">
        <img [src]="friend.image ? friend.image : 'https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png'" alt="" />
        <p>{{ friend.name }} {{friend.lastName}} </p>
    </div>
    <div class="messages">
        <div>
            <ul>
                <li *ngFor="let msg of messages" [ngClass]="{'sent': msg.user_id === currentUser.uid, 'replies' : !(msg.user_id === currentUser.uid) && msg.user_id === friend.uid }">
                    <ng-container [ngTemplateOutlet]="listMsgs" [ngTemplateOutletContext]="{msg : msg}"></ng-container>
                </li>
            </ul>
        </div>
    </div>
    <div class="message-input">
        <div class="wrap">
            <div class="send-message-container">
                <input type="text" placeholder=" {{'chat.labelTextChat' | translate}} " #inputEmoji [(ngModel)]="message.message" (keyup.enter)="sendMessage()" />
                <div class="emoji-section">
                    <button mat-icon-button class="emoji-message" (click)="toggleEmoji()">
                        <mat-icon>emoji_emotions</mat-icon>
                    </button>
                    <div style="z-index: 1;" [style]="display">
                        <emoji-mart class="emoji-container" [darkMode]="false" [color]="'#007bff'" [set]="'twitter'" [showPreview]="false" (emojiClick)="addEmoji($event, inputEmoji)"></emoji-mart>
                    </div>
                </div>
            </div>

            <button class="send-message-button" (click)="sendMessage()">
                <mat-icon>send</mat-icon>
            </button>
        </div>
    </div>
</section>










































<section class="container-acept-chat" *ngIf="!chatStatus">
    <div class="card shadow" style="width: 24rem;">
        <div class="card-header">
            <div class="row align-items-center text-center">
                <div class="col-2">
                    <mat-icon style="font-size: 3em;">receipt_long</mat-icon>
                </div>
                <div class="col-10">
                    <h3> {{'chat.termsConditions' | translate}} </h3>
                    <small> {{'chat.termsConditionsMsg' | translate}} </small>
                    <small> {{'chat.request' | translate}} </small>
                </div>
            </div>
        </div>
        <div class="card-body">
            <p class="card-text">
                Términos y condiciones del Uso del Chat Tenga en cuenta las siguientes condiciones para el uso del chat: El servicio de Chat tiene como propósito optimizar la comunicación directa con los usuarios y clientes que residen en cualquier parte del mundo. Al
                acceder al servicio de chat, el usuario declara conocer y aceptar los términos y condiciones generales, y para el uso del correo electrónico, la política de privacidad, y las reglas de uso que expresadas en este documento. Magix no se
                responsabiliza por las opiniones y comentarios emitidos por los participantes en el chat; su contenido es única y exclusiva responsabilidad civil y penal de quien lo envió. Magix se reserva el derecho de retirar en cualquier momento, y
                a su criterio, los mensajes que vayan en contra de las reglas del chat o de la legislación vigente. Todo usuario del servicio de chat se compromete a: Mantener una conducta decente, y de respeto mutuo cuando utiliza este servicio. No usar
                lenguaje ofensivo, calumnioso u ofensivo hacia los otros usuarios de este servicio. No violar las leyes o reglamentos vigentes. No publicar mensajes comerciales. No enviar repetida e indiscriminadamente mensajes en el cuarto de chat, que
                puedan provocar una degradación del servicio. Magix no se hace responsable por los daños que provengan del uso de este servicio. Recomendaciones al usuario antes de acceder el servicio de chat: Evite dar información privada a las personas
                que ha conocido en el chat. Sea consciente de que puede ser objeto de mensajes indeseados por parte de otros usuarios en el chat. Se sugiere ignorarlos y notificar este tipo de irregularidades al administrador del chat. La velocidad de
                conexión al servidor de chat depende en gran parte de la velocidad de conexión de su proveedor de acceso Internet. Nunca y bajo ningún caso ningún administrador, moderador o miembro le solicitará la contraseña de su registro, por lo tanto,
                cualquier petición en ese sentido es falsa. Horarios: Los horarios serán establecidos por los encargados de cada evento Es importante indicar que estos espacios virtuales no son canales para ingresar sugerencias, quejas y felicitaciones.
                Si desea radicar una sugerencia, queja y/o felicitación puede hacerlo a través del correo electrónico comunicado en la plataforma Por razones de seguridad las conversaciones realizadas por el chat serán guardadas. Está prohibido el uso
                del chat para fines publicitarios y comerciales, por ser este una herramienta informativa de los productos y servicios. Está restringido colocar cualquier tipo de vínculos a distintas páginas web, imágenes, virus o correo basura (spam)
                en el chat. En caso de que el usuario del chat no cumpla con las reglas de comportamiento y uso de vocabulario decente, el moderador podrá bloquear su participación. Por razones de seguridad, el usuario del chat no puede suministrar información
                delicada personal ni confidencial.
            </p>
        </div>
        <div class="card-footer d-flex justify-content-evenly">
            <button type="button" class="btn btn-outline-danger" (click)="navigateToChat()"> {{'chat.decline' | translate}} </button>
            <button type="button" class="btn btn-primary" (click)="confirmChat()"> {{'chat.accept' | translate}} </button>
        </div>
    </div>
</section>

<ng-template #listMsgs let-msg='msg'>
    <div class="message-container">
        <img [src]="currentUser.image ? currentUser.image : 'https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png'" alt="" *ngIf="msg.user_id === currentUser.uid" />
        <img [src]="friend.image ? friend.image : 'https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png'" alt="" *ngIf="!(msg.user_id === currentUser.uid) && msg.user_id === friend.uid" />
        <p>{{msg.message}}</p>
    </div>
</ng-template>