import { User } from 'src/app/core/models/user';
import { FormGroup, FormControl } from '@angular/forms';
import { NetworkingChatInterface } from 'src/app/core/models/networking.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  	selector    : 'networking-left-section',
  	templateUrl : './left.component.html',
  	styleUrls   : [
		'./left.component.scss',
		'./dynamicStyles/search.component.scss',
		'./dynamicStyles/profile.component.scss',
		'./dynamicStyles/contacts.component.scss',
	],
})

export class NetworkingLeftSectionComponent {
	@Output() chat          : EventEmitter<NetworkingChatInterface> = new EventEmitter();
	@Output() scroll        : EventEmitter<string>                  = new EventEmitter();
	@Output() friendDeleted : EventEmitter<string>                  = new EventEmitter();

	@Input() contacts       : User[] | null = [];
	@Input() currentUser    : User   | null = null;
	@Input() defaultImage   : string      = "";

	showAttendees = false;
	form          = new FormGroup({
		search : new FormControl()
	});

	constructor(){}

	getAttendeesSectionClass() : string{
		return this.showAttendees ? "slide-in" : "slide-out";
	}

	showOverside(status: boolean) {
		this.showAttendees = status;
		let element = document.getElementById('over-left-side');
		if (element && !element.classList.contains('checked') && status) {
			element.classList.add("checked");
		}
	}

}
