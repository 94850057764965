<div id="profile-img-section">
	<div id="profile-img-section__container">
		<div id="profile-img-section__container-wrapper" (click)="navigateToProfile()">
			<img [src]="user.image || defaultImage" alt="" *ngIf="currentUser as user">
			<img [src]="defaultImage"               alt="" *ngIf="!currentUser">
		</div>
	</div>
</div>


<div id="profile-actions-section">
	<div id="profile-actions-section__container">

		<button mat-icon-button (click)="showAttendees.emit()">
			<mat-icon>chat</mat-icon>
		</button>

		<button mat-icon-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>

	</div>
</div>


<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item (click)="navigateToProfile()">
    <span>Perfil</span>
  </button>
</mat-menu>


<ng-template #loaderBlock>
    <app-loader></app-loader>
</ng-template>
