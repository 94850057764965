<aside id="right-side-container">
	<div class="top-container">
		<div class="top-bar">
			<mat-icon (click)="hideFriend.emit(false)" style="cursor: pointer;">close</mat-icon>
			<p>Info. del contacto</p>
		</div>
	</div>
	
	<div class="profile-container">
		<img [src]="friend.image" alt="Profile image">
		<div>
			<h5> {{friend.name}} </h5>
			<p> {{friend.description}} </p>
			<small> {{friend.email}} </small>
		</div>
	</div>
</aside>