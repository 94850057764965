import { User } from 'src/app/core/models/user';
import { Observable } from 'rxjs';
import { NetworkingService } from 'src/app/core/services/networking/networking.service';
import { NetworkingInterface } from 'src/app/core/models/networking.interface';
import { NetworkingInitChatInterface } from 'src/app/core/models/networking.interface';
import { NetworkingChatMessageInterface } from 'src/app/core/models/networking.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector    : 'networking-center-section',
	templateUrl : './center.component.html',
	styleUrls   : [
		'./center.component.scss',
		'./dynamicStyles/chat.component.scss',
		'./dynamicStyles/sender.component.scss',
		'./dynamicStyles/profile.component.scss'
	],
})

export class NetworkingCenterSectionComponent {
	@Output() showFriend : EventEmitter<boolean> = new EventEmitter();
	@Output() showChats  : EventEmitter<boolean> = new EventEmitter();
	
	@Input() currentUser : User | null = null;

	
	@Input() set chat( value : NetworkingInitChatInterface | null){
		if(value){
			if( !this.friend ){
				this.friend         = value.friend;
				this.usersMembersID = value.usersMembersID;
				this.retrieveChat();
			}
			else if( value.friend.uid != this.friend.uid ){
				this.friend         = value.friend;
				this.usersMembersID = value.usersMembersID;
				this.retrieveChat();
			}else{
				this.friend = value.friend;
			}
		}
	}
	
	friend!          : User;
	friendChat!      : NetworkingInterface;
	chatMessages!    : Observable<NetworkingChatMessageInterface[]>; 
	usersMembersID!  : string;
	toggleViewValue! : boolean;

	constructor(
		private networkingService : NetworkingService
	){}

	async retrieveChat(){
		let chatResponse = await this.networkingService.retrieveChatByMembersUID(this.usersMembersID);
		
		if(chatResponse){
			this.friendChat   = chatResponse;
			this.chatMessages = this.networkingService.retrieveChatMessages( this.friendChat.id );
		}
	}

}