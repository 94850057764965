import { NetworkingContactLastMessageInformation } from './networking.interface';
export class User {
	public loginType?: "open" | "emailNPassword";
	public uid: string;
	public email: string;
	public name: string;
	public summary?: string;
	public ban?: boolean;
	public password?: string;
	public type?: "user" | "speaker" | "moderator" | "admin" | string;
	public lastName?       : string;
	public lastSeen?       : number;
	public lastSeenStatus? : boolean;
	public pending_chat? : string[];
	public status?: {
		country: string,
		room: string,
		last_changed: number,
		state: boolean,
		event: string
	}
	public events?: {
		[eventID: string]: Array<string>
	};
	public timers?: Array<any>;
	public friends?:string[];
	public tyc?:boolean;
	public openChats?:string[];
	public image?: string;
	public job?: string;
	public description?: string;
	public city?: string;
	public phone?: string;
	public country?: string;
	public pdf?: string;
	public profileBackground?: string;
	public time_zone? : string;
	public lastMessage? : NetworkingContactLastMessageInformation;
	schedules? : SchedulesInterface[];
	appointments? : AppointmentInterface[];
	constructor() {
		this.loginType = "emailNPassword";
		this.uid = "";
		this.email = '';
		this.name = '';
	}
}
export interface SchedulesInterface{
  timezone : string;
  start_at : number;
  end_at   : number;
  title    : string;
  color    : string;
  id       : string;
}

export interface AppointmentInterface{
  id?   : string,
  topic : string,
  user  : {
    name  : string,
    email : string,
    phone : string
  },
  timezone    : string,
  date        : number,
  schedule_id : string,
}

export type UserInterface = User;


export interface RegisterUser {
  email: string,
  password: string
}

export interface RemoveUser {
  email: string
}

export interface heartBeatInterface{
  last_changed : number,
  event        : number | string
}


export interface scheduleFullCalendarInterface {
  end             : string,
  title           : string,
  start           : string,
  backgroundColor : string,
  extendedProps   : {
    id : string
  }
}

export class ScheduleClass implements SchedulesInterface{
  start_at = 0;
  end_at   = 0;
  title    = '';
  color    = '';
  id       = '';
  timezone = '';
}

export class UserAddressClass implements UserAddressInterface {
  public country = "Privado";
  public city    = "Privado";
  constructor(){

  }
}

export interface UserAddressInterface {
  country : string;
  city    : string;
}
