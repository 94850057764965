// 1 - Importaciones
import { Subscription } from 'rxjs';
import { ActionReducer } from '@ngrx/store';
// import { RegisterForm } from '../../models/RegisterForm';
import { FormActions, FormActionsTypes } from './form.actions';
// import { Calendar } from '../../models/Calendar';

export interface FormState {
    form: any | null;
    formNotFound: boolean;
    formIsLoading: boolean;
    formImagesIsLoading: boolean;
    calendar: any | null;
    uploadingUserIsLoading: boolean;
    EmailSent: boolean;
}



const initialState: FormState = {
    form: null,
    formNotFound: false,
    formIsLoading: false,
    formImagesIsLoading: false,
    calendar: null,
    uploadingUserIsLoading: false,
    EmailSent: false,
}

export const formReducer: ActionReducer<FormState, FormActions> = function (state = initialState, action) {
    switch (action.type) {
        case FormActionsTypes.LoadForm:
            return { ...state, formIsLoading: true };
        case FormActionsTypes.LoadedForm:
            return { ...state, form: action.payload.form, formIsLoading: false };
        case FormActionsTypes.FormNotFound:
            return { ...state, formNotFound: true, formIsLoading: false };
        case FormActionsTypes.LoadFormImages:
            return { ...state, formImagesIsLoading: true };
        case FormActionsTypes.LoadedFormImages:
            return { ...state, form: action.payload.form, formImagesIsLoading: false };
        case FormActionsTypes.UploadUser:
            return { ...state, uploadingUserIsLoading: true };
        case FormActionsTypes.UploadedUser:
            return { ...state, uploadingUserIsLoading: false};
        case FormActionsTypes.EmailSent:
            return { ...state, EmailSent:true};
        default:
            return state;
    }
}