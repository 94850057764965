import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map, shareReplay, mergeMap, take } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { Conference } from '../models/conferences/conference';
import { FilesService } from './files.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root'
})
export class SponsorService {

	BASE_PATH = "conferences";

	constructor(
		private database: AngularFireDatabase,
		private errorServ: ErrorService,
		private firestore: AngularFirestore,
		private fileService: FilesService,

	) { }

	async getSponsors(event_id: number) {
		let sponsors = await this.database.list<Conference>(this.BASE_PATH, ref => ref.orderByChild('eventID').equalTo(event_id))
											.valueChanges()
											.pipe(
												take(1),
												map(conferences => conferences.filter(conference => conference.sponsors && conference.sponsorsCategories)),
												map(conferences => conferences.map(async (conference) => {
													let sponsors = [];
													for (let i = 0; i < conference.sponsors.length; i++) {
														if(!conference.sponsors[i].hidden){
															conference.sponsors[i].logo = await this.fileService.getDownloadLink(conference.sponsors[i].logo);
															let sponsor    = conference.sponsors[i];
															let categories = conference.sponsorsCategories.filter(category => conference.sponsors[i].category == category.name);
															sponsors.push({
																sponsor, category: categories[0]
															});
														}													
													}
													return sponsors;
												}))
											)
											.toPromise();

		if (sponsors.length > 0) {
			return sponsors[0];
		} else {
			return [];
		}
	}

	async getFirestoreSponsors(event_fid: string) {
		let result = await this.firestore.collection(`conferences`).doc(event_fid).snapshotChanges().pipe(take(1)).toPromise();

		console.log(result.payload.data())
		// return Promise.all(
		//     result.docs.map(async (doc) => {
		//         let data = doc.data();
		//         if (data.image) {
		//             data.image = await this.fileService.getDownloadLink(data.image);
		//         }
		//         return data;
		//     })
		// );
	}


}
