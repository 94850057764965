// 1 - Importaciones
import { Action } from '@ngrx/store'
// import { User } from '../../models/user.model';
import firebase from 'firebase';
import { MagixEvent } from '../../models/event';
import { User } from '../../models/user';

// 2 - Definición los tipos de acciones
export enum AuthActionTypes {
    LoadUser = '[LoadUser] Action',
    Login = '[Login] Action',
    LoggedIn = '[LoggedIn] Action',
    UserRequested = '[UserRequested] Action',
    UserLoaded = '[UserLoaded] Action',
    UserImagesRequested = '[UserImagesRequested] Action',
    UserImagesLoaded = '[UserImagesLoaded] Action',
    Logout = '[Logout] Action',
    LoginError = '[LoginError] Action',
    UserConnected = '[UserConnected] Action',
    UserDisconnected = '[UserDisconnected] Action',
    StartUserConnectionListener = '[StartUserConnectionListener] Action',
    RequestUserLocation = '[RequestUserLocation] Action',
    LoadedUserLocation = '[LoadedUserLocation] Action',
    UploadUserImage = '[UploadUserImage] Action',
    UploadedUserImage = '[UploadedUserImage] Action',
    UpdateUser = '[UpdateUser] Action',
    LoginWithAnonymSession = '[LoginWithAnonymSession] Action'
}
export class LoadUser implements Action {
    readonly type = AuthActionTypes.LoadUser;
    constructor(public payload: { loginType: MagixEvent["loginType"] }) { }
}
export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { email: string, password: string }) { }
}
export class LoginWithAnonymSession implements Action {
    readonly type = AuthActionTypes.LoginWithAnonymSession;
}
export class LoggedIn implements Action {
    readonly type = AuthActionTypes.LoggedIn;
    constructor(public payload: { user: firebase.User, loginType: MagixEvent["loginType"] }) { }
}
export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
    constructor(public payload: { uid: string }) { }
}
export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User | null }) { }
}
export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}
export class UserImagesRequested implements Action {
    readonly type = AuthActionTypes.UserImagesRequested;
    constructor(public payload: { user: User }) { }
}
export class UserImagesLoaded implements Action {
    readonly type = AuthActionTypes.UserImagesLoaded;
    constructor(public payload: { user: User }) { }
}
export class LoginError implements Action {
    readonly type = AuthActionTypes.LoginError;
    constructor(public payload?: { error: any }) { }
}

export class StartUserConnectionListener implements Action {
    readonly type = AuthActionTypes.StartUserConnectionListener;
}
export class UserConnected implements Action {
    readonly type = AuthActionTypes.UserConnected;
    constructor(public paylod: { connectedTime: number }) { }
}
export class UserDisconnected implements Action {
    readonly type = AuthActionTypes.UserDisconnected;
    constructor(public payload: { disconnectedTime: number }) { }
}
export class RequestUserLocation implements Action {
    readonly type = AuthActionTypes.RequestUserLocation;
}
export class LoadedUserLocation implements Action {
    readonly type = AuthActionTypes.LoadedUserLocation;
    constructor(public payload: { country: string }) { }
}
export class UploadUserImage implements Action {
    readonly type = AuthActionTypes.UploadUserImage;
    constructor(public payload: { uid: string, path: string, image: File | null }) { }
}
export class UploadedUserImage implements Action {
    readonly type = AuthActionTypes.UploadedUserImage;
    constructor(public payload: { uid: string, imagePath: string }) { }
}

export class UpdateUser implements Action {
    readonly type = AuthActionTypes.UpdateUser;
    constructor(public payload: { uid: string, user: User | any }) { }
}


export type AuthActions = Login |
    LoggedIn |
    UserRequested |
    UserLoaded |
    Logout |
    UserImagesRequested |
    UserImagesLoaded |
    LoginError |
    LoadUser |
    UserConnected |
    UserDisconnected |
    RequestUserLocation |
    LoadedUserLocation |
    UploadUserImage |
    LoginWithAnonymSession;