// Modulos
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// Componentes
import { LoaderComponent } from './loader/loader.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { MainBannerComponent } from './main-banner/main-banner.component';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import { GalleryModalComponent } from './gallery/gallery-modal/gallery-modal.component';
import { FormDatePickerComponent } from './form/inputs/datePicker/date.component';
import { SocialIconButtonsComponent } from './social-icon-buttons/social-icon-buttons.component';
import { FormInputsOutlineComponent } from './form/inputs/outline/outline.component';
import { UploadProfileImageComponent } from './upload-profile-image/upload-profile-image.component';
import { FormSelectsOutlineComponent } from './form/selects/outline/outline.component';
import { ChangeLanguageButtonComponent } from './change-language-button/change-language-button.component';
import { WhatsappSupportButtonComponent } from './whatsapp-support-button/whatsapp-support-button.component';
// Pipes
import { SafePipe } from 'src/app/core/pipes/safe.pipe';
import { ThanksForComingComponent } from './thanks-for-coming/thanks-for-coming.component';
import { TimestampToDateCurrentTZPipe } from 'src/app/core/pipes/timestamp-to-date-current-tz.pipe';
// Services
import { RouterModule } from '@angular/router';
import { HelperService } from 'src/app/core/services/helper.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

import { BootstrapInput } from './form/inputs/bootstrap/input/input.component';
import { BootstrapSelect } from './form/inputs/bootstrap/select/select.component';
import { StandDialogVideo } from '../modules/stands/dialogs/videos/videos.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StandDialogGallery } from '../modules/stands/dialogs/gallery/gallery.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormCheckboxComponent } from './form/checkbox/checkbox.component';
import { FormDropZoneComponent } from './form/dropzone/dropzone.component';
import { ImageDropZoneComponent } from './dropzone-image/dropzone.component';
import { FormColorPickerComponent } from './form/inputs/colorPicker/color.picker.component';
import { CustomFormInputComponent } from './form/inputs/inputs/input.component';
import { FormTextAreaOutlineComponent } from './form/textArea/outline/outline.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomMaterialInputComponent } from './material/input/input.component';
import { CustomMaterialErrorsComponent } from './material/errors/errors.component';
import { CustomMaterialSelectComponent } from './material/select/select.component';
import { TranslateModule, TranslateStore } from '@ngx-translate/core';
import { CustomMaterialTextAreaComponent } from './material/textArea/text.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RutInputComponent } from './form/inputs/bootstrap/rut-input/rut-input.component';
@NgModule({
    declarations: [
        // COMPONENTS
        SafePipe,
        BootstrapInput,
        LoaderComponent,
        BootstrapSelect,
        GalleryComponent,
        StandDialogVideo,
        StandDialogGallery,
        ThumbnailComponent,
        MainBannerComponent,
        InputErrorsComponent,
        FormDropZoneComponent,
        FormCheckboxComponent,
        GalleryModalComponent,
        ImageDropZoneComponent,
        FormDatePickerComponent,
        CustomFormInputComponent,
        FormColorPickerComponent,
        ThanksForComingComponent,
        SocialIconButtonsComponent,
        FormInputsOutlineComponent,
        UploadProfileImageComponent,
        FormSelectsOutlineComponent,
        CustomMaterialInputComponent,
        FormTextAreaOutlineComponent,
        TimestampToDateCurrentTZPipe,
        CustomMaterialSelectComponent,
        ChangeLanguageButtonComponent,
        CustomMaterialErrorsComponent,
        WhatsappSupportButtonComponent,
        CustomMaterialTextAreaComponent,
        RutInputComponent,
    ],
    imports: [
        FormsModule,
        RouterModule,
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatButtonModule,
        TranslateModule,
        HttpClientModule,
        NgxSpinnerModule,
        ColorPickerModule,
        MatCheckboxModule,
        NgxDropzoneModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        // COMPONENTS
        SafePipe,
        MatInputModule,
        BootstrapInput,
        RutInputComponent,
        TranslateModule,
        BootstrapSelect,
        LoaderComponent,
        HttpClientModule,
        GalleryComponent,
        ThumbnailComponent,
        NgxSpinnerModule,
        ReactiveFormsModule,
        MainBannerComponent,
        InputErrorsComponent,
        FormDropZoneComponent,
        FormCheckboxComponent,
        GalleryModalComponent,
        ImageDropZoneComponent,
        FormDatePickerComponent,
        CustomFormInputComponent,
        FormColorPickerComponent,
        MatProgressSpinnerModule,
        SocialIconButtonsComponent,
        FormInputsOutlineComponent,
        FormSelectsOutlineComponent,
        UploadProfileImageComponent,
        CustomMaterialInputComponent,
        TimestampToDateCurrentTZPipe,
        FormTextAreaOutlineComponent,
        CustomMaterialErrorsComponent,
        ChangeLanguageButtonComponent,
        CustomMaterialSelectComponent,
        WhatsappSupportButtonComponent,
        CustomMaterialTextAreaComponent,
    ],
    providers: [
        HelperService,
        TranslateStore
    ]
})
export class CommonComponentsModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
