<main id="center-container">
    <networking-profile-center-section  id="center-topbar" 
										[friend]="friend"
										(showFriend)="showFriend.emit(true)" 
                                        (showChats)="showChats.emit(false)"

	></networking-profile-center-section>
    <networking-chat-center-section   id="center-chat"
									  [chatFriend]="friend"
									  [chatMessages]="chatMessages | async"

	>
	</networking-chat-center-section>
    <networking-sender-center-section id="center-sender"
									  [chat]="friendChat"
									  [friend]="friend"
									  [currentUser]="currentUser"></networking-sender-center-section>
</main>