import { User } from 'src/app/core/models/user';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector    : 'networking-profile-left-section',
	styleUrls   : ['./profile.component.scss'],
	templateUrl : './profile.component.html',
})

export class NetworkingProfileLeftSectionComponent {

	@Output() showAttendees : EventEmitter<void> = new EventEmitter();
	@Input() currentUser    : User | null = null;
	@Input() defaultImage   : string = "";

	constructor(
		private router         : Router,
		private activatedRoute : ActivatedRoute
	){}

	navigateToProfile(){
		let event_id   = this.activatedRoute.snapshot.paramMap.get('id');
		let event_name = this.activatedRoute.snapshot.paramMap.get('name');

		this.router.navigate([`${event_id}/${event_name}/account`]);
	}

}
