import { NgModule } from '@angular/core';
import { BaseComponent } from './views/theme/base/base.component';
import { WelcomeComponent } from './views/modules/welcome/welcome.component';
import { NotFoundComponent } from './views/common/not-found/not-found.component';
import { AttendeesComponent } from './views/modules/attendees/attendees.component';
import { CustomChatComponent } from './views/modules/chats/chat.component';
import { NetworkingComponent } from './views/modules/networking/networking.component';
import { CertificatesComponent } from './views/modules/certificates/certificates.component';
import { ThanksForComingComponent } from './views/common/thanks-for-coming/thanks-for-coming.component';
import { CustomPrivateChatComponent } from './views/modules/chats/private/private.component';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import {
	AngularFireAuthGuard,
	redirectUnauthorizedTo,
	redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { HasCustomDomainGuard } from './guards/domains.guard';

const redirectLoggedInToHome = (data: ActivatedRouteSnapshot) => {
	const { id, name } = data.params;
	redirectLoggedInTo([`/${id}/${name}/home`]);
};
const redirectUnauthorizedToLogin = (data: ActivatedRouteSnapshot) => {
	const { id, name } = data.params;
	return redirectUnauthorizedTo([
		!id || !name ? `404` : `${id}/${name}/login`,
	]);
};

const routes: Routes = [
	// {
	// 	path: '', redirectTo: '404', pathMatch: 'full'
	// },
	{
		path: '', pathMatch: 'full', redirectTo: '/72/red-municipios-ninez/login'
	},
	{
		path: ':id/:name/login',
		loadChildren: () =>
			import('./views/modules/auth/auth.module').then(
				(m) => m.AuthModule
			),
		data: { authGuardPipe: redirectLoggedInToHome },
	},
	{
		path: ':id/:name/certificate/:certifyId',
		component: CertificatesComponent,
		data: { animation: 'Certificates' },
	},
	{
		path: ':id/:name/welcome',
		component: WelcomeComponent,
		data: { animation: 'Welcome' },
	},
	{
		path: ':id/:name/form',
		children: [
			{
				path: ':fid',
				loadChildren: () =>
					import('./views/modules/auth/form/form.module').then(
						(m) => m.FormModule
					),
				data: { animation: 'Form' },
			}
		],
		data: { animation: 'Form' },
	},
	{
		path: ':id/:name',
		component: BaseComponent,
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
		children: [
			{
				path: 'home',
				loadChildren: () =>
					import('./views/modules/home/home.module').then(
						(m) => m.HomeModule
					),
				data: { animation: 'Home' },
			},
			{
				path: 'stands',
				loadChildren: () =>
					import('./views/modules/stands/stands.module').then(
						(m) => m.StandsModule
					),
				data: { animation: 'Stands' },
			},
			{
				path: 'live',
				loadChildren: () =>
					import('./views/modules/event/event.module').then(
						(m) => m.EventModule
					),
				data: { animation: 'Live' },
			},
			{
				path: 'on-demand',
				loadChildren: () =>
					import('./views/modules/on-demand/on-demand.module').then(
						(m) => m.OnDemandModule
					),
				data: { animation: 'OnDemand' },
			},
			{
				path: 'account',
				loadChildren: () =>
					import('./views/modules/user/user.module').then(
						(m) => m.UserModule
					),
			},
			{
				path: 'break-out-rooms',
				loadChildren: () =>
					import(
						'./views/modules/break-out-rooms/break-out-rooms.module'
					).then((m) => m.BreakOutRoomsModule),
				data: { animation: 'BreakoutRooms' },
			},
			{
				path: 'chats',
				component: CustomChatComponent,
				children: [
					{ path: ':chat', component: CustomPrivateChatComponent },
				],
				data: { animation: 'Chats' },
			},
			{
				path: 'certificates',
				component: CertificatesComponent,
				data: { animation: 'Certificates' },
			},
			{
				path: 'speaker',
				loadChildren: () =>
					import('./views/modules/speakers/speakers.module').then(
						(m) => m.SpeakersModule
					),
				data: { animation: 'Speakers' },
			},
			{
				path: 'attendees',
				component: AttendeesComponent,
				data: { animation: 'Attendees' },
			},
			{
				path: 'attendees/:attender',
				component: AttendeesComponent,
				data: { animation: 'Attendees' },
			},
			{
				path: 'networking',
				component: NetworkingComponent,
				data: { animation: 'Networking' },
			},
			{
				path: '**',
				redirectTo: 'home',
			},
		],
	},
	{
		path: ':id/:name/logout',
		component: ThanksForComingComponent,
	},
	{
		path: '404',
		component: NotFoundComponent
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			paramsInheritanceStrategy: 'always',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
