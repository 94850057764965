import { Injectable } from '@angular/core';
// Services
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class WelcomeService {

    BASE_PATH: string = '/welcome';

    constructor(
        private firestore: AngularFirestore,
    ) { }

    getEditor(doc_id: string) {
        return this.firestore.collection(this.BASE_PATH).doc(doc_id).snapshotChanges();
    }
}