import * as uuid from 'uuid';
import { Injectable         } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { take               } from 'rxjs/operators';
//Servicios
import { ErrorService } from './error.service';
import { HelperService } from './helper.service';

@Injectable({ providedIn: 'root' })
export class FilesService {

  	BASE_FOLDER: string = '/content';

	constructor(
		private storageService: AngularFireStorage,
		private alerts: HelperService,
		private errorServ : ErrorService
	) { }

	async upload(file: File): Promise<string> {
		let extension = file.name.split('.').pop();
		let path      = `${uuid.v4()}.${extension}`;
		try {
			await this.storageService.upload(`${this.BASE_FOLDER}/${path}`, file);
			return `${this.BASE_FOLDER}/${path}`;
		} catch (error : any) {
			this.errorServ.handleErrors(`Ha ocurrido un error al intentar subir el archivo. Detalles: `,error);
			throw error;
		}
	}

	async getDownloadLink(path : string) : Promise<string> {
		let link : string = '';
		try {
			link = await this.storageService.ref(`${path}`).getDownloadURL().pipe(take(1)).toPromise();
		} catch (error : any) {
			this.errorServ.handleErrors(`Archivo: ${path} no existe!.`,error);
		}
		return link;
	}

	remove(path: string): void {
		try {
			this.storageService.ref(path).delete().subscribe(
			() => {
				this.alerts.swalSuccess('Exito', 'Se ha eliminado el archivo con exito.');
			},
			error => {
				this.errorServ.handleErrors(`Archivo: ${path} no existe!`,error);
			}
			)
		} catch (error : any) {
			this.errorServ.handleErrors(`Ha ocurrido un error al intentar eliminar el archivo. Path de consulta: ${path}. Detalles: `,error);
		}
	}
}