<aside class="over-left-side" [formGroup]="form">
	<header class="over-left-side-header">
		<div class="over-left-side-header-container">

			<div class="over-left-side-header-icon">
				<button mat-icon-button (click)="hideAttendees.emit()">
					<mat-icon>arrow_back</mat-icon>
				</button>
			</div>

			<div class="over-left-side-header-title">
				<span>Nuevo chat</span>
			</div>

		</div>
	</header>

	<networking-search-left-section
									class="over-left-side-searcher"
									placeholder="Buscar contactos"
									formControlName="search"
	></networking-search-left-section>

	<networking-attendees-list-left-section class="over-left-side-list"
											[search]="this.form.controls.search.value"
											[currentUser]="currentUser"
											(chat)="chat.emit($event)"
 	></networking-attendees-list-left-section>

</aside>
