import { skipWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { selectEvent } from 'src/app/core/ngrx-store/event-store/event.selectors';
import { Component, OnDestroy, OnInit } from '@angular/core';
// Interfaces
import { User } from 'src/app/core/models/user';
import { MagixEvent } from 'src/app/core/models/event';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';

@Component({
	templateUrl: './attendees.component.html',
	styleUrls: ['./attendees.component.scss']
})

export class AttendeesComponent implements OnDestroy {
	// Variables globales
	event: MagixEvent | null = null;
	subscriptions: Subscription[] = [];
	/* @ts-ignore */
	selectedAttender: User;
	// Inicializar servicios
	constructor(
		private store: Store<StoreRootState>,
		private activeRoute: ActivatedRoute,
		private userServ: UserService
	) {
		this.activeRoute.paramMap.subscribe((params: ParamMap) => {
			this.subscriptions.push(
				this.store.pipe(
					select(selectEvent),
					skipWhile(event => !event)
				).subscribe(response => {

					if (response && response.tabs) {
						// window.document.title = response.tabs.attendees;
					}

					if (response) {
						this.event = response;
					}

					this.updateEvent.bind(this);
				})
			);
			let attender_uid = params.get('attender');
			if (attender_uid) {
				this.getUser(attender_uid);
			}
		});
	}

	private async getUser(user_uid: string) {
		let response = await this.userServ.getWithImg(user_uid);
		// @ts-ignore
		if (response != false && response.events[this.event.id]) {
			this.selectedAttender = response;
		}
	}

	private updateEvent(event: MagixEvent | null) {
		if (event) {
			this.event = event;
			// @ts-ignore
			document.documentElement.style.setProperty('--attendes_bg', this.event.colors.backgrounds.attendees);
		}
	}

	showUser(user: User) {
		this.selectedAttender = user;
	}

	//Eliminar las destrucciones
	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
