import { Component } from '@angular/core';
import { slideInAnimation } from "../../../route-animation";

@Component({
	selector: 'app-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	animations: [slideInAnimation]
})

export class BaseComponent {

	constructor() { }

}
