import { User } from 'src/app/core/models/user';
import { Component, Input } from '@angular/core';
import { NetworkingMessageInterface } from 'src/app/core/models/networking.interface';
import { NetworkingChatMessageInterface } from '../../../../../../core/models/networking.interface';
import moment from 'moment';

@Component({
	selector    : 'networking-chat-center-section',
	styleUrls   : ['./chat.component.scss'],
	templateUrl : './chat.component.html',
})

export class NetworkingChatCenterSectionComponent {
	@Input() set chatFriend( friend : User | null){
		this.friend = friend;
	}
	@Input() set chatMessages(messages : NetworkingChatMessageInterface[] | null ){
		if(messages){
			this.messages = messages;
		}
	}

	convertToLocalTime(timestamp: number):String{
		return moment(timestamp).format('L LT');
	}
	
	friend   : User | null = null;
	messages : NetworkingChatMessageInterface[] = [];

	constructor(){
		// @ts-ignore
		document.getElementById("vnew-support-button").style.display = "none";
	}

}
