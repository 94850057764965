// 1 - Importaciones
import { EventActionsTypes, EventActions } from './event.actions';
import { Subscription } from 'rxjs';
import { ActionReducer } from '@ngrx/store';
import { Room } from '../../models/conferences/room';
import { MagixEvent } from '../../models/event';

export interface EventState {
    event: MagixEvent | null;
    room: Room | null,
    eventID: number | null;
    eventURLName: string | null;
    eventLoadStatus: "not-exists" | "loaded" | "waiting-to-load",
    eventActiveSubscription: Subscription | null,
    loadedEventImages: boolean,
    loadingEventWelcome: boolean
}

const initialState: EventState = {
    event: null,
    room: null,
    eventID: null,
    eventURLName: null,
    eventLoadStatus: "waiting-to-load",
    eventActiveSubscription: null,
    loadedEventImages: false,
    loadingEventWelcome: false
}

// 3 - Switch con las funciones reducer
export const eventReducer: ActionReducer<EventState, EventActions> = function (state = initialState, action) {
    switch (action.type) {
        case EventActionsTypes.Default:
            return initialState;
        case EventActionsTypes.LoadEvent:
            return { ...state, eventID: Number(action.payload.eventID), eventURLName: action.payload.name };
        case EventActionsTypes.EventError:
            return { ...state, eventID: null, event: null, eventURLName: null, eventLoadStatus: "not-exists" };
        case EventActionsTypes.EventLoaded:
            return { ...state, event: action.payload.event, eventLoadStatus: "loaded" }
        case EventActionsTypes.EventListenerStarted:
            return { ...state, eventActiveSubscription: action.payload.subscription };
        case EventActionsTypes.LoadEventImagesURLS:
            return { ...state, loadedEventImages: false };
        case EventActionsTypes.LoadedEventImagesURLS:
            return { ...state, loadedEventImages: true, event: action.payload.event };
        case EventActionsTypes.SetEventData:
            return { ...state, eventID: action.payload.eventID };
        /*  case EventActionsTypes.ChangeCurrentRoom:
            return { ...state, room: action.payload.room }; */
        case EventActionsTypes.LoadEventWelcome:
            return { ...state, loadingEventWelcome: true };
        case EventActionsTypes.LoadedEventWelcome:
            return { ...state, loadingEventWelcome: false };
        default:
            return state;
    }
}