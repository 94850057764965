<mat-card class="mat-elevation-z3" *ngIf="user">
    <div class="expositor-profile-container">
        <div class="profile-image-container">
            <img [src]="user.image ? user.image : 'https://toppng.com/uploads/preview/file-svg-profile-icon-vector-11562942678pprjdh47a8.png'">
        </div>
        <div class="profile-data-container">
            <div class="col">
                <span class="profile-name">{{ user.name | uppercase}} {{user.lastName | uppercase}}</span>
                <span class="profile-email">{{user.type | scapeUser}} {{ user.job || '' }} </span>
            </div>
            <div class="col">
                <button mat-raised-button color="primary" (click)="sendMessage(user.uid)"> {{'attendees.btnSendMessage' | translate}} </button>
            </div>
        </div>
    </div>
    <div class="profile-details-container">
        <!-- Nombre completo -->
        <div>
            <span> {{'attendees.workstation' | translate}} </span>
            <span *ngIf="user.job != undefined">{{user.job | uppercase}}.</span>
        </div>
        <!-- Acerca de -->
        <div>
            <span> {{'attendees.about' | translate}} </span>
            <span *ngIf="user.description != undefined">{{user.description | uppercase}}.</span>
        </div>
        <!-- Resume -->
        <div>
            <span> {{'attendees.summary' | translate}} </span>
            <span *ngIf="user.summary != undefined">{{user.summary | uppercase}}.</span>
        </div>
    </div>
</mat-card>