
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Certificate } from "../models/certificate";
import { AngularFireDatabase } from '@angular/fire/database';
@Injectable({
  	providedIn: 'root'
})
export class CertificatesService {
	constructor(
		private database: AngularFireDatabase,
	) { }

	async getCertificate(ids: number[]) : Promise<Certificate[]> {
		let results = await Promise.all(
			ids.map( (id) => 
				this.database.list<Certificate>(`certificates`, (reference) => reference.orderByChild("id").equalTo(id))
							.valueChanges()
							.pipe(
								map( certificates => {
									if(certificates.length){
										return certificates[0];
									}else{
										return null;
									}
								}),
							    take(1)
							)
							.toPromise()
			)
		)
		// @ts-ignore
		return results.filter( result => result != null );
	}
 
}

