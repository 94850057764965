

<article id="chats-content">
	<div class="chat-background-color"></div>
    <section class="chat-background">

		<div *ngIf="friend">
			<div class="message contiguous" *ngFor="let message of messages" 
											[ngClass]="friend.uid == message.user_id ? 'message-in' : 'message-out'"
											[class.image] ="message.type == 'img'"
			>
				<div class="message-container">
					<!-- <ng-container [ngTemplateOutlet]="messageTailIn"  *ngIf="friend.uid == message.user_id"></ng-container>
					<ng-container [ngTemplateOutlet]="messageTailOut" *ngIf="friend.uid != message.user_id"></ng-container> -->

					<div class="message-text-container">
						<div class="message-text-wrapper">
							<ng-container [ngTemplateOutlet]="typeText"   [ngTemplateOutletContext]="{msg : message.message}" *ngIf="!message.type || message.type != 'img'"></ng-container>
							<ng-container [ngTemplateOutlet]="typeImage"  [ngTemplateOutletContext]="{msg : message.message}" *ngIf="message.type == 'img'"></ng-container>

							<div class="metadata-container">
								<div class="metadata-wrapper">
									<!-- <span class="timestamp">08-10-2021 11:48 a.m.</span> -->
									<span class="timestamp">{{ this.convertToLocalTime(message.sent_at) }}</span>
									<mat-icon>done_all</mat-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

    </section>
</article>


<ng-template #messageTailOut >
	<span class="message-tail">
		<svg viewBox="0 0 8 13" width="8" height="13" class="">
			<path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
			<path fill="currentColor" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
		</svg>
	</span>
</ng-template>
  
<ng-template #messageTailIn>
	<span class="message-tail">
		<svg viewBox="0 0 8 13" width="8" height="13" class="">
			<path opacity=".13" fill="#0000000" d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path>
			<path fill="currentColor" d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path>
		</svg>
	</span>
</ng-template>

<ng-template #typeImage let-msg="msg">
	<div class="message-image-data">
		<div class="message-image-container">
			<img [src]="msg" />
		</div>
		<div class="metadata-image-overlay"></div>
	</div>
</ng-template>

<ng-template #typeText let-msg="msg">
	<div class="message-text-data">
		<span>
			{{ msg }}
		</span>
		<span class="date-placeholder"></span>
	</div>
</ng-template>