import { Action } from '@ngrx/store'
import { Subscription } from 'rxjs';
import { Room } from '../../models/conferences/room';
import { MagixEvent } from '../../models/event';

export enum EventActionsTypes {
  Default = '[Default] Action',
  LoadEvent = '[LoadEvent] Action',
  EventLoaded = '[EventLoaded] Action',
  EventError = '[EventError] Action',
  EventListenerStarted = '[EventListenerStarted] Action',
  LoadEventImagesURLS = '[LoadEventImages] Action',
  LoadedEventImagesURLS = '[LoadedEventImagesURLS] Action',
  SetEventData = '[SetEventData] Action',
  StartPlayerCounter = '[StartPlayerCounter] Action',
  StopPlayerCounter = '[StopPlayerCounter] Action',
  StartedPlayerCounter = '[StartedPlayerCounter] Action',
  StoppedPlayerCounter = '[StoppedPlayerCounter] Action',
  ErrorPlayerCounter = '[ErrorPlayerCounter] Action',
  ChangeCurrentRoom = '[ChangeCurrentRoom] Action',
  NotFoundEvent = '[NotFoundEvent] Action',
  LoadEventWelcome = '[LoadEventWelcome] Action',
  LoadedEventWelcome = '[LoadedEventWelcome] Action',
  NotFoundEventWelcome = '[NotFoundEventWelcome] Action'
}

export class Default implements Action {
  readonly type = EventActionsTypes.Default
  constructor(public payload: any) { }
}

export class SetEventData implements Action {
  readonly type = EventActionsTypes.SetEventData;
  constructor(public payload: { eventID: number, name: string }) { }
}

export class NotFoundEvent implements Action {
  readonly type = EventActionsTypes.NotFoundEvent;
}
export class LoadEvent implements Action {
  readonly type = EventActionsTypes.LoadEvent
  constructor(public payload: { eventID: string | number, name: string }) { }
}
export class EventLoaded implements Action {
  readonly type = EventActionsTypes.EventLoaded;
  constructor(public payload: { event: MagixEvent, loadImages?: boolean }) { }
}
export class EventError implements Action {
  readonly type = EventActionsTypes.EventError;
  constructor() { }
}
export class EventListenerStarted implements Action {
  readonly type = EventActionsTypes.EventListenerStarted;
  constructor(public payload: { subscription: Subscription }) { }
} export class LoadEventImagesURLS implements Action {
  readonly type = EventActionsTypes.LoadEventImagesURLS;
  constructor(public payload: { event: MagixEvent }) { }
}
export class LoadedEventImagesURLS implements Action {
  readonly type = EventActionsTypes.LoadedEventImagesURLS;
  constructor(public payload: { event: MagixEvent }) { }
}
export class StartPlayerCounter implements Action {
  readonly type = EventActionsTypes.StartPlayerCounter;
  constructor(public payload: { event: MagixEvent, room: Room }) { }
}
export class StartedPlayerCounter implements Action {
  readonly type = EventActionsTypes.StartedPlayerCounter;
}
export class ErrorPlayerCounter implements Action {
  readonly type = EventActionsTypes.ErrorPlayerCounter;
}
export class StopPlayerCounter implements Action {
  readonly type = EventActionsTypes.StopPlayerCounter;
  constructor(public payload: { event: MagixEvent, room: Room }) { }
}
export class StoppedPlayerCounter implements Action {
  readonly type = EventActionsTypes.StoppedPlayerCounter;
}
export class ChangeCurrentRoom implements Action {
  readonly type = EventActionsTypes.ChangeCurrentRoom;
  constructor(public payload: { room: Room | null }) { }
}

export class LoadEventWelcome implements Action {
  readonly type = EventActionsTypes.LoadEventWelcome;
  constructor(public payload: { event: MagixEvent }) { }
}
export class LoadedEventWelcome implements Action {
  readonly type = EventActionsTypes.LoadedEventWelcome;
  constructor(public payload: { event: MagixEvent }) { }
}
export class NotFoundEventWelcome implements Action {
  readonly type = EventActionsTypes.NotFoundEventWelcome;
}

export type EventActions =
  Default |
  LoadEvent |
  EventLoaded |
  EventError |
  EventListenerStarted |
  LoadEventImagesURLS |
  LoadedEventImagesURLS |
  SetEventData |
  ChangeCurrentRoom |
  NotFoundEvent |
  LoadEventWelcome |
  LoadedEventWelcome;