import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
// Operadores
import { Observable } from 'rxjs';
import { filter, shareReplay, map, take } from 'rxjs/operators';
// Interfaces
import { User } from '../models/user';
import { FilesService } from './files.service';
// Servicios

@Injectable({ providedIn: 'root' })

export class AttendeesService {
    //Constante de la ubicacion en firebase
    BASE_PATH: string = '/users';
    //Inicializar servicios
    constructor(
        private database: AngularFireDatabase,
        private firestore: AngularFirestore,
        private fileService: FilesService
    ) { }
    //Obtener data con las imagenes
    getAll(event_id: number): Observable<User[]> {
        return this.database.list<User>(`${this.BASE_PATH}`).valueChanges()
            .pipe(
                shareReplay(1),
                map(attendees => {
                    return attendees.filter(attender => {
                        return (attender.events != undefined && attender.events[`${event_id}`] != undefined) && attender.type != 'expositor';
                    });
                })
            );
    }

    async getAttendeesIndex(eventID: number): Promise<string[]> {
        return this.database.object<{ [letter: string]: string }>(`indexes/users/${eventID}`).valueChanges().pipe(map(letters => {
            let result: string[] = [];
            for (let key in letters)
                result.push(letters[key]);
            return result;
        }), take(1)).toPromise();
    }

    async getAttendeesSummaryByField(eventID: number, text: string): Promise<User[]> {
        let result = await this.firestore.collection<User>("users",
            (ref) =>
                ref.where(`events`, "array-contains", eventID)
                    .where('name', ">=", text)
                    .where('name', '<=', text + '\uf8ff')
        ).get().pipe(take(1)).toPromise();
        return Promise.all(
            result.docs.map(async (doc) => {
                let data = doc.data();
                if (data.image) {
                    data.image = await this.fileService.getDownloadLink(data.image);
                }
                return data;
            })
        );
    }

    async getAttendeesSummaryByEvent(eventID: number): Promise<User[]> {
        let result = await this.firestore.collection<User>("users",
            (ref) => ref.where(`events`, "array-contains", eventID)
        ).get().pipe(take(1)).toPromise();
        return Promise.all(
            result.docs.map(async (doc) => {
                let data = doc.data();
                if (data.image) {
                    data.image = await this.fileService.getDownloadLink(data.image);
                }
                return data;
            })
        );
    }
}
