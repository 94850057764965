export const GlobalErrors: { [language: string]: { [error: string]: { title: string, description: string } } } = {
    "es-MX": {
        "auth/claims-too-large": {
            title: "auth/claims-too-large",
            description: "La carga útil de la reclamación que se entregó a setCustomUserClaims() supera el tamaño máximo de 1,000 bytes."
        },
        "auth/email-already-exists": {
            title: "El correo electrónico ya existe",
            description: "Otro usuario ya está utilizando el correo electrónico proporcionado. Cada usuario debe tener un correo electrónico único."
        },
        "auth/id-token-expired": {
            title: "Token de acceso expirado",
            description: "El token de ID de Firebase que se proporcionó está vencido."
        },
        "auth/id-token-revoked": {
            title: "Token de acceso revocado",
            description: "Se revocó el token de ID de Firebase."
        },
        "auth/insufficient-permission": {
            title: "No tienes suficientes permisos",
            description: "La credencial que se usó para inicializar el SDK de Admin no tiene permisos suficientes a fin de acceder al recurso de autenticación solicitado. Consulta cómo configurar un proyecto de Firebase para ver la documentación sobre cómo generar una credencial con los permisos apropiados y usarla en la autenticación de los SDK de Admin."
        },
        "auth/internal-error": {
            title: "Error interno",
            description: "El servidor de autenticación encontró un error inesperado cuando se intentaba procesar la solicitud. Para obtener información adicional, revisa la respuesta del servidor de autenticación, que debería estar incluida en el mensaje de error. Si el error persiste, avísanos mediante nuestro canal de asistencia de informe de errores."
        },
        "auth/invalid-argument": {
            title: "Correo electrónico invalido",
            description: "Se proporcionó un argumento no válido para un método de autenticación. El mensaje de error debe incluir información adicional."
        },
        "auth/invalid-claims": {
            title: "auth/invalid-claims",
            description: "Los atributos personalizados del reclamo que se entregaron a setCustomUserClaims() no son válidos."
        },
        "auth/invalid-continue-uri": {
            title: "auth/invalid-continue-uri",
            description: "La URL de continuación debe ser una string de URL válida."
        },
        "auth/invalid-creation-time": {
            title: "auth/invalid-creation-time",
            description: "La hora de creación debe ser una string de fecha en formato UTC válida."
        },
        "auth/invalid-credential": {
            title: "Credenciales invalidas",
            description: "La credencial que se usa en la autenticación de los SDK de Admin no se puede emplear para realizar la acción deseada. Algunos métodos de autenticación, como createCustomToken() y verifyIdToken(), requieren que los SDK se inicialicen con una credencial de certificado en lugar de un token de actualización o una credencial predeterminada de la aplicación. Consulta Inicializa el SDK para ver documentación sobre cómo autenticar el SDK de Admin con una credencial de certificado."
        },
        "auth/invalid-disabled-field": {
            title: "auth/invalid-disabled-field",
            description: "El valor que se proporcionó para la propiedad del usuario disabled no es válido. Debe ser un booleano."
        },
        "auth/invalid-display-name": {
            title: "Nombre invalido",
            description: "El valor que se proporcionó para la propiedad del usuario displayName no es válido. Debe ser una string que no esté vacía."
        },
        "auth/invalid-dynamic-link-domain": {
            title: "auth/invalid-dynamic-link-domain",
            description: "El dominio del vínculo dinámico proporcionado no se configuró o no se autorizó para el proyecto actual."
        },
        "auth/invalid-email": {
            title: "Correo electrónico invalido.",
            description: "El valor que se proporcionó para la propiedad del usuario email no es válido. Debe ser una dirección de correo electrónico de string."
        },
        "auth/invalid-email-verified": {
            title: "auth/invalid-email-verified",
            description: "El valor que se proporcionó para la propiedad del usuario emailVerified no es válido. Debe ser un booleano."
        },
        "auth/invalid-hash-algorithm": {
            title: "auth/invalid-hash-algorithm",
            description: "El algoritmo de hash debe coincidir con las strings de la lista de algoritmos compatibles."
        },
        "auth/invalid-hash-block-size": {
            title: "auth/invalid-hash-block-size",
            description: "El tamaño del conjunto de hash debe ser un número válido."
        },
        "auth/invalid-hash-derived-key-length": {
            title: "auth/invalid-hash-derived-key-length",
            description: "La longitud de la clave derivada de hash debe ser un número válido."
        },
        "auth/invalid-hash-key": {
            title: "auth/invalid-hash-key",
            description: "La clave de hash debe ser un búfer de bytes válido."
        },
        "auth/invalid-hash-memory-cost": {
            title: "auth/invalid-hash-memory-cost",
            description: "El costo de la memoria de hash debe ser un número válido."
        },
        "auth/invalid-hash-parallelization": {
            title: "auth/invalid-hash-parallelization",
            description: "La paralelización de hash debe ser un número válido."
        },
        "auth/invalid-hash-rounds": {
            title: "auth/invalid-hash-rounds",
            description: "Las rondas de hash deben ser un número válido."
        },
        "auth/invalid-hash-salt-separator": {
            title: "auth/invalid-hash-salt-separator",
            description: "El campo del separador de sal del algoritmo de hash debe ser un búfer de bytes válido."
        },
        "auth/invalid-id-token": {
            title: "auth/invalid-id-token",
            description: "El token de ID que se proporcionó no es un token de ID de Firebase válido."
        },
        "auth/invalid-last-sign-in-time": {
            title: "auth/invalid-last-sign-in-time",
            description: "La hora del último acceso debe ser una string de fecha en formato UTC válida."
        },
        "auth/invalid-page-token": {
            title: "auth/invalid-page-token",
            description: "El token de página siguiente que se entregó en listUsers() no es válido. Debe ser una string válida que no esté vacía."
        },
        "auth/invalid-password": {
            title: "Contraseña incorrecta",
            description: "El valor que se proporcionó para la propiedad del usuario password no es válido. Debe ser una string con al menos seis caracteres."
        },
        "auth/invalid-password-hash": {
            title: "auth/invalid-password-hash",
            description: "El hash de contraseñas debe ser un búfer de bytes válidos."
        },
        "auth/invalid-password-salt": {
            title: "auth/invalid-password-salt",
            description: "La contraseña con sal debe ser un búfer de bytes válido."
        },
        "auth/invalid-phone-number": {
            title: "auth/invalid-phone-number",
            description: "El valor que se proporcionó para phoneNumber no es válido. Debe ser una string de identificador que no esté vacía y que cumpla con el estándar E.164."
        },
        "auth/invalid-photo-url": {
            title: "auth/invalid-photo-url",
            description: "El valor que se proporcionó para la propiedad del usuario photoURL no es válido. Debe ser una URL de string."
        },
        "auth/invalid-provider-data": {
            title: "auth/invalid-provider-data",
            description: "providerData debe ser una serie de objetos UserInfo."
        },
        "auth/invalid-provider-id": {
            title: "auth/invalid-provider-id",
            description: "providerId debe ser una string del identificador del proveedor compatible válida."
        },
        "auth/invalid-session-cookie-duration": {
            title: "auth/invalid-session-cookie-duration",
            description: "La duración de las cookies de la sesión debe ser un número válido en milisegundos que vaya entre los 5 minutos y las 2 semanas."
        },
        "auth/invalid-uid": {
            title: "auth/invalid-uid",
            description: "El uid proporcionado debe ser una string no vacía con un máximo de 128 caracteres."
        },
        "auth/invalid-user-import": {
            title: "auth/invalid-user-import",
            description: "El registro de usuarios para importar no es válido."
        },
        "auth/maximum-user-count-exceeded": {
            title: "Se ha alcanzado el número máximo de usuarios",
            description: "Se excedió la cantidad máxima de usuarios permitidos para importar."
        },
        "auth/missing-android-pkg-name": {
            title: "auth/missing-android-pkg-name",
            description: "Si es obligatorio instalar la app para Android, debe proporcionarse un nombre de paquete de Android."
        },
        "auth/missing-continue-uri": {
            title: "auth/missing-continue-uri",
            description: "Se debe proporcionar una URL de continuación válida en la solicitud."
        },
        "auth/missing-hash-algorithm": {
            title: "auth/missing-hash-algorithm",
            description: "Para importar usuarios con hash de contraseñas, es necesario proporcionar el algoritmo de hash y sus parámetros."
        },
        "auth/missing-ios-bundle-id": {
            title: "auth/missing-ios-bundle-id",
            description: "La solicitud debe contener un ID del paquete de iOS."
        },
        "auth/missing-uid": {
            title: "auth/missing-uid",
            description: "Se requiere un identificador uid para la operación actual."
        },
        "auth/operation-not-allowed": {
            title: "Operación no permitida",
            description: "El proveedor de acceso proporcionado está inhabilitado para tu proyecto de Firebase. Habilítalo en la sección Método de acceso de Firebase console."
        },
        "auth/phone-number-already-exists": {
            title: "auth/phone-number-already-exists",
            description: "Otro usuario ya utiliza el phoneNumber proporcionado. Cada usuario debe tener un phoneNumber único."
        },
        "auth/project-not-found": {
            title: "auth/project-not-found",
            description: "No se encontró ningún proyecto de Firebase correspondiente a la credencial que se usó para inicializar los SDK de administrador. Consulta cómo configurar un proyecto de Firebase para ver la documentación sobre cómo generar una credencial para tu proyecto y usarla en la autenticación de los SDK de Admin."
        },
        "auth/reserved-claims": {
            title: "auth/reserved-claims",
            description: "Uno o más de los reclamos personalizados de usuarios que se entregaron a setCustomUserClaims() están reservados. Por ejemplo, no deben usarse reclamos específicos de OIDC (p. ej., sub, iat, iss, exp, aud o auth_time) como claves para reclamos personalizados."
        },
        "auth/session-cookie-expired": {
            title: "auth/session-cookie-expired",
            description: "La cookie proporcionada de la sesión de Firebase venció."
        },
        "auth/session-cookie-revoked": {
            title: "auth/session-cookie-revoked",
            description: "Se revocaron las cookies de la sesión de Firebase."
        },
        "auth/uid-already-exists": {
            title: "auth/uid-already-exists",
            description: "Otro usuario ya utiliza el uid proporcionado. Cada usuario debe tener un uid único."
        },
        "auth/unauthorized-continue-uri": {
            title: "auth/unauthorized-continue-uri",
            description: "El dominio de la URL de continuación no está en la lista blanca. Inclúyelo en la lista en Firebase console."
        },
        "auth/user-not-found": {
            title: "Usuario no encontrado",
            description: "No existe ningún registro de usuario que corresponda al identificador proporcionado."
        },
        "auth/user-disabled": {
            title: "Usuario desactivado",
            description: "Por el momento tu usuario se encuentra desactivado."
        },
        "auth/wrong-password": {
            title: "Usuario o contraseña incorrecta.",
            description: "El usuario o contraseña no coinciden con ningun registro."
        },
        "no-internet-connection": {
            title: "Sin conexión a internet",
            description: "Verifica la conexión a internet."
        }
    },
    "en-US": {
        "auth/claims-too-large": {
            title: "auth/claims-too-large",
            description: "The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes."
        },
        "auth/email-already-exists": {
            title: "auth/email-already-exists",
            description: "The provided email is already in use by an existing user. Each user must have a unique email."
        },
        "auth/id-token-expired": {
            title: "auth/id-token-expired",
            description: "The provided Firebase ID token is expired."
        },
        "auth/id-token-revoked": {
            title: "auth/id-token-revoked",
            description: "The Firebase ID token has been revoked."
        },
        "auth/insufficient-permission": {
            title: "auth/insufficient-permission",
            description: "The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs."
        },
        "auth/internal-error": {
            title: "auth/internal-error",
            description: "The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our Bug Report support channel."
        },
        "auth/invalid-argument": {
            title: "auth/invalid-argument",
            description: "An invalid argument was provided to an Authentication method. The error message should contain additional information."
        },
        "auth/invalid-claims": {
            title: "auth/invalid-claims",
            description: "The custom claim attributes provided to setCustomUserClaims() are invalid."
        },
        "auth/invalid-continue-uri": {
            title: "auth/invalid-continue-uri",
            description: "The continue URL must be a valid URL string."
        },
        "auth/invalid-creation-time": {
            title: "auth/invalid-creation-time",
            description: "The creation time must be a valid UTC date string."
        },
        "auth/invalid-credential": {
            title: "auth/invalid-credential",
            description: "The credential used to authenticate the Admin SDKs cannot be used to perform the desired action. Certain Authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be initialized with a certificate credential as opposed to a refresh token or Application Default credential. See Initialize the SDK for documentation on how to authenticate the Admin SDKs with a certificate credential."
        },
        "auth/invalid-disabled-field": {
            title: "auth/invalid-disabled-field",
            description: "The provided value for the disabled user property is invalid. It must be a boolean."
        },
        "auth/invalid-display-name": {
            title: "auth/invalid-display-name",
            description: "The provided value for the displayName user property is invalid. It must be a non-empty string."
        },
        "auth/invalid-dynamic-link-domain": {
            title: "auth/invalid-dynamic-link-domain",
            description: "The provided dynamic link domain is not configured or authorized for the current project."
        },
        "auth/invalid-email": {
            title: "auth/invalid-email",
            description: "The provided value for the email user property is invalid. It must be a string email address."
        },
        "auth/invalid-email-verified": {
            title: "auth/invalid-email-verified",
            description: "The provided value for the emailVerified user property is invalid. It must be a boolean."
        },
        "auth/invalid-hash-algorithm": {
            title: "auth/invalid-hash-algorithm",
            description: "The hash algorithm must match one of the strings in the list of supported algorithms."
        },
        "auth/invalid-hash-block-size": {
            title: "auth/invalid-hash-block-size",
            description: "The hash block size must be a valid number."
        },
        "auth/invalid-hash-derived-key-length": {
            title: "auth/invalid-hash-derived-key-length",
            description: "The hash derived key length must be a valid number."
        },
        "auth/invalid-hash-key": {
            title: "auth/invalid-hash-key",
            description: "The hash key must a valid byte buffer."
        },
        "auth/invalid-hash-memory-cost": {
            title: "auth/invalid-hash-memory-cost",
            description: "The hash memory cost must be a valid number."
        },
        "auth/invalid-hash-parallelization": {
            title: "auth/invalid-hash-parallelization",
            description: "The hash parallelization must be a valid number."
        },
        "auth/invalid-hash-rounds": {
            title: "auth/invalid-hash-rounds",
            description: "The hash rounds must be a valid number."
        },
        "auth/invalid-hash-salt-separator": {
            title: "auth/invalid-hash-salt-separator",
            description: "The hashing algorithm salt separator field must be a valid byte buffer."
        },
        "auth/invalid-id-token": {
            title: "auth/invalid-id-token",
            description: "The provided ID token is not a valid Firebase ID token."
        },
        "auth/invalid-last-sign-in-time": {
            title: "auth/invalid-last-sign-in-time",
            description: "The last sign-in time must be a valid UTC date string."
        },
        "auth/invalid-page-token": {
            title: "auth/invalid-page-token",
            description: "The provided next page token in listUsers() is invalid. It must be a valid non-empty string."
        },
        "auth/invalid-password": {
            title: "auth/invalid-password",
            description: "The provided value for the password user property is invalid. It must be a string with at least six characters."
        },
        "auth/invalid-password-hash": {
            title: "auth/invalid-password-hash",
            description: "The password hash must be a valid byte buffer."
        },
        "auth/invalid-password-salt": {
            title: "auth/invalid-password-salt",
            description: "The password salt must be a valid byte buffer"
        },
        "auth/invalid-phone-number": {
            title: "auth/invalid-phone-number",
            description: "The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string."
        },
        "auth/invalid-photo-url": {
            title: "auth/invalid-photo-url",
            description: "The provided value for the photoURL user property is invalid. It must be a string URL."
        },
        "auth/invalid-provider-data": {
            title: "auth/invalid-provider-data",
            description: "The providerData must be a valid array of UserInfo objects."
        },
        "auth/invalid-provider-id": {
            title: "auth/invalid-provider-id",
            description: "The providerId must be a valid supported provider identifier string."
        },
        "auth/invalid-session-cookie-duration": {
            title: "auth/invalid-session-cookie-duration",
            description: "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks."
        },
        "auth/invalid-uid": {
            title: "auth/invalid-uid",
            description: "The provided uid must be a non-empty string with at most 128 characters."
        },
        "auth/invalid-user-import": {
            title: "auth/invalid-user-import",
            description: "The user record to import is invalid."
        },
        "auth/maximum-user-count-exceeded": {
            title: "auth/maximum-user-count-exceeded",
            description: "The maximum allowed number of users to import has been exceeded."
        },
        "auth/missing-android-pkg-name": {
            title: "auth/missing-android-pkg-name",
            description: "An Android Package Name must be provided if the Android App is required to be installed."
        },
        "auth/missing-continue-uri": {
            title: "auth/missing-continue-uri",
            description: "A valid continue URL must be provided in the request."
        },
        "auth/missing-hash-algorithm": {
            title: "auth/missing-hash-algorithm",
            description: "Importing users with password hashes requires that the hashing algorithm and its parameters be provided."
        },
        "auth/missing-ios-bundle-id": {
            title: "auth/missing-ios-bundle-id",
            description: "The request is missing an iOS Bundle ID."
        },
        "auth/missing-uid": {
            title: "auth/missing-uid",
            description: "A uid identifier is required for the current operation."
        },
        "auth/operation-not-allowed": {
            title: "auth/operation-not-allowed",
            description: "The provided sign-in provider is disabled for your Firebase project. Enable it from the Sign-in Method section of the Firebase console."
        },
        "auth/phone-number-already-exists": {
            title: "auth/phone-number-already-exists",
            description: "The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber."
        },
        "auth/project-not-found": {
            title: "auth/project-not-found",
            description: "No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs."
        },
        "auth/reserved-claims": {
            title: "auth/reserved-claims",
            description: "One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims."
        },
        "auth/session-cookie-expired": {
            title: "auth/session-cookie-expired",
            description: "The provided Firebase session cookie is expired."
        },
        "auth/session-cookie-revoked": {
            title: "auth/session-cookie-revoked",
            description: "The Firebase session cookie has been revoked."
        },
        "auth/uid-already-exists": {
            title: "auth/uid-already-exists",
            description: "The provided uid is already in use by an existing user. Each user must have a unique uid."
        },
        "auth/unauthorized-continue-uri": {
            title: "auth/unauthorized-continue-uri",
            description: "The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console."
        },
        "auth/user-not-found": {
            title: "auth/user-not-found",
            description: "There is no existing user record corresponding to the provided identifier."
        },
        "auth/user-disabled": {
            title: "auth/user-disabled",
            description: "Thrown if the user corresponding to the given email has been disabled."
        },
        "auth/wrong-password": {
            title: "auth/wrong-password",
            description: "Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set."
        },
        "no-internet-connection": {
            title: "No Internet Connection",
            description: "Your computer is offline"
        }
    }

}