// 1 - Importaciones
import { ActionReducer } from '@ngrx/store';
import { OnDemandVideo } from '../../models/ondemand';
import { OnDemand } from '../../_models/events/eventModerator.model';
import { OnDemandActions, OnDemandActionsTypes } from './on-demand.actions';

export interface OnDemandState {
    loadingImages: boolean,
    loadingOnDemand: boolean,
    emptyOnDemand: boolean,
    onDemand: any
}

const initialState: OnDemandState = {
    loadingImages: false,
    loadingOnDemand: false,
    emptyOnDemand: false,
    onDemand: null
}

// 3 - Switch con las funciones reducer
export const onDemandReducer: ActionReducer<OnDemandState, OnDemandActions> = function (state = initialState, action) {
    switch (action.type) {
        case OnDemandActionsTypes.Default:
            return initialState;
        case OnDemandActionsTypes.InitializeOnDemandListener:
            return { ...state, loadingOnDemand: true };
        case OnDemandActionsTypes.StopOnDemandListener:
            return { ...state, onDemand: null };
        case OnDemandActionsTypes.OnDemandLoaded:
            return { ...state, onDemand: action.payload.onDemand, loadingOnDemand: false, loadingImages: true };
        case OnDemandActionsTypes.LoadOnDemandImages:
            return { ...state, loadingImages: true };
        case OnDemandActionsTypes.LoadedOnDemandImages:
            return { ...state, onDemand: action.payload.onDemand, loadingImages: false };
        case OnDemandActionsTypes.OnDemandLoadedEmpty:
            return { ...state, emptyOnDemand: true, loadingImages: false, loadingOnDemand: false };
        case OnDemandActionsTypes.SelectVideo:
            return { ...state, selectedVideo: action.payload.video };
        default:
            return state;
    }
}