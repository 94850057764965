import { Component, Input } from '@angular/core';
import { User } from '../../../../core/models/user';
import { currentUser } from 'src/app/core/auth/selectors/auth.selectors';
import { take, skipWhile } from "rxjs/operators";
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { select, Store } from '@ngrx/store';
import { CustomChatService } from 'src/app/core/services/custom.chat.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-attendees-show',
  templateUrl: './attendees.show.component.html',
  styleUrls: ['./attendees.show.component.scss']
})

export class AttendeesShowComponent{

  @Input() user: User | null = null;
  currentUser: User | null = null;
  constructor(
    private store: Store<StoreRootState>,
    private chatServ : CustomChatService,
    private activeRoute : ActivatedRoute,
    public router: Router,
  ) { }

  async ngOnInit() {
    this.currentUser = await this.store.pipe(select(currentUser), skipWhile((user) => !user), take(1)).toPromise();
  }

  async sendMessage( friend_id : string){
    if(this.currentUser){
      let chat_id    = await this.chatServ.newChat(friend_id,this.currentUser.uid);
      let event_id   = this.activeRoute.snapshot.paramMap.get('id');
      let event_name = this.activeRoute.snapshot.paramMap.get('name');

      this.router.navigate([`${event_id}/${event_name}/chats/${chat_id}`]);
    }  
  }
}
