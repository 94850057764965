import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Store } from '@ngrx/store';
import firebase from 'firebase';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { MagixEvent } from '../../models/event';
import { User, heartBeatInterface, UserAddressInterface, UserAddressClass } from '../../models/user';
import { UserConnected, UserDisconnected } from '../actions/auth.actions';
import { HeartbeatService, UserCoordsInterface } from '../../services/heartbeat.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	userConnected$: Observable<boolean> | null = null;
	connectedSubscription: Subscription | null = null;

	currentUser: User | null = null;
	country: string = "";
	key: string = 'AIzaSyCu2_YzRcqVpBn6Z0rdAdeOcS-jtvR62xc';
	constructor(
		private auth: AngularFireAuth,
		private database: AngularFireDatabase,
		private store: Store,
		private heartbeatServ: HeartbeatService,
		private http: HttpClient,
		private userServ: UserService
	) { }

	initializeConnectedStatus(): void {
		if (!this.userConnected$) {
			this.userConnected$ = this.database.object<boolean>(".info/connected").valueChanges().pipe(map(connected => connected == true), shareReplay(1));
		}

		this.connectedSubscription = this.userConnected$.subscribe(connected => {
			let now = new Date();
			if (this.currentUser && this.country)
				// this.setDisconnectedStatus(this.currentUser, this.country);
				this.store.dispatch(
					connected == true ?
						new UserConnected({ connectedTime: now.getTime() }) :
						new UserDisconnected({ disconnectedTime: now.getTime() })
				);
		});
	}

	async setConnectedStatus(user: User, event: MagixEvent): Promise<any> {
		try {
			let databaseStatusReference = this.database.database.ref(`users/${user.uid}/heartBeat`);
			const isOnlineForDatabase: heartBeatInterface = {
				/* @ts-ignore */
				last_changed: firebase.database.ServerValue.TIMESTAMP,
				event: event.id
			};

			return databaseStatusReference.set(isOnlineForDatabase);
		} catch (error) {
			console.warn("Ha ocurrido un error al cambiar el estatus del cliente.", error);
		}
	}

	// async setDisconnectedStatus(user: User): Promise<any> {
	//   try {
	//     let databaseStatusReference = this.database.database.ref(`users/${user.uid}/heartBeat`);
	//     const isOfflineForDatabase : heartBeatInterface = {
	//       /* @ts-ignore */
	//       last_changed: firebase.database.ServerValue.TIMESTAMP,
	//       event: "none"
	//     };
	//     return databaseStatusReference.onDisconnect().set(isOfflineForDatabase);
	//   } catch (error) {
	//     console.warn("Ha ocurrido un error al cambiar el estatus del cliente.", error);
	//   }
	// }

	async recoverAccount(email: string): Promise<void> {
		try {
			await this.auth.sendPasswordResetEmail(email)
		} catch (error) {
			console.warn(error);
			throw (error);
		}
	}

	async login(email: string, password: string) {
		try {
			try {
				let credentials = await this.auth.signInWithEmailAndPassword(email, password);
				return credentials;
			} catch (error) {
				password = password.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
				let credentials = await this.auth.signInWithEmailAndPassword(email, password);
				return credentials;
			}
		} catch (error) { 
			console.warn(error);
			throw (error);
		}
	}

	async registerAnonimSession(): Promise<firebase.User> {
		try {
			let user = await this.auth.signInAnonymously();
			if (user.user) return user.user;
			else throw "error-registering-user";
		} catch (error) {
			console.warn("Ha ocurrido un error al iniciar sesion anonima. Error:", error);
			throw error;
		}
	}

	logout() {
		return this.auth.signOut();
	}

	async removeMyAccount(): Promise<void> {
		let currentUser = await this.auth.currentUser;
		if (currentUser) {
			return currentUser.delete()
		}
	}

	getLoggedUser(): Observable<firebase.User | null> {
		return this.auth.user.pipe(map(user => user ? user : null));
	}

	registerAnonymUserOnDB(user: firebase.User, eventID: number): Promise<void> {
		let uid = user.uid;
		let userDB: User = {
			loginType: "open",
			uid,
			email: user.email || `${uid}@anonym-user.com`,
			name: uid,
			ban: false,
			password: "",
			type: "user",
			lastName: "N/A",
			events: {
				[eventID]: new Array(100).fill(true)
			}
		}
		return this.database.database.ref(`users/${uid}`).set(userDB);
	}











	async setUserAddress(user: User): Promise<void> {
		let address = await this.getUserAddres();
		let newUser = await this.userServ.get(user.uid);

		if (newUser) {
			newUser.city = address.city;
			newUser.country = address.country;
			await this.database.database.ref(`users/${newUser.uid}`).set(newUser);
		}
	}

	async getUserAddres(): Promise<UserAddressInterface> {
		let response = await this.getUserCoords();
		let address: UserAddressInterface = new UserAddressClass();
		if (response.status) {
			address = await this.http.get<string>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${response.coords?.latitude},${response.coords?.longitude}&sensor=false&key=${this.key}`)
				.pipe(
					map((information: any) => {
						let response = new UserAddressClass();
						if (information.status == "OK") {
							response.city = information.results[6].address_components[0].long_name.toLowerCase();
							response.country = information.results[6].address_components[2].long_name.toLowerCase();
						}
						return response;
					}),
					take(1)
				)
				.toPromise();
		}
		return address;
	}

	getUserCoords(): Promise<UserCoordsInterface> {
		return new Promise((resolve) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					resolve({
						status: true,
						coords: position.coords
					});
				});
			} else {
				resolve({ status: false });
			}
		});
	}
}
