import { Component } from '@angular/core';

declare let gsap: any;

// const DOMAIN = "http://localhost:4200";
const DOMAIN = "https://redmunicipiosninez.com";
@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})

export class NotFoundComponent{
	constructor()
	{
	}

}
