<nav class="navbar navbar-expand-lg navbar-light">
	<button class="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#MVSMenu">
		<mat-icon class="menu-icon">menu</mat-icon>
	</button>

	<div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="MVSMenu">
		<ul class="navbar-nav text-md-center text-left">
			<ng-container *ngFor="let option of routes" class="nav-item">
				<li *ngIf="option.status">
					<a class="nav-link hoverable" [href]="option.routePath" [routerLink]="option.routePath"
						routerLinkActive="active">
						<div *ngIf="(event$ | async).showIconsMainNav != false" [innerHTML]="option.icon | safe:'html'" class="nav-icon-container"></div>
						<!-- <img src="../../../../assets/icons/{{option.icon}}.svg"> -->
						<span class="align-self-center nav-span">{{option.title}}</span>
					</a>
				</li>
			</ng-container>
			<li *ngIf="eventsNavbar['chats']">
				<a class="nav-link hoverable" routerLink="./chats" routerLinkActive="active">
					<div class="nav-icon-container">
						<mat-icon [matBadge]="pendingChats" matBadgePosition="before" matBadgeColor="warn"
							*ngIf="pendingChats > 0">question_answer</mat-icon>
						<mat-icon *ngIf="pendingChats == 0">question_answer</mat-icon>
					</div>
					<span class="align-self-center nav-span"> {{ chatLabel }} </span>
				</a>
			</li>
			<li *ngIf="eventsNavbar['networking']">
				<a class="nav-link hoverable" routerLink="./networking" routerLinkActive="active">
					<div class="nav-icon-container">
						<mat-icon [matBadge]="pendingChats" matBadgePosition="before" matBadgeColor="warn"
							*ngIf="pendingChats > 0">question_answer</mat-icon>
						<mat-icon *ngIf="pendingChats == 0">question_answer</mat-icon>
					</div>
					<span class="align-self-center nav-span"> {{'navbar.routes.networking' | translate}} </span>
				</a>
			</li>
		</ul>
	</div>


	<div *ngIf="event$ | async as event" class="form-inline my-2 my-lg-0">
		<ng-container *ngIf="event.languages && event.languages.length > 1">
			<button mat-button [matMenuTriggerFor]="menu" class="translate-button">
				<mat-icon aria-hidden="false" aria-label="icono-de-transmision">translate</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<ng-container *ngFor="language of event.languages; index as i;" [ngSwitch]="event.languages[i]">
					<button (click)="changeLanguage('es')" *ngSwitchCase="'Español'" mat-menu-item>ESP</button>
					<button (click)="changeLanguage('en')" *ngSwitchCase="'Inglés'" mat-menu-item>ENG</button>
					<button (click)="changeLanguage('pt')" *ngSwitchCase="'Portugués'" mat-menu-item>POR</button>
					<button (click)="changeLanguage('fr')" *ngSwitchCase="'Francés'" mat-menu-item>FRA</button>
					<button (click)="changeLanguage('de')" *ngSwitchCase="'Alemán'" mat-menu-item>DEU</button>
					<button (click)="changeLanguage('zh')" *ngSwitchCase="'Chino'" mat-menu-item>ZHO</button>
				</ng-container>
			</mat-menu>
		</ng-container>
	</div>

	<div  *ngIf="(event$ | async).enableUserButton != false" class="form-inline my-2 my-lg-0">
		<button mat-button [matMenuTriggerFor]="menu" class="user-button" [disabled]="!(loadedUser$ | async)">
			<mat-icon *ngIf="loadedUser$ | async" aria-hidden="false" aria-label="icono-de-transmision">
				account_circle</mat-icon>
		</button>
		<mat-menu  #menu="matMenu">
			<p class="text-center" *ngIf="(user$ | async); let currentUser;">{{'navbar.profile.greetings' | translate}}
				{{ currentUser.name }}</p>
			<button mat-menu-item [routerLink]="['./account']"> {{'navbar.profile.myProfile' | translate}} </button>
			<button mat-menu-item (click)="logout()"> {{'navbar.profile.logout' | translate}} </button>
		</mat-menu>
	</div>

</nav>
