<aside id="left-side-container" [formGroup]="form">
	<!-- HEADER -->
	<networking-profile-left-section 	id="left-side-header"
										[currentUser]="currentUser"
										[defaultImage]="defaultImage"
										(showAttendees)="showOverside(true)"
	></networking-profile-left-section>
	<!-- SEARCHER -->
	<networking-search-left-section id="left-side-searcher"
									formControlName="search"
	></networking-search-left-section>
	<!-- CHATS -->
	<networking-conctacts-left-section  id="left-side-chats"
										[contacts]="contacts"
										[currentUser]="currentUser"
										[searchContacts]="this.form.controls.search.value"
										(chat)="chat.emit($event)"
										(scroll)="scroll.emit($event)"
										(friendDeleted)="friendDeleted.emit($event)"
	></networking-conctacts-left-section>

</aside>

<networking-attendees-left-section  id="over-left-side"
									class="over-left-side"
									[ngClass]='showAttendees ? "slide-in" : "slide-out"'
									[currentUser]="currentUser"
									(chat)="chat.emit($event)"
									(hideAttendees)="showOverside(false)">
</networking-attendees-left-section>

