import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';

@Pipe({
  name: 'scapeUser'
})
export class ScapeUserPipe implements PipeTransform {

  transform(value: User["type"], ...args: unknown[]): unknown {
    switch (value) {
      case "user":
      case "moderator":
      case "admin":
        return "Asistente";
      case "expositor":
        return "Ponente / Expositor";
      default:
        return "N/A";
    }
  }

}
