import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, concat, from, Observable, of, throwError } from 'rxjs';
import { mergeMap, map, switchMap } from 'rxjs/operators';
// import { EventService } from '../../services/event.service';
import { ImagesService } from '../../services/images.service';
import { LoadForm, FormActionsTypes, LoadedForm, FormNotFound, LoadFormImages, LoadedFormImages, UploadUser, UploadedUser, ErrorUploadingUser, EmailSent } from './form.actions';
import { StoreRootState } from '../index.reducer';
import { MetricsService } from '../../services/metrics.service';
import { FormsService } from '../../services/cochinero.service';
import { UserService } from '../../services/user.service';
import { RegisterForm } from '../../_models/RegisterForm';
import { EventsService } from '../../services/event.service';

@Injectable()
export class FormEffects {

    offEventSubscription = new BehaviorSubject(false);


    loadForm$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadForm>(FormActionsTypes.LoadForm),
                mergeMap((action) => from(this.formService._getForm(action.payload.formId))),
                map((form) => form ? new LoadedForm({ form }) : new FormNotFound())
            )
    )

    onLoadedForm$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadedForm>(FormActionsTypes.LoadedForm),
                switchMap((action) => {
                    let actions = [];
                    actions.push(new LoadFormImages({ form: action.payload.form }));
                    // action.payload.form.eventid ? actions.push(new LoadEvent({ eventID: action.payload.form.eventid })) : null
                    return actions;
                })
            )
    )

    /*  uploadUser$ = createEffect(() =>
         this.actions$
             .pipe(
                 ofType<UploadUser>(FormActionsTypes.UploadUser),
                 mergeMap(async (action) => {
                     let res = await this.userService.registerUserInEvent(action.payload.newUser, action.payload.event)
                     console.log('esto trae res', res);
                     return { res, action };
                 }),
                 map((response) => response.res.userRegistered
                     ? new UploadedUser({ formValues: response.action.payload.formValues, form: response.action.payload.form })
                     : new ErrorUploadingUser())
             )
     )
  */
    updateFormInfo$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<UploadedUser>(FormActionsTypes.UploadedUser),
                mergeMap(async (action) => {
                    let res = await this.formService.updateFormInfo(action.payload.form, action.payload.formValues, '¡Listo! Se le envío un correo con los datos del registro.');
                    return res;
                }),
                map((res) => {
                    if (res.status === 'OK' && !res.error) {
                        return new EmailSent({});
                    } else {
                        return new ErrorUploadingUser()
                    }
                })
            )
    )

    loadImagesForm$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType<LoadFormImages>(FormActionsTypes.LoadFormImages),
                mergeMap(async action => {
                    let form: RegisterForm = JSON.parse(JSON.stringify(action.payload.form));
                    try {

                        if (form) {
                            form.backgroundImg = await this.imagesService.getImage(form.backgroundImg);
                            form.logoImg = await this.imagesService.getImage(form.logoImg);
                            form.termsDoc = form.termsDoc ? await this.imagesService.getImage(form.termsDoc) : undefined;
                        }

                    } catch (error) {
                        console.warn('Ocurrio un error al obtener imagen no encontrada!', error);
                    }
                    return form;
                }), map((form) => new LoadedFormImages({ form }))
            )
    )



    // .pipe(
    //     ofType<LoadedForm>(FormActionsTypes.LoadedForm),
    //     mergeMap(action => {
    //         let form: RegisterForm = JSON.parse(JSON.stringify(action.payload.form));
    //         let calendar: Calendar = {
    //             title: form.calendarInfo?.title,
    //             startDate: form.calendarInfo?.startDate,
    //             startHour: form.calendarInfo?.startHour?.split(':')[0],
    //             startMin : form.calendarInfo?.startHour?.split(':')[1],
    //             endDate : form.calendarInfo?.endDate,
    //             endHour : form.calendarInfo?.endHour?.split(':')[0],
    //             endMin : form.calendarInfo?.endHour?.split(':')[1],
    //             timeZone : form.calendarInfo?.timeZone,
    //         }
    //         return calendar;
    //     }),
    //     map((action) =>new CreateInputs())))




    constructor(
        private actions$: Actions,
        private eventService: EventsService,
        private store: Store<StoreRootState>,
        private imagesService: ImagesService,
        private metricsService: MetricsService,
        private formService: FormsService,
        private userService: UserService
    ) {
    }

}