import { Action       } from '@ngrx/store'
import { OnDemandData } from '../../models/ondemand';

export enum OnDemandActionsTypes {
  Default = '[Default] Action',
  InitializeOnDemandListener = '[InitializeOnDemandListener] Action',
  StopOnDemandListener = '[StopOnDemandListener] Action',
  OnDemandLoaded = '[OnDemandLoaded] Action',
  LoadOnDemandImages = '[LoadOnDemandImages] Action',
  LoadedOnDemandImages = '[LoadedOnDemandImages] Action',
  OnDemandLoadedEmpty = '[OnDemandLoadedEmpty] Action',
  SelectVideo = '[SelectVideo] Action',
}

export class Default implements Action {
  readonly type = OnDemandActionsTypes.Default
  constructor(public payload: any) { }
}

export class InitializeOnDemandListener implements Action {
  readonly type = OnDemandActionsTypes.InitializeOnDemandListener;
  constructor(public payload: { event_fid : string }) { }
}

export class StopOnDemandListener implements Action {
  readonly type = OnDemandActionsTypes.StopOnDemandListener;
}

export class OnDemandLoaded implements Action {
  readonly type = OnDemandActionsTypes.OnDemandLoaded;
  constructor(public payload: { onDemand: OnDemandData }) { }
}

export class OnDemandLoadedEmpty implements Action {
  readonly type = OnDemandActionsTypes.OnDemandLoadedEmpty;
}

export class LoadOnDemandImages implements Action {
  readonly type = OnDemandActionsTypes.LoadOnDemandImages;
  constructor(public payload: { onDemand: OnDemandData }) { }
}

export class LoadedOnDemandImages implements Action {
  readonly type = OnDemandActionsTypes.LoadedOnDemandImages;
  constructor(public payload: { onDemand: OnDemandData }) { }
}

export class SelectVideo implements Action {
  readonly type = OnDemandActionsTypes.SelectVideo;
  constructor(public payload: { video: any }) { }
}

export type OnDemandActions =
  Default |
  InitializeOnDemandListener |
  StopOnDemandListener |
  OnDemandLoaded |
  LoadOnDemandImages |
  LoadedOnDemandImages |
  OnDemandLoadedEmpty |
  SelectVideo;