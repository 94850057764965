import { Component, Input, OnInit } from '@angular/core';
import { StoreRootState } from 'src/app/core/ngrx-store/index.reducer';
import { select, Store } from '@ngrx/store';
import { skipWhile  } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { selectEvent } from 'src/app/core/ngrx-store/event-store/event.selectors';
import { EventSupportInterfaz } from 'src/app/core/models/event';

@Component({
  selector: 'app-whatsapp-support-button',
  templateUrl: './whatsapp-support-button.component.html',
  styleUrls: ['./whatsapp-support-button.component.scss']
})
export class WhatsappSupportButtonComponent implements OnInit {

  @Input() message: string = "";
  // @ts-ignore
  support     : EventSupportInterfaz;
  hideButtons : boolean = true;
  //
  whatsapp  : socialMediaSupportsInterface = new socialMediaSupportsClass();
  telephone : socialMediaSupportsInterface = new socialMediaSupportsClass();
  email     : socialMediaSupportsInterface = new socialMediaSupportsClass();
  subscriptions : Subscription[] = [];
  activatedButton: boolean = false;

  constructor(private store : Store<StoreRootState>) {}

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.store.pipe(select(selectEvent),skipWhile( event => !event )).subscribe( response => {
        if(response){
          this.activatedButton  = false;
          this.whatsapp.status  = false;
          this.email.status     = false;
          this.telephone.status = false;

          this.support = response.support;
          // Validate whatsapp
          if(this.support.whatsapp && this.support.whatsapp.length > 0){
            this.whatsapp.status = true;
            this.whatsapp.url = `https://api.whatsapp.com/send?phone=${this.support.whatsapp}&text=${this.support.whatsappMensage ? this.support.whatsappMensage : ''}`;
            this.activatedButton = true;
          }
          // Validate email
          if(this.support.email && this.support.email.length > 0){
            this.email.status = true;
            this.email.url = `mailto:${this.support.email}`;
            this.activatedButton = true;
          }
          // Validate phone
          if(this.support.telephone && this.support.telephone.length > 0){
            this.telephone.status = true;
            this.telephone.url = `tel:${this.support.telephone}`;
            this.activatedButton = true;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}

export class socialMediaSupportsClass{
  status = false;
  url    = '';
}
export type socialMediaSupportsInterface = socialMediaSupportsClass;
