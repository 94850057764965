import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import firebase from 'firebase';
import { ActionReducer } from '@ngrx/store';
import { User } from '../../models/user';

export interface AuthState {
    loggedIn: boolean;
    anonymUser: boolean;
    isUserLoaded: boolean;
    user: User | null;
    userAuthCredentials: firebase.User | null;
    loadingUser: boolean;
    loadingUserImages: boolean;
    connected: boolean;
}
export const initialAuthState: AuthState = {
    loggedIn: false,
    anonymUser: false,
    isUserLoaded: false,
    user: null,
    userAuthCredentials: null,
    loadingUser: false,
    loadingUserImages: true,
    connected: true
};

export const authReducer: ActionReducer<AuthState, AuthActions> = function (state = initialAuthState, action) {
    switch (action.type) {
        case AuthActionTypes.LoggedIn:
            return {
                ...state,
                loggedIn: true,
                isUserLoaded: false,
                userAuthCredentials: action.payload.user,
            };
        case AuthActionTypes.UserRequested:
            return {
                ...state,
                loadingUser: true,
                loadingUserImages: true
            };
        case AuthActionTypes.UserLoaded:
            return {
                ...state,
                loadingUser: false,
                isUserLoaded: true,
                user: action.payload.user
            }
        case AuthActionTypes.Logout:
            return {
				loggedIn: false,
				anonymUser: false,
				isUserLoaded: false,
				user: null,
				userAuthCredentials: null,
				loadingUser: false,
				loadingUserImages: true,
				connected: true
			};
        case AuthActionTypes.UserImagesRequested:
            return {
                ...state,
                connected: true,
                loadingUserImages: true
            }
        case AuthActionTypes.UserImagesLoaded:
            return {
                ...state,
                user: action.payload.user,
                loadingUserImages: false,
            }
        case AuthActionTypes.UserConnected:
            return {
                ...state,
                connected: true
            }
        case AuthActionTypes.UserDisconnected:
            return {
                ...state,
                connected: false
            }
        case AuthActionTypes.LoadedUserLocation:
            if (state.user && state.user.status) {
                state.user.status.country = action.payload.country;
            }
            return state;
        default:
            return state;
    }
}
