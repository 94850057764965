import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class GrapeJSService {

	constructor(
		private database: AngularFireDatabase
	) { }

	printHTML(element_id: string, documentData: any) {
		let htmlContainer = document.getElementById(element_id);

		if (htmlContainer) {
			this.removeOldTags(htmlContainer);
			//HTML values
			const htmlTxt: string[] = documentData['gjs-html'].split('<script>');
			const embedCSS: string = documentData['gjs-css'];
			//HTML Elements
			const headElement = htmlContainer?.getElementsByTagName('head')[0];
			const bodyElement = htmlContainer?.getElementsByTagName('body')[0];
			const style = document.createElement('style');
			const script = document.createElement('script');
			//Inserting data to html elements
			style.appendChild(document.createTextNode(embedCSS));
			headElement?.appendChild(style);
			// Init htmlembed
			bodyElement.innerHTML = htmlTxt[0];
			// Check if js exist
			if (htmlTxt.length > 1) {
				const embedScript: string = htmlTxt[1].split('</script>')[0];
				script.appendChild(document.createTextNode(embedScript));
			}
			// Wait until values set
			setTimeout(() => {
				bodyElement?.appendChild(script);
			}, 500)
		}
	}

	printCustomHTML(element_id: string, html: any, css : any) {
		let htmlContainer = document.getElementById(element_id);
		// console.log(htmlContainer);
		if (htmlContainer) {
			this.removeOldTags(htmlContainer);
			//HTML values
			const htmlTxt: string[] = html.split('<script>');
			const embedCSS: string  = css;
			//HTML Elements
			const headElement = htmlContainer?.getElementsByTagName('head')[0];
			const bodyElement = htmlContainer?.getElementsByTagName('body')[0];
			const style = document.createElement('style');
			const script = document.createElement('script');
			//Inserting data to html elements
			style.appendChild(document.createTextNode(embedCSS));
			headElement?.appendChild(style);
			// Init htmlembed
			bodyElement.innerHTML = htmlTxt[0];
			// Check if js exist
			if (htmlTxt.length > 1) {
				const embedScript: string = htmlTxt[1].split('</script>')[0];
				script.appendChild(document.createTextNode(embedScript));
			}
			// Wait until values set
			setTimeout(() => {
				bodyElement?.appendChild(script);
			}, 500)
		}
	}

	private removeOldTags(element: HTMLElement | any) {
		element.querySelectorAll('style').forEach((style: HTMLElement) => style.remove());
		element.querySelectorAll('script').forEach((script: HTMLElement) => script.remove());
	}

	async getHTML(eventId: number, type: string) {
		return await this.database.object<any>(`grape/${eventId}/${type}`).valueChanges().pipe(take(1)).toPromise();
	}
}
